import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const URL_QUICKSIGHT_DASHBOARD = 'https://1k9hkkbuxh.execute-api.us-east-1.amazonaws.com/test/dashboard-embed-sample'

const useStyles = makeStyles((theme) => ({

containerIframe: {
  display: 'flex',
  minHeight: '600px',
  height: '600px',
  //paddingTop: '56.25%', /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
},

/* Then style the iframe to fit in the container div with full height and width */
responsiveIframe: {
  flexGrow: 1,
  width: '100%',
  height: '100%',
},
}))


export default function PaginaKPI(props) {
  const classes = useStyles();
  return (
    <div className={classes.containerIframe}>
      <iframe className={classes.responsiveIframe} title="KPI Dashboard" src={URL_QUICKSIGHT_DASHBOARD} ></iframe>
    </div>
  )
}
