import React from 'react'
import './App.css'
//import logo from './logo emigo tr.png'
import Amplify, { Hub } from 'aws-amplify'
import initTraducciones from './traducciones'
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import awsconfig from './aws-exports'
import PaginaBase from './pages/PaginaBase'
import { useStore, selUser } from './store'
import { ReactComponent as EmigoLogo } from './assets/logo emigo tr.svg';
import { makeLoggers } from './utils'

const { ll,ee } = makeLoggers('App')

Amplify.configure(awsconfig)
initTraducciones()

const useStyles = makeStyles((theme) => ({
  logoContenedor: {
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    width: '50%',
    height: '50%',
  }
}));

const authEventListener = data => {
  ll('authEventListener:',data);
  switch(data.payload.event){
    case 'signOut':
      ll('user signed in:',data.payload);
      break;
    case 'signIn':
      ll('user signed in:',data.payload);
      break;
    case 'tokenRefresh':
      ll('token refresh succeeded:',data.payload);
      break;
    case 'tokenRefresh_failure':
      ee('token refresh failed',data);
      break;
    default:
  }
}


const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState();
  const user = useStore(selUser);
  const classes = useStyles()
  const loadInfo = useStore(state => state.loadInfo)
  const setGlobalUser = useStore(state => state.setUser)
  const infoLoaded = useStore(state => state.infoLoaded)

  ll.enabled = false; ee.enabled = true;

  React.useEffect(() => {
    Hub.listen('auth', authEventListener)
    return () => {
      Hub.remove('auth');
    }
  },[]);

  React.useEffect(() => {
    loadInfo()
      .then(() => ll('Info Tipos Sensores y Detectores cargada:'))
      .catch(e => ee('ERROR en carga de Info Tipos:',e));
    return onAuthUIStateChange((nextAuthState, authData) => {
        ll('onAuthUIStateChange: nextAuthState:',nextAuthState);
        ll('onAuthUIStateChange:',authData)
        setAuthState(nextAuthState);
        setGlobalUser(authData);
    });
  }, [loadInfo,setGlobalUser,user]);

  return authState === AuthState.SignedIn && user.attributes && infoLoaded ? (
    <>
      <CssBaseline />
      <div className="App">
          <PaginaBase user={user} />
      </div>
    </>
    ) : (
      <AmplifyAuthenticator>
        <AmplifySignIn slot="sign-in" hideSignUp headerTitle="Inicio de sesión en EMIGO 2.0">
          <div slot="header-subtitle" className={classes.logoContenedor}><EmigoLogo className={classes.logo} /></div>
        </AmplifySignIn>
      </AmplifyAuthenticator>
  );
}

export default AuthStateApp;
