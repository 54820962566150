import ReactDOMServer from 'react-dom/server';
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Leaflet from 'leaflet'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faBroadcastTower } from '@fortawesome/free-solid-svg-icons'
import { faDizzy } from '@fortawesome/free-solid-svg-icons'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { EstadoAntena } from '../enums'
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  shadow: {
    transform: 'scaleY(0.5)',
    color: 'radial-gradient(rgba(60,60,60,0.5), rgba(60,60,60,0))'
  },
  alerta: {
    transform: 'scale(0.75)',
  },
  text: {
    whiteSpace: 'nowrap',
    transform: 'translate(-50%,25%) scale(0.5)',
    zIndex: 1000,
    backgroundColor: 'rgba(255,255,255,0.6)',
    paddingLeft: '0.2em',
    paddingRight: '0.2em',
  },
}))

const COLOR_SELECTED = "rgba(0,255,255,0.5)"

export const MarkerIcon = ({color,icon,selected,alerted,noShadow,text}) => {
  //console.log('MarkerIcon:',{color,icon,selected,alerted,noShadow,text})
  const classes = useStyles()
  return (
    <span className="fa-layers fa-fw">
    { selected &&
      <FontAwesomeIcon icon={faCircle} size="2x" transform="left-3 up-0" color={COLOR_SELECTED}/>
    }
    { !noShadow &&
      <FontAwesomeIcon icon={faMapMarker} className={classes.shadow} size="lg" color="rgba(60,60,60,0.2)" transform="rotate-60 down-6 right-7"/>
    }
      <FontAwesomeIcon icon={faMapMarker} size="lg" color={color} transform=""/>
      <FontAwesomeIcon icon={icon} size="sm" inverse transform="shrink-6 up-3 left-0"/>
    { alerted &&
      <FontAwesomeIcon icon={faBell} className={classes.alerta} size="xs" transform="right-8 up-12" color="red"/>
    }
    { text &&
      <span className={clsx(classes.text,"fa-layers-text")}>{text}</span>
    }
    </span>
  )
}

const ICONS = [
  faBroadcastTower,
  faDizzy,
  faExclamationCircle,
  faQuestion,
]

export const ICONOS = {
  ANTENA_NORMAL: 0,
  ANTENA_INACTIVA: 1,
  ANTENA_ALARMADA: 2,
  ANTENA_SININFO: 3,
}

export const makeMarkerIcon = ({color,icono,selected,alerted,noShadow,text}) => {
  const icon = ICONS[icono] || faQuestion
  const iconHtml = ReactDOMServer.renderToString(<MarkerIcon {...{color,icon,selected,alerted,noShadow,text}}/>)
  return new Leaflet.DivIcon({ html: iconHtml, popupAnchor:[0,-50], iconAnchor:[22,53.33] })
}

export const getIconoAntena = (antena,selected,alerted,text,noShadow) => {
  switch(antena.idEstadoAntena){
    case EstadoAntena.INACTIVA:
      return makeMarkerIcon({ color:'grey', icono:ICONOS.ANTENA_INACTIVA, selected, alerted, noShadow, text })
    case EstadoAntena.ACTIVA:
      return makeMarkerIcon({ color:'green', icono:ICONOS.ANTENA_NORMAL, selected, alerted, noShadow, text })
    case EstadoAntena.PROBLEMAS:
      return makeMarkerIcon({ color:'orange', icono:ICONOS.ANTENA_ALARMADA, selected, alerted, noShadow, text })
    case EstadoAntena.FALLA:
      return makeMarkerIcon({ color:'red', icono:ICONOS.ANTENA_ALARMADA, selected, alerted, noShadow, text })
    default:
      return makeMarkerIcon({ color:COLOR_SELECTED, icono:ICONOS.ANTENA_SININFO, selected, alerted, noShadow, text })
  }
}
