import { DateTime, Interval, Duration } from 'luxon'
import * as bounds from 'binary-search-bounds'

const debug = require('debug');

const compTS = (a,b) => a.ts - b.ts
/**
 * funcion que busca el indice del elemento con el mismo ts o la posicion donde iria el ts si no lo encuentra
 * @param valores   {[{ts,...}]}      arreglo de objetos valor
 * @param ts        {number}          valor de ts buscado
 * @return          {idx:int,encontrado:bool}
 */
export const bsearchTS = (valores,val) => {
  const idx = bounds.le(valores, val, compTS)
  return {idx, encontrado: idx>=0 && compTS(val,valores[idx])===0}
}

export const makeDateTime = (algo) => {
  if(DateTime.isDateTime(algo)) return algo
  if(typeof(algo) === 'string') return DateTime.fromISO(algo)
  if(typeof(algo) === 'number'){
    if(algo > 99999999999) return DateTime.fromMillis(algo)
    return DateTime.fromSeconds(algo)
  }
  if(algo instanceof Date) return DateTime.fromJSDate(algo)

  return algo
}

export const formatoIntervalo = (desde, hasta=null, options=null) => {
  const dtDesde = makeDateTime(desde)
  const dtHasta = !hasta ? DateTime.now().toUTC() : makeDateTime(hasta)
  const intervalo = Interval.fromDateTimes(dtDesde, dtHasta)
  //console.log('formatoIntervalo:',{desde,hasta,dtDesde,dtHasta,intervalo})
  const dur = intervalo.isValid ? intervalo.toDuration(['days','hours','minutes','seconds']).normalize() : Duration.fromMillis(0)
  const d = dur.toObject()
  let fmt = ''
  if(d.days >= 1) {
    fmt = "'hace' d 'días'" + (d.hours >= 1 ? "' y' h 'horas'" : '')
  } else if(d.hours >= 1){
    fmt = "'hace' h 'horas'" + (d.minutes ?  "' y' m 'minutos'" : '')
  } else if(d.minutes >= 1){
    fmt = "'hace' m 'minutos'" + (d.segundos ?  "' y' s 'segundos'" : '')
  } else if(d.seconds >= 1){
    fmt = "'hace' s 'segundos'"
  } else {
    fmt = "'ahora'"
  }
  return dur.toFormat(fmt)
}

export const formatoTS = (ts, options = null) => {
  const dt = makeDateTime(ts)
  return dt.toLocaleString(DateTime.DATETIME_SHORT)
}

export const combineGrapqlQueries = (q1, q2) => {
  const aq1 = q1.trim().split('\n').slice(0,-1),
  aq2 = q2.trim().split('\n').slice(1)
  return [...aq1, ...aq2].join('\n')
}

export const hasError = errors => {
  for(let k in errors){
    if(errors[k]) return true
  }
  return false
}

export const toFloat = n => {
  if(typeof(n) === 'number')
    return Number.isNaN(n) ? 0.0 : n
  if(typeof(n) === 'string'){
    const num = Number.parseFloat(n)
    return Number.isNaN(num) ? 0.0 : num
  }
  return 0.0
}

export const makeLoggers = modulo => {
  const loggers = {
    ll: debug(`${modulo}:log`),
    ee: debug(`${modulo}:ERROR`)
  }
  return loggers
}

export const enableLoggers = (...loggers) => {
  debug.enable(loggers.join(','))
}

export const disableLoggers = () => {
  debug.disable()
}

// module.exports = {
//   bsearchTS,
//   compTS,
//   makeDateTime,
//   formatoIntervalo,
//   formatoTS,
//   combineGrapqlQueries,
// }
