import React from 'react';
import { DateTime } from 'luxon'
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, Tooltip, ResponsiveContainer, CartesianGrid, Legend, ReferenceLine } from 'recharts';
import { FormControlLabel, Switch } from '@material-ui/core'
import { API } from 'aws-amplify'
import * as queries from '../graphql/queries'
//import * as subscriptions from '../graphql/subscriptions'
import FormConsultaDatos from '../components/FormConsultaDatos'
import { makeSensorInfo } from '../sensorInfo'
import TablaDatos from '../components/TablaDatos';

import { bsearchTS } from '../utils'
import { useStore, selInfoDetectores, selInfoSensores } from '../store'
import { useDatosAntenas } from '../components/hooksAntenas'
import { makeDetector } from '../detectorInfo'
import { TipoDetector } from '../enums'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  signOut: {
    width: '180px',
    backgroundColor: 'rgba(0,0,0,0)'
  },
  spacer: {
    flexGrow: 1
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '700px',
    overflow: 'hidden',
  },
  mainContent: {
    flexGrow: 1,
  },
  formulario: {
    width: '100%',
    flexGrow: 0,
    heigth: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'auto'
  },
  customTooltip: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    padding: '15px',
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
  },
  labelTooltip: {
    textAlign:'right',
  },
  labelTooltipTitulo: {
    fontSize: '+2',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '-10px',
  },
  headerLecturaL1: {
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  headerLecturaL2: {
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  minitoolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}))

const COLORES = [
  "#8884d8", "#82ca9d", "#ffc658", '#0088FE', '#00C49F', '#FFBB28', '#FF8042'
]
const REF_OPACITY = 0.3
const COLORES_REF = COLORES.map(c => {
  const r = c.substr(1,2), g = c.substr(3,2), b = c.substr(5,2)
  return `rgba(${Number.parseInt(r,16)},${Number.parseInt(g,16)},${Number.parseInt(b,16)},${REF_OPACITY})`
})
const rgbaChangeOpacity = (rgba,newOpacity) => {
  const res = rgba.match(/(rgba\(.+,)([0-9]+\.?[0-9]*)\)/)
  return `${res[1]}${newOpacity})`
}

const MAXCOLORES = COLORES.length

const formatTS = (secs) => {
  const ts = DateTime.fromSeconds(secs)
  return `${ts.toLocaleString({day:'numeric',month:'numeric'})} ${ts.toLocaleString(DateTime.TIME_24_SIMPLE)}`
}

function CustomTooltip({ payload, label, active, sensores }) {
  const classes = useStyles()
  const rex = /[0-9]+$/;
  console.log('CustomTooltip:',{ payload, label, active, sensores })
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltip}>
        <p key={'titulo'} className={classes.labelTooltip}>{`Fecha: ${formatTS(payload[0].payload.ts)}`}</p>
      { payload.map((p,i) => {
        const r = rex.exec(p.dataKey)
        const sensor = r && sensores.find(s => s.idSensor === r[0])
        const label = sensor?.label || p.name;
        return <>
          <p key={`lt${p.dataKey}`} className={classes.labelTooltipTitulo}>{ label }</p>
          <p key={`l${p.dataKey}`} className={classes.labelTooltip}>{`${p.name}: ${Number(p.value).toFixed(2)}`}</p>
        </>
      })}
      </div>
    );
  }

  return null;
}

const DOTS = ['circle',] // 'cross', 'diamond', 'square', 'star', 'triangle', 'wye' ]

function LabelRefLine({text1,text2,viewBox,algn,stroke}) {
  return (
    <>
    <Label position={`insideTop${algn}`} viewBox={viewBox} fill={rgbaChangeOpacity(stroke,1.0)}>
    {text1}
    </Label>
    <Label position={`insideBottom${algn}`} viewBox={viewBox} fill={rgbaChangeOpacity(stroke,1.0)}>
    {text2}
    </Label>
    </>
  )
}

const REF_LINE_LABEL_ALIGNS = [ "Left", "", "Right" ]
function getCustomRefLabel(idx,text1,text2,context,stroke){
  return <LabelRefLine text1={text1} text2={text2} viewBox={context.viewBox} algn={REF_LINE_LABEL_ALIGNS[idx % 3]} stroke={stroke}/>
}


function Chart({className, sensores, valores, ...other}) {
  const theme = useTheme();

  //const tituloLecturas = (valores.series && valores.series.length && valores.series[0].titulo) || ''
  const tiposAxis = valores.series.reduce((tipos,serie) => {
    if(tipos.find(t => t.clase === serie.clase) === undefined){
      return [...tipos, serie]
    }
    return tipos
  }, [])
  console.log('tiposAxis:',tiposAxis)

  const renderCustomAxisTick = ({ x, y, payload }) => {
    const ts = DateTime.fromSeconds(payload.value)
    return (
      <>
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={8} textAnchor="middle" fill="#666">{ts.toLocaleString(DateTime.TIME_24_SIMPLE)}</text>
      </g>
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={24} textAnchor="middle" fill="#666">{ts.toLocaleString({day:'numeric',month:'numeric'})}</text>
      </g>
      </>
    )
  }

  return (
    <div className={className} {...other} >
      <ResponsiveContainer width="100%" height={400}>

        <LineChart
          data={valores.valores}
          margin={{
            top: 16,
            right: 16,
            bottom: 16,
            left: 24,
          }}
        >
          <defs>
          { COLORES.map( (c,i) => (
            <linearGradient key={`LG${i}`} id={`color${i}`} x1="0" y1="0" x2="0" y2="1">
              <stop key="stop05" offset="5%" stopColor={c} stopOpacity={0.8}/>
              <stop key="stop95" offset="95%" stopColor={c} stopOpacity={0}/>
            </linearGradient>
          ))}
          </defs>

          <XAxis dataKey="ts" type="number" scale="time" domain={['auto','auto']} stroke={theme.palette.text.secondary} tick={renderCustomAxisTick}/>
          <YAxis yAxisId="eje0" stroke={theme.palette.text.primary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              {tiposAxis.length && (tiposAxis[0].clase || tiposAxis[0].unidad || tiposAxis[0].label)}
            </Label>
          </YAxis>
          { tiposAxis.length > 1 &&
            <YAxis yAxisId="eje1" stroke={theme.palette.text.secondary} orientation="right">
              <Label
                angle={270}
                position="right"
                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
              >
                {tiposAxis[1].clase || tiposAxis[1].unidad || tiposAxis[1].label}
              </Label>
            </YAxis>
          }
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={<CustomTooltip sensores={sensores} />} />
          <Legend verticalAlign="top" wrapperStyle={{ top: 10, lineHeight: '40px' }}/>
          { valores.series.map((s,i) => {
              s._d = { stroke:COLORES_REF[i], yAxisId:`eje${tiposAxis[0].clase === s.clase ? '0' : '1'}`, }
              const propsLine = {
                yAxisId:`eje${tiposAxis[0].clase === s.clase ? '0' : '1'}`,
                type:"monotoneX",
                dataKey:s.dataKey,
                name:s.label,
                legendType:DOTS[i % DOTS.length],
                stroke:COLORES[i],
                fillOpacity:1,
                fill:`url(#color${i % MAXCOLORES})`,
                dot:true,
                activeDot:{ r:6 },
                animationBegin: 500,
              }
              console.log('propsLine:',propsLine);
              return (
                  <Line key={`line${i}`} {...propsLine} />
          )})}
          { valores.series.filter(s => s.lineas && s.lineas.length > 0).map(s => s.lineas.map(l => ({...l,_s:s}))).flat().map((lin,i) => (
            <ReferenceLine key={`reflin${i}`} yAxisId={lin._s._d.yAxisId} y={lin.valor} stroke={lin._s._d.stroke} strokeWidth={5} strokeDasharray="5 5" label={context => getCustomRefLabel(i,lin.label,lin._s.label,context,lin._s._d.stroke)} />
          ))}

        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

/**
 * ********************************************************************
 */

function Tabla({ sensores, valores, ...other}) {
  const Columnas = [
    {
      field: 'tsf',
      title:'Fecha/Hora',
      width:150,
      align: 'right',
    }
  ]

  valores.series.forEach((serie, i) => {
    const [loc,measure] = serie.label.split('/')
    Columnas.push({
      field: serie.dataKey+'f',
      title: loc,
      subtitle: measure,
      width: 200,
      align: 'right',
    })
  });

  return (
    <TablaDatos
      datos={valores.valores}
      columnas={Columnas}
    />
  )

}


const incrementCargando = c => c+1
const decrementCargando = c => c > 0 ? c-1 : 0

const crearSensorList = (antenas, infoSensores) => {
  const sensores = []
  antenas.forEach(a => {
    const baseLabel = a.locacionActual ? a.locacionActual.nombreLocacion : a.idPlacaRB
    if(a.sensores){
      a.sensores.forEach((s, i) => {
        const infoSensor = infoSensores.find(is => is.id === s.idTipoSensor)
        if(infoSensor){
          infoSensor.forEachCampoValor({},(vo, i) => {
            sensores.push({
              label: `${baseLabel}/${vo.label}`,
              idSensor: s.idSensor,
              is: infoSensor,
              campo: vo.campo,
              tipo: vo.tipo,
              unidad: vo.unidad,
              clase: vo.clase || vo.unidad,
            })
          });
        }
      });
    }
  });

  return sensores
}

export default function PaginaConsultaDatos(){
  const classes = useStyles();
  const { antenas } = useDatosAntenas()
  const [valores, setValores] = React.useState({ series:[], valores:[]})
  const infoSensores = useStore(selInfoSensores).map(is => makeSensorInfo(is))
  const infoDetectores = useStore(selInfoDetectores)
  const [cargando, setCargando] = React.useState(0)
  const [mostrarChart, setMostrarChart] = React.useState(true)

  const sensores = antenas ? crearSensorList(antenas,infoSensores) : []

  const switchChartTable = (event) => {
    setMostrarChart(event.target.checked)
  }

  const handleConsultar = (consulta) => {
    console.log('consulta:',consulta)
    const varsConsulta = {
      periodo: {
        rango: undefined,
      },
      agregacion: consulta.agregacion
    }
    varsConsulta.periodo.rango = {
      fechaInicio: consulta.fechaInicio.toString(),
      fechaFin: consulta.fechaFin.toString(),
    }
    //console.log('vars gql:',varsConsulta)
    setValores({ series:[], valores:[]})
    setCargando(0)
    consulta.sensores.forEach(sens => {
      const vc = {...varsConsulta, idSensor:sens.idSensor}
      setCargando(incrementCargando)

      //cargar alertas para dibujar las líneas
      API.graphql({ query: queries.getAlertas, variables: { idSensor: sens.idSensor }})
      .then(result => {
        const alertas = result.data.getAlertas
        const lins = alertas.map(al => {
          const det = makeDetector(infoDetectores, al)
          //console.log('Lineas:det:',det)
          if(det.id === TipoDetector.DETECTOR_VALOR_MINIMO && det.av.valorMonitoreado.campoSensor === sens.campo){
            return { valor: Number.parseFloat(det.p.valorMinimo), label: det.paramsObj.valorMinimo.label }
          } else if(det.id === TipoDetector.DETECTOR_VALOR_MAXIMO && det.av.valorMonitoreado.campoSensor === sens.campo){
            return { valor: Number.parseFloat(det.p.valorMaximo), label: det.paramsObj.valorMaximo.label }
          } else return null
        }).filter(l => l !== null).flat()
        console.log('Lins:',lins)
        return lins
        //setLineas(state => [...state, ...lins])
      }).catch(e => {
        console.error('Error al cargar alertas:',e)
        return []
      }).then(lins => {
        API.graphql({ query: queries.getLecturasSensor, variables: vc })
        .then( result => {
          const lecturas = result.data.getLecturasSensor
          //console.log('getLecturasSensor: ',lecturas)
          //const sensor = sens //sensores.find(s => s.idSensor === ids)
          setCargando(decrementCargando)
          setValores((oldValores) => {
            const newValores = Array.from(oldValores.valores)
            const isv = sens.is
            const dataKey = `${sens.campo}-${sens.idSensor}`
            const serie = { dataKey, is:sens.is, label:isv.getLabelConUnidades(sens.campo), unidad:sens.unidad, clase:sens.clase, lineas:lins }
            console.log(`oldValores=`,newValores.length)
            lecturas.forEach((lectura, i) => {
              isv.lectura = lectura
              const v = {
                ts: DateTime.fromISO(lectura.fechaLectura).toSeconds(),
                tsf: isv.getValor('fechaLectura'),
              }
              const {idx,encontrado} = bsearchTS(newValores,v)
              if(!encontrado){
                v[dataKey] = isv.getValorRaw(sens.campo)
                v[dataKey+'f'] = isv.getValor(sens.campo)
                newValores.splice(idx+1,0,v)
              }else{
                newValores[idx][dataKey] = isv.getValorRaw(sens.campo)
                newValores[idx][dataKey+'f'] = isv.getValor(sens.campo)
              }
            });
            console.log(`newValores=`,newValores)
            return { series: [...oldValores.series, serie] , valores:newValores }
          })
        }).catch(e=>{
          console.error('Error en consulta:',e)
        })
      })
    })
  }

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <FormConsultaDatos sensores={sensores} handleConsultar={handleConsultar} cargando={cargando}/>
        <div className={classes.minitoolbar}>
          <FormControlLabel
            control={
              <Switch
                checked={mostrarChart}
                onChange={switchChartTable}
                name="mostrarChart"
                color="primary"
              />
            }
            label="Ver gráfico/tabla"
          />
        </div>
        { mostrarChart && valores.series.length &&
          <Chart className={classes.mainContent} sensores={sensores} valores={valores} />
        }
        { !mostrarChart && valores.series.length &&
          <Tabla className={classes.mainContent} sensores={sensores} valores={valores}/>
        }
      </main>
    </div>

  )

}
