import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeLoggers } from '../utils'
const {ll,ee} = makeLoggers('TablaDatos')

const useStyles = makeStyles((theme) => ({
  content: {
    // display: 'flex',
    // flexDirection: 'column',
    // width: '100%',
    overflow: 'auto',
    height: 500,
  },
  tabla: {
    tableLayout: 'fixed',
    width: 'auto'
  },
  headerLecturaL1: {
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  headerLecturaL2: {
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
  },
  body: {
  },
}))(TableCell);

const StyledTableCellRowHeader = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.info.contrastText,
    zIndex: 100,
  },
  body: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.info.contrastText,
    position: 'sticky',
    left: 0,
  },
}))(TableCell);

const Celda = ({idx, ...props}) => {
  return idx===0 ? <StyledTableCellRowHeader {...props} /> : <StyledTableCell {...props} />
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const HeaderCelda = ({idx, width, align, title, subtitle}) => {
  const classes = useStyles();
  const props = { idx }
  if(width !== undefined) props.width = width
  if(align !== undefined) props.align = align

  return (
    <Celda {...props}>
      <div className={classes.headerLecturaL1}>{title}</div>
      { subtitle !== undefined &&
        <div className={classes.headerLecturaL2}>{subtitle}</div>
      }
    </Celda>)
}

export default function TablaDatos({columnas, datos}) {
  const classes = useStyles();
  ll.enabled = false; ee.enabled = true

  ll('TABLA: props:',{columnas, datos})
  const cols = columnas || []
  const rows = datos || []

  return (
    <TableContainer className={classes.content}>
      <Table stickyHeader className={classes.tabla} size="small">
        <TableHead styles={{backgroundColor: 'gray'}}>
          <TableRow key={`rh${0}`}>
            { cols.map((c,i) => (
              <HeaderCelda key={i} idx={i} width={c.width} align={c.align} title={c.title} subtitle={c.subtitle} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        { rows.map((r,ir) => (
          <StyledTableRow key={`r${ir}`}>
          { cols.map((c,ic) => (
            <Celda idx={ic} key={`c${ic}`} align={c.align}>{r[c.field]}</Celda>
          ))}
          </StyledTableRow>
        ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
