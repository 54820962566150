/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getValoresEnum = /* GraphQL */ `
  query GetValoresEnum($tipo: String) {
    getValoresEnum(tipo: $tipo)
  }
`;
export const getValorEnum = /* GraphQL */ `
  query GetValorEnum($tipo: String, $idEnum: ID, $nombreCorto: String) {
    getValorEnum(tipo: $tipo, idEnum: $idEnum, nombreCorto: $nombreCorto) {
      idEnum
      tipoEnum
      nombreCorto
      nombreLargo
      datosAdicionales
    }
  }
`;
export const getClientes = /* GraphQL */ `
  query GetClientes($limit: Int, $offset: Int) {
    getClientes(limit: $limit, offset: $offset) {
      idCliente
      nombreCliente
      fechaCreacion
      idEstadoCliente
      antenas {
        idAntena
        idCliente
        idModeloAntena
        idPlacaRB
        activa
        idEstadoAntena
        idModoLectura
        intervaloLectura
        idLocacionActual
        fechaCreacion
        sensores {
          idSensor
          idAntena
          nombreSensor
          idTipoSensor
          idSensorPlaca
          idCalibracionSensor
          idUltimaLectura
          visibleSoloAdministradores
        }
        locacionActual {
          idLocacion
          idCliente
          idAntena
          nombreLocacion
          latitud
          longitud
          notas
          activa
          fechaCreacion
        }
      }
    }
  }
`;
export const getCliente = /* GraphQL */ `
  query GetCliente($idCliente: ID!) {
    getCliente(idCliente: $idCliente) {
      idCliente
      nombreCliente
      fechaCreacion
      idEstadoCliente
      antenas {
        idAntena
        idCliente
        idModeloAntena
        idPlacaRB
        activa
        idEstadoAntena
        idModoLectura
        intervaloLectura
        idLocacionActual
        fechaCreacion
        sensores {
          idSensor
          idAntena
          nombreSensor
          idTipoSensor
          idSensorPlaca
          idCalibracionSensor
          idUltimaLectura
          visibleSoloAdministradores
        }
        locacionActual {
          idLocacion
          idCliente
          idAntena
          nombreLocacion
          latitud
          longitud
          notas
          activa
          fechaCreacion
        }
      }
    }
  }
`;
export const getAntenas = /* GraphQL */ `
  query GetAntenas($idCliente: ID!, $limit: Int, $offset: Int) {
    getAntenas(idCliente: $idCliente, limit: $limit, offset: $offset) {
      idAntena
      idCliente
      idModeloAntena
      idPlacaRB
      activa
      idEstadoAntena
      idModoLectura
      intervaloLectura
      idLocacionActual
      fechaCreacion
      sensores {
        idSensor
        idAntena
        nombreSensor
        idTipoSensor
        idSensorPlaca
        idCalibracionSensor
        idUltimaLectura
        visibleSoloAdministradores
        calibracionActual {
          idCalibracionSensor
          idSensor
          idLocacion
          activo
          valorCalibracion1
          valorCalibracion2
          valorCalibracion3
          valorCalibracion4
          valorCalibracion5
          notas
          fechaCreacion
          idTipoSensor
          idTipoDetectorAlerta
          configDetectorAlerta
          variableAlerta
        }
        ultimaLectura {
          idLectura
          idSensor
          idLocacion
          idCalibracionSensor
          valorLeido
          datosAdicionales
          valorCalculado1
          valorCalculado2
          valorCalculado3
          valorCalculadoAdicional
          fechaLectura
          fechaCreacion
        }
      }
      locacionActual {
        idLocacion
        idCliente
        idAntena
        nombreLocacion
        latitud
        longitud
        notas
        activa
        fechaCreacion
      }
    }
  }
`;
export const getAntena = /* GraphQL */ `
  query GetAntena($idAntena: ID, $idPlacaRB: String, $idSensor: ID) {
    getAntena(idAntena: $idAntena, idPlacaRB: $idPlacaRB, idSensor: $idSensor) {
      idAntena
      idCliente
      idModeloAntena
      idPlacaRB
      activa
      idEstadoAntena
      idModoLectura
      intervaloLectura
      idLocacionActual
      fechaCreacion
      sensores {
        idSensor
        idAntena
        nombreSensor
        idTipoSensor
        idSensorPlaca
        idCalibracionSensor
        idUltimaLectura
        visibleSoloAdministradores
        calibracionActual {
          idCalibracionSensor
          idSensor
          idLocacion
          activo
          valorCalibracion1
          valorCalibracion2
          valorCalibracion3
          valorCalibracion4
          valorCalibracion5
          notas
          fechaCreacion
          idTipoSensor
          idTipoDetectorAlerta
          configDetectorAlerta
          variableAlerta
        }
        ultimaLectura {
          idLectura
          idSensor
          idLocacion
          idCalibracionSensor
          valorLeido
          datosAdicionales
          valorCalculado1
          valorCalculado2
          valorCalculado3
          valorCalculadoAdicional
          fechaLectura
          fechaCreacion
        }
      }
      locacionActual {
        idLocacion
        idCliente
        idAntena
        nombreLocacion
        latitud
        longitud
        notas
        activa
        fechaCreacion
      }
    }
  }
`;
export const getSensorPlaca = /* GraphQL */ `
  query GetSensorPlaca($idAntena: ID!, $idSensorPlaca: Int!) {
    getSensorPlaca(idAntena: $idAntena, idSensorPlaca: $idSensorPlaca) {
      idSensor
      idAntena
      nombreSensor
      idTipoSensor
      idSensorPlaca
      idCalibracionSensor
      idUltimaLectura
      visibleSoloAdministradores
      calibracionActual {
        idCalibracionSensor
        idSensor
        idLocacion
        activo
        valorCalibracion1
        valorCalibracion2
        valorCalibracion3
        valorCalibracion4
        valorCalibracion5
        notas
        fechaCreacion
        idTipoSensor
        idTipoDetectorAlerta
        configDetectorAlerta
        variableAlerta
      }
      ultimaLectura {
        idLectura
        idSensor
        idLocacion
        idCalibracionSensor
        valorLeido
        datosAdicionales
        valorCalculado1
        valorCalculado2
        valorCalculado3
        valorCalculadoAdicional
        fechaLectura
        fechaCreacion
      }
    }
  }
`;
export const getSensores = /* GraphQL */ `
  query GetSensores($idAntena: ID!) {
    getSensores(idAntena: $idAntena) {
      idSensor
      idAntena
      nombreSensor
      idTipoSensor
      idSensorPlaca
      idCalibracionSensor
      idUltimaLectura
      visibleSoloAdministradores
      calibracionActual {
        idCalibracionSensor
        idSensor
        idLocacion
        activo
        valorCalibracion1
        valorCalibracion2
        valorCalibracion3
        valorCalibracion4
        valorCalibracion5
        notas
        fechaCreacion
        idTipoSensor
        idTipoDetectorAlerta
        configDetectorAlerta
        variableAlerta
      }
      ultimaLectura {
        idLectura
        idSensor
        idLocacion
        idCalibracionSensor
        valorLeido
        datosAdicionales
        valorCalculado1
        valorCalculado2
        valorCalculado3
        valorCalculadoAdicional
        fechaLectura
        fechaCreacion
      }
    }
  }
`;
export const getInfoTipoSensor = /* GraphQL */ `
  query GetInfoTipoSensor($idTipoSensor: ID!) {
    getInfoTipoSensor(idTipoSensor: $idTipoSensor) {
      id
      nombre
      descripcion
      instrucciones
      camposCalibracion {
        campo
        label
        ayuda
        tipo
        min
        max
        requerido
        tipoFuente
        fuente
        modificador
      }
      seccionesCalibracion {
        nombre
        titulo
        nivel
        campos
      }
      valoresObtenidos {
        campo
        tipo
        label
        unidad
        formato {
          decimales
          style
        }
        consultable
        mostrarEnCalibracion
      }
    }
  }
`;
export const getInfoTiposSensores = /* GraphQL */ `
  query GetInfoTiposSensores {
    getInfoTiposSensores {
      id
      nombre
      descripcion
      instrucciones
      camposCalibracion {
        campo
        label
        ayuda
        tipo
        min
        max
        requerido
        tipoFuente
        fuente
        modificador
      }
      seccionesCalibracion {
        nombre
        titulo
        nivel
        campos
      }
      valoresObtenidos {
        campo
        tipo
        label
        unidad
        formato {
          decimales
          style
        }
        consultable
        mostrarEnCalibracion
      }
    }
  }
`;
export const getCalibracionSensor = /* GraphQL */ `
  query GetCalibracionSensor($idSensor: ID!) {
    getCalibracionSensor(idSensor: $idSensor) {
      idCalibracionSensor
      idSensor
      idLocacion
      activo
      valorCalibracion1
      valorCalibracion2
      valorCalibracion3
      valorCalibracion4
      valorCalibracion5
      notas
      fechaCreacion
      idTipoSensor
      idTipoDetectorAlerta
      configDetectorAlerta
      variableAlerta
    }
  }
`;
export const getLecturasCalibracion = /* GraphQL */ `
  query GetLecturasCalibracion(
    $idPlacaRB: String
    $idAntena: ID
    $idSensorPlaca: ID
    $idSensor: ID
  ) {
    getLecturasCalibracion(
      idPlacaRB: $idPlacaRB
      idAntena: $idAntena
      idSensorPlaca: $idSensorPlaca
      idSensor: $idSensor
    ) {
      idLecturaCalibracion
      idSensor
      valorLeido
      datosAdicionales
      fechaLectura
      fechaCreacion
    }
  }
`;
export const procesarLecturas = /* GraphQL */ `
  query ProcesarLecturas(
    $idSensor: ID
    $calibracion: Calibracion
    $valores: [ValorLeido!]!
  ) {
    procesarLecturas(
      idSensor: $idSensor
      calibracion: $calibracion
      valores: $valores
    ) {
      valorLeido
      datosAdicionales
      fechaLectura
      valorCalculado1
      valorCalculado2
      valorCalculado3
      valorCalculadoAdicional
    }
  }
`;
export const getLecturasSensor = /* GraphQL */ `
  query GetLecturasSensor(
    $idSensor: ID!
    $periodo: Periodo!
    $agregacion: Agregacion
    $limit: Int
    $offset: Int
  ) {
    getLecturasSensor(
      idSensor: $idSensor
      periodo: $periodo
      agregacion: $agregacion
      limit: $limit
      offset: $offset
    ) {
      idLectura
      idSensor
      idLocacion
      idCalibracionSensor
      valorLeido
      datosAdicionales
      valorCalculado1
      valorCalculado2
      valorCalculado3
      valorCalculadoAdicional
      fechaLectura
      fechaCreacion
    }
  }
`;
export const getInfoTiposDetectorAlerta = /* GraphQL */ `
  query GetInfoTiposDetectorAlerta {
    getInfoTiposDetectorAlerta {
      id
      nombre
      descripcion
      params {
        param
        label
        desc
        tipo
        min
        max
        default
        requerido
      }
      vars {
        nombreVariable
        tipo
        label
        desc
      }
      estados {
        nombreEstado
        desc
        reportar
        reportarNivel
      }
      estadoInicial
    }
  }
`;
export const getAlertas = /* GraphQL */ `
  query GetAlertas($idCliente: ID, $idAntena: ID, $idSensor: ID) {
    getAlertas(
      idCliente: $idCliente
      idAntena: $idAntena
      idSensor: $idSensor
    ) {
      idAlerta
      idSensor
      idDetector
      borrada
      habilitada
      valoresParametros {
        nombreParametro
        valor
      }
      asignacionesVariables {
        nombreVariable
        campoSensor
        conversion
      }
      estadoActual
      variablesEstadoActual
      fechaEstadoActual
      estadoReportado
      fechaEstadoReportado
      variablesEstadoReportado
      fechaCreacion
      notificaciones {
        idNotificacionAlerta
        idAlerta
        nombreEstado
        evento
        nivelAlerta
        generarLog
        generarEmail
        destinatariosEmail
        alertaPush
        destinatariosPush
        plantilla
        activa
      }
    }
  }
`;
export const getAlerta = /* GraphQL */ `
  query GetAlerta($idAlerta: ID!) {
    getAlerta(idAlerta: $idAlerta) {
      idAlerta
      idSensor
      idDetector
      borrada
      habilitada
      valoresParametros {
        nombreParametro
        valor
      }
      asignacionesVariables {
        nombreVariable
        campoSensor
        conversion
      }
      estadoActual
      variablesEstadoActual
      fechaEstadoActual
      estadoReportado
      fechaEstadoReportado
      variablesEstadoReportado
      fechaCreacion
      notificaciones {
        idNotificacionAlerta
        idAlerta
        nombreEstado
        evento
        nivelAlerta
        generarLog
        generarEmail
        destinatariosEmail
        alertaPush
        destinatariosPush
        plantilla
        activa
      }
    }
  }
`;
export const getNotificacionesAlerta = /* GraphQL */ `
  query GetNotificacionesAlerta($idAlerta: ID!) {
    getNotificacionesAlerta(idAlerta: $idAlerta) {
      idNotificacionAlerta
      idAlerta
      nombreEstado
      evento
      nivelAlerta
      generarLog
      generarEmail
      destinatariosEmail
      alertaPush
      destinatariosPush
      plantilla
      activa
    }
  }
`;
export const getRegistroAlerta = /* GraphQL */ `
  query GetRegistroAlerta(
    $idAlerta: ID!
    $desde: AWSDateTime!
    $hasta: AWSDateTime!
    $limit: Int
    $offset: Int
  ) {
    getRegistroAlerta(
      idAlerta: $idAlerta
      desde: $desde
      hasta: $hasta
      limit: $limit
      offset: $offset
    ) {
      idAlerta
      idSensor
      idDetector
      borrada
      habilitada
      valoresParametros {
        nombreParametro
        valor
      }
      asignacionesVariables {
        nombreVariable
        campoSensor
        conversion
      }
      estadoActual
      variablesEstadoActual
      fechaEstadoActual
      estadoReportado
      fechaEstadoReportado
      variablesEstadoReportado
      fechaCreacion
      notificaciones {
        idNotificacionAlerta
        idAlerta
        nombreEstado
        evento
        nivelAlerta
        generarLog
        generarEmail
        destinatariosEmail
        alertaPush
        destinatariosPush
        plantilla
        activa
      }
    }
  }
`;
