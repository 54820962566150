import React from 'react'
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'



const useStyles = makeStyles((theme) => ({
  contenedorLista: {
    flexGrow: 1,
    overflow: 'auto',
  },
  lista: {
    minHeight: 400,
    width: '100%'
  },
  listItem: {
    minHeight: 40,
  },

}))

/**
 * Antena
 */
const Elemento = ({ elemento, selected, iconRender, render, botones, onClickElemento, onClickBoton }) => {
  const classes = useStyles()
  console.log('Elemento:',elemento)
  return (
    <ListItem className={classes.listItem} button dense onClick={() => (onClickElemento && onClickElemento({elemento}))} selected={selected}>
      { iconRender && iconRender(elemento) }
      { render && render(elemento)}
      { !render && (
        <ListItemText
          primary={elemento.primaryText}
          secondary={elemento.secondaryText}
        />
      )}
      <ListItemSecondaryAction>
      { botones && botones.map(b => (
        <Tooltip key={b.idBoton} title={b.tooltip} placement="top">
          <IconButton disabled={b.disabled} size="small" edge="end" onClick={() => onClickBoton({elemento, idBoton: b.idBoton})}>
            {b.icon}
          </IconButton>
        </Tooltip>
      ))}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

/**
 * Componente para mostrar la lista de eleemntos
 */
export function ListaElementos({ elementos, selectedElementoId, botones, onClickElemento, onClickBoton, renderElementIcon, renderElement }) {
  const classes = useStyles()

  return (
    <div className={classes.contenedorLista}>
    <List>
    { elementos.map((e,i) => (
      <Elemento key={e.id || i}
        elemento={e}
        selected={selectedElementoId === e.id}
        iconRender={renderElementIcon}
        render={renderElement}
        {...{botones, onClickElemento, onClickBoton}}
      />
    ))}
    </List>
  </div>
  )
}
