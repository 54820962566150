import React from 'react';
import { API } from 'aws-amplify'
import { makeSensorInfo } from '../sensorInfo'
import BotonRefrescar from './BotonRefrescar'
import { getAntenas } from '../graphql/queries'
import { onUpdatedAntena } from '../graphql/subscriptions'
import { useStore, selInfoSensores, selUserClientId } from '../store'
import { makeLoggers } from '../utils';
const {ll,ee} = makeLoggers('hooksAntenas','log')

export function useDatosAntenas() {
  const [antenas, setAntenas] = React.useState([])
  const [refrescando, setRefrescando] = React.useState(false)
  const infoSensores = useStore(selInfoSensores)
  const idCliente = useStore(selUserClientId)

  ll.enabled = true; ee.enabled = true;

  const _loadDatosAntenas = React.useCallback(async () => {
    if(!idCliente) return
    setRefrescando(true)
    try {
      const aa = await API.graphql({ query: getAntenas, variables: { idCliente } })
      const antenas = aa.data.getAntenas
      antenas.forEach(a => {
        a.sensores.forEach((s, i) => {
          const its = infoSensores.find(is => s.idTipoSensor === is.id)
          if(its) s.infoTipoSensor = makeSensorInfo(its, s.ultimaLectura)
        });
      });
      ll('useDatosAntenas: loaded:%o',antenas)
      setAntenas(antenas)
    }catch(e){
      ee('Error refrescandoAntenas:',e)
      setTimeout(_loadDatosAntenas,1500) //retry
    }finally{
      setRefrescando(false)
    }
  },[idCliente,infoSensores])


  const refreshAntenas = async () => {
    await _loadDatosAntenas()
  }

  React.useEffect(() => {
    _loadDatosAntenas()
    .catch(e => {
      ee('Error al leer antenas:',e)
    })
  }, [_loadDatosAntenas]);

  React.useEffect(() => {
    const subscription = API
      .graphql({ query: onUpdatedAntena, variables:{ idCliente }})
      .subscribe({
        next: ({ provider, value }) => {
          ee('onUpdatedAntena.data:',value);
        },
        error: (error) => {
          ee('onUpdatedAntena subscription error:',error);
        },
      });
    return () => {
      subscription.unsubscribe();
    }
  });

  const BotonRefrescoAntenas = ({caption="Actualizar"}) => (
    <BotonRefrescar caption={caption} refrescando={refrescando} doRefresh={refreshAntenas} />
  )

  /**
   * el hook retorna una variable de estado con los datos de las antenas, una
   * función para forzar la actualización de los datos. y un botón para el refresco
   */
   return {
     antenas,
     refreshAntenas,
     BotonRefrescoAntenas,
     refrescandoAntenas:refrescando,
   }

}
