import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';

import {ReactComponent as EmigoLogo} from '../assets/logo emigo tr.svg';
import ExploreIcon from '@material-ui/icons/Explore'
import TableChartIcon from '@material-ui/icons/TableChart'
import BarChartIcon from '@material-ui/icons/BarChart'
import SettingsIcon from '@material-ui/icons/Settings'
import NotificationsIcon from '@material-ui/icons/Notifications'
import AccountIcon from '@material-ui/icons/AccountCircle'

import PaginaAjustes from './PaginaAjustes'
import PaginaConsultaDatos from './PaginaConsultaDatos'
import PaginaUbicaciones from './PaginaUbicaciones'
import PaginaUltimasLecturas from './PaginaUltimasLecturasTable'
import PaginaAlertas from './PaginaAlertas'
import PaginaKPI from './PaginaKPI'
import PaginaUsuarios from './PaginaUsuarios'


import { useStore, selUserIsAdmin, selUserIsROOT, selUserEmail } from '../store'
import DialogCerrarSesion from '../components/DialogCerrarSesion'
import { makeLoggers } from '../utils'
const {ee} = makeLoggers('PBase')

const drawerWidth = 220;
const LOGO_SIZE = 60;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    //backgroundColor: 'rgba(0,255,255,0.5)'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  cuentaButton: {
    marginRight: theme.spacing(3),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarContent: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    //backgroundColor: 'rgba(0,255,255,0.5)'
    //flexGrow: 1,
    width: '100%',
    padding: theme.spacing(2),
  },
  logo: {
    display: 'inline',
    height: LOGO_SIZE,
    width: LOGO_SIZE,
    padding: 5,
    marginRight: theme.spacing(2),
    backgroundColor: 'rgba(255,255,255,1.0)'
  },
  selectedMenuText: {
    color: theme.palette.primary.contrastText,
  },
  selectedMenuItem: {
    background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.success.dark} 90%)`,
    borderRadius: 3,
    border: 0,
    //color: 'white',
    height: 48,
    //padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  selectedMenuIcon: {
    color: theme.palette.primary.contrastText,
  },
  tituloPaginaAppBar: {
    marginLeft: theme.spacing(4)
  },
  logoCentrado: {
    height: LOGO_SIZE,
    width: LOGO_SIZE,
    [theme.breakpoints.down('xs')]: {
      marginLeft: drawerWidth/2-LOGO_SIZE/2,
    },
    marginTop: 5,
  },
  spacer: {
    flexGrow: 1
  },
}))


const PAGINA_LOCACIONES = 0
const PAGINA_ULTIMAS_LECTURAS = 1
const PAGINA_EXPLORAR = 2
const PAGINA_ALERTAS = 3
const PAGINA_KPI = 4
const PAGINA_AJUSTES = 5
const PAGINA_CUENTA = 7
const PAGINA_USUARIOS = 8
const DIVIDER = 99

const IconTypes = [
  <ExploreIcon />,
  <TableChartIcon />,
  <BarChartIcon />,
  <NotificationsIcon />,
  <BarChartIcon />,
  <SettingsIcon />,
//  <AccountIcon />,
]

function getIcon(id, props) {
  const t = IconTypes[id]
  return <t.type {...props} />
}
const MENU = [
  { id: PAGINA_LOCACIONES, text: 'Locaciones' },
  { id: PAGINA_ULTIMAS_LECTURAS, text: 'Últimas lecturas' },
  { id: PAGINA_EXPLORAR, text: 'Explorar Datos' },
  { id: PAGINA_ALERTAS, text: 'Alertas' },
  { id: PAGINA_KPI, text: 'KPI' },
  { id: PAGINA_AJUSTES, text: 'Ajustes', adminOnly:true },
  { id: DIVIDER, text:'' },
  { id: PAGINA_CUENTA, text: 'Cuenta', hidden:true },
  { id: PAGINA_USUARIOS, text: 'Usuarios', hidden:true },
];

function MenuLink({menuItem, selectedId, handleClick, ...props}) {
  const classes = useStyles()
  const { id, text } = menuItem
  if(id === DIVIDER){
    return <Divider />
  }
  const selected = id === selectedId
  const iconProps = selected ? { color:'primary', classes:{ colorPrimary: classes.selectedMenuIcon } } : {}
  const textProps = selected ? { root: classes.selectedMenuText } : {}
  const itemProps = { selected: classes.selectedMenuItem }
  return (
    <ListItem button {...props} selected={selected} onClick={() => handleClick(id)} classes={itemProps}>
      <ListItemIcon>{getIcon(id, iconProps)}</ListItemIcon>
      <ListItemText primary={text} classes={textProps}/>
    </ListItem>
  )
}

function MenuCuenta({anchorEl, open, handleClose, username, handleLogout, handleAdminUsuarios}) {
  const esROOT = useStore(selUserIsROOT)

  return (
  <Menu
    getContentAnchorEl={null}
    anchorEl={anchorEl}
    keepMounted
    open={open}
    onClose={handleClose}
    variant={'menu'}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    >
    <MenuItem key={'menuUsuario'} disabled={true}>Usuario: {username}</MenuItem>
    { esROOT &&
      <MenuItem key={'menuAdminUsuarios'} onClick={handleAdminUsuarios}>Administrar usuarios</MenuItem>
    }
    <MenuItem key={'menuLogout'} onClick={handleLogout}>Cerrar sesión</MenuItem>
  </Menu>)
}

export default function PaginaBase() {
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [selectedPage, setSelectedPage] = React.useState(0)
  const esAdmin = useStore(selUserIsAdmin)
  const username = useStore(selUserEmail)
  const [openMenu, setOpenMenu] = React.useState(false)
  const menuAnchorRef = React.useRef(null);
  const [openConfirmDlg, setOpenConfirmDlg] = React.useState(false)

  ee.enabled = true;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleClickItem = itemId => {
    setSelectedPage(itemId)
  }

  const handleOpenMenuCuenta = () => {
    setOpenMenu(true)
  }

  const handleCloseMenuCuenta = () => {
    setOpenMenu(false)
  }


  const confirmSignOut = () => {
    setOpenMenu(false)
    setOpenConfirmDlg(true)
  }

  const handleCancelSignOut = () => {
    setOpenMenu(false)
    setOpenConfirmDlg(false)
  }

  const handleCerrarSesion = async () => {
    setOpenMenu(false)
    try {
      await Auth.signOut()
    }catch(e){
      ee(e)
    } finally {
      setOpenConfirmDlg(false)
    }
  }

  const handleAdminUsuarios = () => {
    setOpenMenu(false)
    handleClickItem(PAGINA_USUARIOS)
  }

  const drawer = (
    <div>
      <div className={classes.toolbar}>
      <EmigoLogo className={classes.logoCentrado} />
      </div>
      <Divider />
      <List>
        {MENU.filter(mi => !mi.hidden && (!mi.adminOnly || esAdmin)).map((mi,i) => (
          <MenuLink key={i} menuItem={mi} selectedId={selectedPage} handleClick={handleClickItem} />
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbarContent}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Hidden smUp implementation="js">
            <EmigoLogo className={classes.logo} />
          </Hidden>
          <Typography variant="h6" noWrap >{MENU[selectedPage].text}</Typography>
          <div className={classes.spacer} />
          <IconButton
            color="inherit"
            aria-label="Cuenta"
            edge="end"
            onClick={handleOpenMenuCuenta}
            ref={menuAnchorRef}
          >
            <AccountIcon />
          </IconButton>
          <MenuCuenta
            anchorEl={menuAnchorRef.current}
            username={username}
            open={openMenu}
            handleClose={handleCloseMenuCuenta}
            handleLogout={confirmSignOut}
            handleAdminUsuarios={handleAdminUsuarios}
          />
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="opciones del menú">
        <Hidden smUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >{drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="js">
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main id="PaginaBase" className={classes.content}>
        <div className={classes.toolbar} />
        { selectedPage === PAGINA_LOCACIONES && (
          <PaginaUbicaciones />
        )}
        { selectedPage === PAGINA_ULTIMAS_LECTURAS && (
          <PaginaUltimasLecturas />
        )}
        { selectedPage === PAGINA_EXPLORAR && (
          <PaginaConsultaDatos />
        )}
        { selectedPage === PAGINA_ALERTAS && (
          <PaginaAlertas />
        )}
        { selectedPage === PAGINA_KPI && (
          <PaginaKPI />
        )}
        { selectedPage === PAGINA_AJUSTES && (
          <PaginaAjustes />
        )}
        { selectedPage === PAGINA_USUARIOS && (
          <PaginaUsuarios />
        )}

        <DialogCerrarSesion
          open={openConfirmDlg}
          handleCancelar={handleCancelSignOut}
          handleCerrarSesion={handleCerrarSesion}
        />
      </main>
    </div>
  );
}
