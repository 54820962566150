import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControlLabel, Switch } from '@material-ui/core'
import MapaAntenas from '../components/MapaAntenas'
import BotonRefrescar from '../components/BotonRefrescar'
import { useDatosAntenas } from '../components/hooksAntenas'
import { useDatosAlertas } from '../components/hooksAlertas'
import { makeDetector } from '../detectorInfo'
import { useStore, selInfoDetectores, selUserIsAdmin } from '../store'
import { makeLoggers } from '../utils'
const {ll} = makeLoggers('PUbicaciones')

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '500px',
  },
  content: {
    flexGrow: 1,
    minHeight: '500px',
  },
  minitoolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  switch: {
    marginLeft: theme.spacing(2)
  }
}))

export default function PaginaUbicaciones(){
  const esAdmin = useStore(selUserIsAdmin)
  const classes = useStyles();
  const { antenas, refreshAntenas, refrescandoAntenas } = useDatosAntenas()
  const { alertas, refreshAlertas, refrescandoAlertas } = useDatosAlertas()
  const infoDetectores = useStore(selInfoDetectores)
  const [showText, setShowText] = React.useState(true)

  ll.enabled = false;

  const alertasP = alertas.map(al => ({...al, detector: makeDetector(infoDetectores,al)}))
  //agregamos la información de las alertas a cada sensor
  const antenasAl = antenas.map(a => {
    const ss = a.sensores.map(s => {
      const als = alertasP.filter(al => al.idSensor === s.idSensor)
      return {...s, alertas:als}
    })
    return {...a, sensores:ss}
  });
  ll('antenasAl:',antenasAl);

  const doRefrescar = () => {
    refreshAlertas()
    refreshAntenas()
  }

  const toggleShowText = () => {
    setShowText(!showText)
  }

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.minitoolbar}>
        <BotonRefrescar refrescando={refrescandoAntenas || refrescandoAlertas} doRefresh={doRefrescar} />
        <FormControlLabel className={classes.switch}
          control={
            <Switch
              checked={showText}
              onChange={toggleShowText}
              name="showText"
              color="primary"
            />
          }
          label="Ver nombres"
        />
        </div>
        <MapaAntenas antenas={antenasAl} esAdmin={esAdmin} mostrarNombres={showText}/>
      </main>
    </div>
  )

}
