/**
 * Pagina que muestra el listado de antenas y permite su administracion.
 * NOTA: Se ha deshabilitado la regla eqeqeq de ESLint ya que aunque el esquema GraphQL se definió con los tipos de datos
 * correctos, al igual que la base de datos, los queries retornan siempre todos los campos como strings
 */

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { API } from 'aws-amplify'
import * as mutations from '../graphql/mutations'
import MapaAntenas from '../components/Mapa'
import ListaAntenasBase from '../components/ListaAntenasBase'
import LoadingIcon from '../components/loading'
import { useDatosAntenas } from '../components/hooksAntenas'

import FormAntena from '../components/FormAntena'
import FormLocacion from '../components/FormLocacionAntena'
import FormCalibracion from '../components/FormCalibracion2'

import AddIconTwoTone from '@material-ui/icons/AddTwoTone';
import EditLocationTwoToneIcon from '@material-ui/icons/EditLocationTwoTone'
import TuneTwoToneIcon from '@material-ui/icons/TuneTwoTone'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import { Button, Grid } from '@material-ui/core'
import { useStore, selUserClientId, selUserNivel } from '../store'
import { useMapStateStore, selEditAntena, selSetEdit, selSetEditAntena, selSetAntena, selSetEditAntenaSensor } from '../mapStateStore'
import shallow from 'zustand/shallow'
import { makeLoggers } from '../utils'
const {ll,ee} = makeLoggers('PAjustes')

const EditIcon = EditTwoToneIcon


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  botonera: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  botonBotonera: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const ACCION_EDIT_ANTENA = 1
const ACCION_EDIT_LOCACION = 2
const ACCION_EDIT_CALIBRACION = 3

const BOTON_EDIT_ANTENA = {
  idBoton: ACCION_EDIT_ANTENA,
  tooltip:'Modificar los datos de la antena',
  icon: <EditIcon />,
  disabled: false,
}

const BOTON_EDIT_LOCACION = {
  idBoton: ACCION_EDIT_LOCACION,
  tooltip:'Asignar/modificar la ubicación de la antena',
  icon: <EditLocationTwoToneIcon />,
  disabled: false,
}

const BOTON_EDIT_CALIBRACION = {
  idBoton: ACCION_EDIT_CALIBRACION,
  tooltip:'Realizar la calibración de sensores',
  icon: <TuneTwoToneIcon />,
  disabled: false,
  menuSetup: antena => {
    const menuItems = []
    antena.sensores.filter(s => s.infoTipoSensor.camposCalibracion?.length).forEach(s => {
      const secciones = s.infoTipoSensor.seccionesCalibracion || [{ nombre: 'calibracion', titulo:'Calibración', nivel: 0, campos: null}]
      secciones.forEach(sec => {
        menuItems.push({
          idSensor: s.idSensor,
          seccion: sec.nombre,
          nivel: sec.nivel,
          titulo: sec.titulo,
        })
      });
    });
    return menuItems
  },
}

const calcMapViewBounds = (antenas,editAntena) => {
  let minLat, maxLat, minLon, maxLon
  const marks = !antenas ? [] : antenas.filter(a => Boolean(a.idLocacionActual))
  //ll('marks antenas: ', marks)
  if(editAntena?.antena?.idLocacionActual){
    minLat = maxLat = editAntena.antena.locacionActual.latitud
    minLon = maxLon = editAntena.antena.locacionActual.longitud
  } else if(marks.length > 0){
    marks.forEach((a, i) => {
      if(!minLat || minLat>a.locacionActual.latitud) minLat = a.locacionActual.latitud
      if(!maxLat || maxLat<a.locacionActual.latitud) maxLat = a.locacionActual.latitud
      if(!minLon || minLon>a.locacionActual.longitud) minLon = a.locacionActual.longitud
      if(!maxLon || maxLon<a.locacionActual.longitud) maxLon = a.locacionActual.longitud
    });
  } else {
    minLat = maxLat = -33.4724224
    minLon = maxLon = -70.7702551
  }
  const margen = 5.0/40000.0*360  //5km
  const bnds = [[minLat-margen,minLon-margen],[maxLat+margen,maxLon+margen]]
  //ll('map bounds:',bnds)
  return bnds
}
/**
 * Componente principal. Recibe como prop el objeto User actualmenteidBoton conectado
 */
export default function PaginaAjustesAntenas() {
  const idCliente = useStore(selUserClientId)
  const nivelUsuario = useStore(selUserNivel)
  const classes = useStyles();
  const {antenas, BotonRefrescoAntenas, refreshAntenas} = useDatosAntenas()
  const editAntena = useMapStateStore(selEditAntena,shallow)
  const setEditAntena = useMapStateStore(selSetEditAntena)
  const setEditAntenaSensor = useMapStateStore(selSetEditAntenaSensor)
  const setAntena = useMapStateStore(selSetAntena)
  const setEdit = useMapStateStore(selSetEdit)
  const [clickedLatLng, setClickedLatLng] = React.useState({lat:null,lng:null})
  const [mapViewBounds, setMapViewBounds] = React.useState(calcMapViewBounds(antenas,editAntena))

  ll.enabled = true; ee.enabled = true;

  const marks = React.useMemo(() => {
    return !antenas ? [] : antenas.filter(a => Boolean(a.idLocacionActual))
  },[antenas])

  React.useEffect(() => {
    const bnds = calcMapViewBounds(antenas,editAntena)
    //if(!mapViewBounds || (bnds[0][0]-mapViewBounds[0][0] > 0.00001 && bnds[0][1]-mapViewBounds[0][1] > 0.00001))
      setMapViewBounds(bnds)
  },[antenas,editAntena])

  const onClickBoton = async ({antena, idBoton, datos}) => {
    ll('onClick:',{antena,idBoton})
    switch(idBoton){
      case ACCION_EDIT_ANTENA:
        setEditAntena({antena, edit:'antena'})
      break
      case ACCION_EDIT_LOCACION:
        setEditAntena({antena, edit:'locacion'})
      break
      case ACCION_EDIT_CALIBRACION:
        abrirCalibracion(antena, datos, nivelUsuario)
      break
      default://solo selecciona
        setEditAntena({antena, edit:''})
      break
    }
  }

  const abrirCalibracion = (antena, datos) => {
    const sensor = antena.sensores.find(s => s.infoTipoSensor.camposCalibracion.length > 0)
    if(!sensor) return  //no hay sensores calibrables
    //setInicializandoForm(true)
    ll('abrirCalibracion:',{antena,sensor})
    setEditAntenaSensor({antena, edit:'calibracion', sensor, seccion:datos.seccion});
  }

  const onClickAntena = ({antena}) => {
    setAntena(antena)
  }

  const handleEditedAntena = async ({tipo,antena,locacion,calibracion}) => {
    ll('handleEditedAntena:',tipo,antena,locacion,calibracion)
    if(!tipo) return
    if(!antena) throw new Error('No viene la antena')

    if(tipo === 'antena') {
      try {
        antena.idCliente = idCliente
        const gqlop = {
          query: antena.idAntena ? mutations.updAntena : mutations.addAntena,
          variables: {...antena, idCliente}
        }
        //ll(`qry:${gqlop.query} vars:`,gqlop.variables)
        const res = await API.graphql(gqlop)
        //ll('res grabar antena:',res)
        return res
      } catch(e) {
        ee('error al grabar antena:',e)
        throw e
      }

    } else if(tipo === 'locacion') {

      if(!locacion) throw new Error('no viene la locacion')
      if(!locacion.idAntena) locacion.idAntena = antena.idAntena
      try {
        const gqlop = {
          query: mutations.setLocacionAntena,
          variables: locacion
        }
        //ll(`qry:${gqlop.query} vars:`,gqlop.variables)
        const res = await API.graphql(gqlop)
        //ll('res grabar locacion:',res)
        return res
      } catch(e) {
        ee('error al grabar locacion antena:',e)
        throw e
      }

    } else if(tipo === 'calibracion'){

      if(!calibracion) throw new Error('No viene la calibracion')
      const gqlop = {
        query: mutations.setCalibracionSensor,
        variables: calibracion
      }
      ll(`qry:${gqlop.query} vars:`,gqlop.variables)
      try {
        const res = await API.graphql(gqlop)
        ll('res grabar calibracion:',res)
        return res
      } catch(e) {
        ee('error al grabar calibracion antena:',e)
        throw e
      }

    }
  }

  const handleClickMap = (latlng) => {
    const { lat, lng } = latlng
    setClickedLatLng({ lat, lng })
  }

  //const formAntena = () => (<FormAntena open={dlgAntenaOpen} idClienteSupplied={idCliente} antena={selectedAntena} handleCloseParent={handleCloseDialog}/>)
  const onCloseFormAntena = async (antena) => {
    if(antena) {
      try{
        await handleEditedAntena({tipo:'antena',antena})
        refreshAntenas()
      }catch(e) {
        throw e
      }
    }
    setEdit('')
  }

  const onCloseFormLocacion = async (antena,locacion) => {
    if(antena) {
      try {
        await handleEditedAntena({tipo:'locacion',antena,locacion})
        refreshAntenas()
      }catch(e) {
        throw e
      }
    }
    setEdit('')
  }

  const onCloseFormCalibracion = async (antena,locacion,calibracion) => {
    if(antena) {
      try{
        await handleEditedAntena({tipo:'calibracion',antena,locacion,calibracion})
        refreshAntenas()
      }catch(e) {
        throw e
      }
    }
    setEdit('')
  }

  const onAgregarAntena = () => {
    setEditAntena({antena:{idCliente}, edit:'antena'})
  }


  return (
    <div className={classes.rootG}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.botonera}>
            <BotonRefrescoAntenas />
            <Button className={classes.botonBotonera} type="button" variant="contained" startIcon={<AddIconTwoTone size='small' />} color="primary" onClick={onAgregarAntena}>Antena</Button>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          { !antenas &&
            <LoadingIcon />
          }
          { antenas && (!editAntena.edit) &&
            <ListaAntenasBase
            antenas={antenas}
            selectedAntenaId={editAntena.antena?.idAntena}
            botones={[BOTON_EDIT_ANTENA, BOTON_EDIT_LOCACION, BOTON_EDIT_CALIBRACION]}
            onClickAntena={onClickAntena}
            onClickBoton={onClickBoton}
            nivelUsuario={nivelUsuario}
            />
          }
          { antenas && editAntena.antena && editAntena.edit === 'antena' &&
            <FormAntena
              antena={editAntena.antena || null}
              onClose={onCloseFormAntena}
            />
          }
          { antenas && editAntena.antena && editAntena.edit === 'locacion' &&
            <FormLocacion
              antena={editAntena.antena}
              onClose={onCloseFormLocacion}
              latlng={clickedLatLng}
            />
          }
          { antenas && editAntena.antena && editAntena.sensor && editAntena.edit === 'calibracion' &&
            <FormCalibracion
              antena={editAntena.antena}
              sensor={editAntena.sensor}
              seccion={editAntena.seccion}
              onClose={onCloseFormCalibracion}
            />
          }
        </Grid>

        <Grid item xs={12} sm={12} md={7}>
          <MapaAntenas
            antenas={marks}
            onClickMap={(editAntena.antena && editAntena.edit === 'locacion') ? handleClickMap : null}
            clickedLatLng={clickedLatLng}
            bounds={mapViewBounds}
          />
        </Grid>

      </Grid>
    </div>
  )

}
