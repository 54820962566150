import React from 'react'
import { API } from 'aws-amplify'
import { getAlertas } from '../graphql/queries'
import BotonRefrescar from './BotonRefrescar'
import { useStore, selUserClientId } from '../store'

export function useDatosAlertas() {
  const [alertas, setAlertas] = React.useState([])
  const [refrescando, setRefrescando] = React.useState(false)
  const idCliente = useStore(selUserClientId)

  const refreshAlertas = React.useCallback(async () => {
    if(!idCliente) return
    setRefrescando(true)
    try {
      const res = await API.graphql({ query: getAlertas, variables: { idCliente } })
      const alertas = res.data.getAlertas
      alertas.forEach(al => {
        al.variablesEstadoActual = JSON.parse(al.variablesEstadoActual)
        al.variablesEstadoReportado = JSON.parse(al.variablesEstadoReportado)
      })
      //console.log('refreshAlertas:',alertas)
      setAlertas(alertas)
    }catch(e){
      //console.error('error refreshAlertas:',e)
      setTimeout(refreshAlertas,1500) //retry
    }finally{
      setRefrescando(false)
    }
  },[idCliente])

  React.useEffect(() => {
    refreshAlertas()
  },[refreshAlertas])

  const BotonRefrescoAlertas = ({caption="refrescar"}) => (
    <BotonRefrescar caption={caption} refrescando={refrescando} doRefresh={refreshAlertas} />
  )


  return { alertas, refreshAlertas, BotonRefrescoAlertas, refrescandoAlertas:refrescando }
}
