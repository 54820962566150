import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { MapContainer, TileLayer, Popup, Marker,  } from 'react-leaflet'
import { makeStyles } from '@material-ui/core/styles'
import { EstadoAntena } from '../enums'
import { getIconoAntena } from './iconosMapa'
import { formatoIntervalo, makeDateTime } from '../utils'
import { useResetTablaCalibraciones, useActivarModoCalibracion, useProcesarLecturas } from './hookCalibracion3';
import { TipoSensor } from '../enums';

const useStyles = makeStyles((theme) => ({
  mapa: {
    height: '500px',
    width: '100%',
    flexGrow: 1,
  },
  twoSimpleColumns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  contentTooltip: {
    minWidth: 200,
  },
  alertas: {
    color: 'red',
    fontWeight: 'bold',
  },
}))

//la antena está alertada si uno de sus sensores tiene una de sus alertas en nivel ALTO
const antenaAlertada = (antena) =>{
  return antena.sensores.find(s => s.alertas.find(al => al.detector.nivelAlerta === 'ALTO') !== undefined) !== undefined
}

const ResumenSensor = ({ antena, sensor, canOnline }) => {
  const classes = useStyles();
  //console.log('ResumenSensor: sensor:',sensor);
  const [ahora, setAhora] = React.useState(new Date());
  const [online,setOnline] = React.useState(false);
  const [lecturaCalib] = useActivarModoCalibracion(online && antena.idPlacaRB, online && sensor.idSensorPlaca, online && sensor.idSensor);
  const calibracion = canOnline && sensor.calibracionActual ? sensor.calibracionActual : null;
  const [lecturaProc] = useProcesarLecturas(lecturaCalib, calibracion);

  const toggleOnline = () => {
    setOnline(prev => !prev)
  }

  React.useEffect(() => {
    const timer = setInterval(() => { setAhora(new Date())}, online ? 1000 : 10000)
    return () => {
      clearInterval(timer)
    }
  },[online]);

  const is = sensor.infoTipoSensor
  is.lectura = (canOnline && online && lecturaProc) ? lecturaProc : sensor.ultimaLectura

  const desde = is.lectura && makeDateTime(is.getValorRaw('fechaLectura'))
  const tiempo = desde ? formatoIntervalo(desde, ahora) : '(sin información)'
  const template = '{{nombreEstado}}: {{v.valorMonitoreado.label}} {{sv.valor}} / Valor minimo: {{p.valorMinimo}}.'
  const als = sensor.alertas.filter(al => al.detector.alertada)
    .map(al => al.detector.getMensajeEstado(null, sensor.infoTipoSensor, template));

  return (
    <>
      <Typography variant="h6" gutterBottom>{sensor.nombreSensor}</Typography>
      { canOnline &&
        <FormControlLabel
        control={<Switch size="small" checked={online} onChange={toggleOnline} />}
        label="Online"
      />}

      { sensor.ultimaLectura &&
        <>
          <Typography key="txtUltimaLectura" variant="caption" component="div" align="center" gutterBottom>{tiempo}</Typography>
          { als.map((al,i) => (
            <Typography key={`al${i}`} className={classes.alertas} variant="caption" component="div" align="center" gutterBottom>{al}</Typography>
          ))}
          {is.mapCamposValor({ soloConsultables:true }, cv => (
            <Grid key={cv.campo} container direction="row" justify="space-between" alignItems="baseline">
              <Typography variant="body2" component="span">{is.getLabel(cv.campo)}</Typography>
              <Typography variant="body2" component="span">{is.getValorConUnidades(cv.campo)}</Typography>
            </Grid>
          ))}
        </>
      }
    </>
  )
}

const ContenidoTooltip = ({ antena, esAdmin }) => {
  const cc = useStyles()
  console.log('ContenidoTooltip:',antena)
  return (
    <div className={cc.contentTooltip}>
      <Typography variant="h5" gutterBottom>{antena.locacionActual ? antena.locacionActual.nombreLocacion : antena.idAntenaPlacaRB }</Typography>
      { antena.sensores.filter(s => !s.visibleSoloAdministradores || esAdmin).map(s => (
        <ResumenSensor key={`rs${s.idSensor}`} sensor={s} antena={antena} canOnline={s.infoTipoSensor.id === TipoSensor.Ultrasonico} />
      ))}
    </div>
  )
}

export default function MapaAntenas({ antenas, esAdmin, mostrarNombres }) {
  const classes = useStyles()
  const [selectedIdAntena, setSelectedIdAntena] = React.useState(0)

  const openPopupAntena = (a) => {
    setSelectedIdAntena(a.idAntena)
  }

  const closePopupAntena = (a) => {
    if(a.idAntena === selectedIdAntena)
      setSelectedIdAntena(0)
  }

  let minLat, maxLat, minLon, maxLon
  let marks = !antenas ? [] : antenas.filter(a => !!a.idLocacionActual)
  marks.forEach((a, i) => {
    if(!minLat || minLat>a.locacionActual.latitud) minLat = a.locacionActual.latitud
    if(!maxLat || maxLat<a.locacionActual.latitud) maxLat = a.locacionActual.latitud
    if(!minLon || minLon>a.locacionActual.longitud) minLon = a.locacionActual.longitud
    if(!maxLon || maxLon<a.locacionActual.longitud) maxLon = a.locacionActual.longitud
  });
  const margen = 5.0/40000.0*360  //5km
  const [defLat, defLon] = [-33.4724224,-70.7702551]  //santiago
  const bounds = !marks.length ? [[defLat-margen,defLon-margen],[defLat+margen,defLon+margen]] : [[minLat-margen,minLon-margen],[maxLat+margen,maxLon+margen]]
  //console.log('bounds:',bounds)
  const prps = {}
  if(marks.length === 1){
    const [{locacionActual:loc}] = marks
    prps.center = [loc.latitud,loc.longitud]
    prps.zoom = 10
  } else {
    prps.bounds = bounds
  }

  //console.log('prps:',prps)
  return (
    <MapContainer  className={classes.mapa} {...prps} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      { marks.map((a,i) => (
          <Marker key={i}
            position={[a.locacionActual.latitud,a.locacionActual.longitud]}
            icon={getIconoAntena(a, a.idAntena === selectedIdAntena, antenaAlertada(a), mostrarNombres && a.locacionActual.nombreLocacion)}
            zIndexOffset={(a.idEstadoAntena === EstadoAntena.FALLA && 500) || (a.idEstadoAntena === EstadoAntena.PROBLEMAS && 400) || (a.idEstadoAntena === EstadoAntena.ACTIVA && 300) || 0}
          >
            <Popup onOpen={() => openPopupAntena(a)} onClose={() => closePopupAntena(a)}><ContenidoTooltip antena={a} esAdmin={esAdmin}/></Popup>
          </Marker>)
      )}
    </MapContainer>
  )
}
