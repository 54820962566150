
import { formatoTS } from './utils'

/**
 * Clase para manejar las lecturas de un sensor, considerando los campos y su formato
 */

export class TipoSensorInfo {
  constructor(info, lectura){
    this._sensorInfo = null
    this._calibracion = null
    this._lectura = null
    //assign using the setters
    this.sensorInfo = info instanceof TipoSensorInfo ? info._sensorInfo : info
    this.lectura = lectura || null
  }

  get id(){
    return this._sensorInfo ? this._sensorInfo.id : null
  }

  get seccionesCalibracion() {
    return this._sensorInfo?.seccionesCalibracion
  }

  set calibracion(cal) {
    this._calibracion = cal
    this._parsedCal5 = {}
    if(cal.valorCalibracion5){
      this._parsedCal5 = JSON.parse(cal.valorCalibracion5)
      if(typeof(this._parsedCal5) !== 'object')
        this._parsedCal5 = {}
    }
  }

  getValorCalibracion(campo){
    if(!this._sensorInfo || !this._calibracion)
      return null;
    if(campo in this._calibracion)
      return this._calibracion[campo];
    if(campo in this._parsedCal5)
      return this._parsedCal5[campo];
    return null
  }

  getValoresCalibracion() {
    const valores = {}
    this.camposCalibracion.forEach((cc, i) => {
      valores[cc.campo] = this.getValorCalibracion(cc.campo) || ''
    });
    valores.idTipoSensor = this.id
    return valores
  }

  buildCalibracion(vals){
    if(!this._sensorInfo) return null
    const valores = vals || {}
    const cal = {
      idTipoSensor: this.id,
      valorCalibracion1: 0,
      valorCalibracion2: null,
      valorCalibracion3: null,
      valorCalibracion4: null,
      valorCalibracion5: null,
      notas: null,
    }
    this.camposCalibracion.forEach((cc, i) => {
      if(cc.campo in cal){
        cal[cc.campo] = valores[cc.campo]
      } else {
        if(cal.valorCalibracion5){
          cal.valorCalibracion5[cc.campo] = valores[cc.campo]
        } else {
          cal.valorCalibracion5 = { [cc.campo]: valores[cc.campo] }
        }
      }
    });

    if(cal.valorCalibracion5)
      cal.valorCalibracion5 = JSON.stringify(cal.valorCalibracion5);

    return cal;
  }

  set sensorInfo(sensorInfo){
    this._sensorInfo = sensorInfo
    this.valoresMap = {}
    this._sensorInfo.valoresObtenidos.forEach(vo => {
      this.valoresMap[vo.campo] = vo
    });

  }

  get lectura() {
    return this._lectura
  }

  set lectura(lectura) {
    this._lectura = lectura
    if(lectura){
      try{
        this.parsedValoresAdicionales = this._lectura.valorCalculadoAdicional ? JSON.parse(this._lectura.valorCalculadoAdicional) : {}
      }catch(e){
        this.parsedValoresAdicionales = {}
      }
      try{
        this.parsedDatosAdicionales = this._lectura.datosAdicionales ? JSON.parse(this._lectura.datosAdicionales) : {}
      }catch(e){
        this.datosAdicionales = {}
      }
    } else {
      this.parsedValoresAdicionales = null
      this.parsedDatosAdicionales = null
    }
  }

  getValorRaw(campo){
    if(!this._lectura)
      return null
    if(campo in this._lectura)
      return this._lectura[campo]
    if(campo in this.parsedValoresAdicionales)
      return this.parsedValoresAdicionales[campo]
    if(campo in this.parsedDatosAdicionales)
      return this.parsedDatosAdicionales[campo]
    return null
  }

  getValor(campo) {
    if(!this._lectura) return null

    const valorRaw = this.getValorRaw(campo)
    if(valorRaw && (campo === 'fechaLectura' || campo === 'fechaCreacion'))
      return formatoTS(valorRaw)

    if(this.valoresMap[campo].formato){
      const {
        locale='es-CL',
        decimales=0,
        style='decimal',
      } = this.valoresMap[campo].formato
      const fmt = new Intl.NumberFormat(locale, {
        minimumFractionDigits: decimales,
        maximumFractionDigits: decimales,
        style,
      })
      return fmt.format(valorRaw)
    } else {
      return valorRaw
    }
  }

  getValorConUnidades(campo){
    if(!this._lectura) return null
    if(this.valoresMap[campo].unidad){
      return this.getValor(campo)+' '+this.valoresMap[campo].unidad
    }else{
      return this.getValor(campo)
    }
  }

  getLabel(campo){
    return this.valoresMap[campo].label
  }

  getLabelConUnidades(campo){
    if(this.valoresMap[campo].unidad){
      return `${this.valoresMap[campo].label} [${this.valoresMap[campo].unidad}]`
    }else{
      return this.valoresMap[campo].label
    }
  }

  get camposCalibracion(){
    return this._sensorInfo.camposCalibracion
  }

  getCamposValor({ soloConsultables=true, soloCalibracion=false}){
    return this._sensorInfo.valoresObtenidos
      .filter(vo => (!soloConsultables || vo.consultable) && (!soloCalibracion || vo.mostrarEnCalibracion))

  }

  mapCamposValor({ soloConsultables=true, soloCalibracion=false },fn){
    return this.getCamposValor({soloConsultables,soloCalibracion})
      .map(fn)
  }

  forEachCampoValor({ soloConsultables=true, soloCalibracion=false },fn){
    return this.getCamposValor({soloConsultables,soloCalibracion})
      .forEach(fn)
  }

}

export function makeSensorInfo(info,lectura){
  return new TipoSensorInfo(info,lectura)
}
