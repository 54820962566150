import create from 'zustand'
import { API } from 'aws-amplify'
import { getInfoTiposSensores, getInfoTiposDetectorAlerta, getValoresEnum } from './graphql/queries'
import { combineGrapqlQueries } from './utils'
import { ModeloAntena } from './enums'

const parseValoresEnum = (json) => {
  const obj = JSON.parse(json)
  const res = []
  for(let maid in obj){
    res.push({...obj[maid], id:maid})
  }
  return res
}

export const useStore = create((set,get) => ({
  infoSensores: [],
  infoDetectores: [],
  modelosAntenas: [],
  infoLoaded: false,
  datosFormConsulta: null,
  user: {},

  loadInfo: async () => {
    if(!get().user.attributes){
      return
    }
    const qry = combineGrapqlQueries(getValoresEnum, combineGrapqlQueries(getInfoTiposSensores, getInfoTiposDetectorAlerta))
    const res = await API.graphql({query: qry, variables:{ tipo: ModeloAntena.TIPO }})
    set(state => ({
      infoSensores: res.data.getInfoTiposSensores,
      infoDetectores: res.data.getInfoTiposDetectorAlerta,
      modelosAntenas: parseValoresEnum(res.data.getValoresEnum),
      infoLoaded: true,
    }))
  },

  setDatosFormConsulta: (datos) => set(state => ({
    datosFormConsulta: datos
  })),

  setUser: (newUser) => set(state => ({
    user: newUser
  })),

}));

export const selInfoSensores = state => state.infoSensores
export const selInfoDetectores = state => state.infoDetectores
export const selModelosAntenas = state => state.modelosAntenas

export const selDatosFormConsulta = state => state.datosFormConsulta
export const selSetDatosFormConsulta = state => state.setDatosFormConsulta

export const selUser = state => state.user
export const selUserEmail = state => state.user.attributes?.email
export const selUserClientId = state => state.user.attributes && state.user.attributes['custom:idCliente']
export const selUserNivel = state => (state.user.attributes && state.user.attributes['custom:nivel']) || 0
export const selUserPerfil = state => state.user.signInUserSession && state.user.signInUserSession.accessToken.payload["cognito:groups"][0]
export const selUserPoolId = state => state.user.pool && state.user.pool.userPoolId
export const selUserIsROOT = state => selUserPerfil(state) === "ROOT"
export const selUserIsAdmin = state => selUserIsROOT(state) || selUserPerfil(state) === "Administradores"
