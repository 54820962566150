import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardActions, CardHeader, Typography  } from '@material-ui/core';
import { Button, TextField, FormGroup } from '@material-ui/core';
import LoadingIcon from './loading';
import { TipoSensor } from '../enums';
import { makeLoggers } from '../utils';
import Progress from './CircularProgressWithLabel';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { formatoIntervalo } from '../utils';
import TablaDatos from './TablaDatos';

import {
  useProcesarLecturas,
  useResetTablaCalibraciones,
  useActivarModoCalibracion,
  useListaCalibraciones,
} from './hookCalibracion3';

const {ll,ee} = makeLoggers('FC2','log')

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  subtitulo: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: '1em',
    marginBottom: '1em',
  },
  root_calcs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  calc: {
    width: 250,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.spacing(1),
  },
  calcPrim: {
    backgroundColor: theme.palette.info.light
  },
  calcSec: {
    backgroundColor: theme.palette.success.light
  }
}));


/******************************************************************************/
export default function FormCalibracion({ antena, sensor, seccion, onClose }) {

  ll.enabled = true; ee.enabled = true;

  if(sensor.infoTipoSensor.id === TipoSensor.Ultrasonico){
    if(seccion === 'sensor'){
      return <SeccionUltrasonicoSensor antena={antena} sensor={sensor} onClose={onClose} />
    }
    if(seccion === 'caudal'){
      return <SeccionUltrasonicoCaudal antena={antena} sensor={sensor} onClose={onClose} />
    }
    return null
  }
  return null
}

/**
 * SENSOR ULTRASONICO - SECCION CALIBRACION DEL SENSOR
 * Wizard de 3 páginas:
 *  1) Activa modo calibración, recibe 5 lecturas de calibracion y guardar el promedio del valor leido
 *  2) El usuario ingresa el valor de la lectura de referencia
 *  3) Activa modo calibracion y procesa las lecturas con los valores de 1 y 2. Si el usuario acepta se graba.
 */

const BOTONES_PAGINA_USENSOR = [
  {},//PAGINAS PARTEN DE 1
  {
    next: { disabled: true, },
    prev: { disabled: true, },
    cancel: { disabled: false, },
  },
  {
    next: { disabled: false, },
    prev: { disabled: false, },
    cancel: { disabled: false, },
  },
  {
    next: { disabled: false, label:'Grabar' },
    prev: { disabled: false, },
    cancel: { disabled: false, },
  },
]


function SeccionUltrasonicoSensor({ antena, sensor, onClose }){
  const botones = {...BOTONES_PAGINA_USENSOR[1]}
  const [wiz, dispatchWiz] = React.useReducer(reducerWizardUSensor, {antena,sensor,seccion:'sensor',botones,onClose}, initWizardSeccion)

  if(wiz.paginaActual === 1) {
    return (
      <PaginaSeccion indice={wiz.paginaActual} botones={wiz.botones} dispatch={dispatchWiz} subtitulo={'Recibiendo datos de calibración'}>
        <PaginaUSensor1 antena={antena} sensor={sensor} dispatch={dispatchWiz} />
      </PaginaSeccion>
    )
  } else if(wiz.paginaActual === 2) {
    return (
      <PaginaUSensor2 antena={antena} sensor={sensor} dispatch={dispatchWiz} datos={wiz} indice={wiz.paginaActual} />
    )
  } else if(wiz.paginaActual === 3) {
    return (
      <PaginaSeccion indice={wiz.paginaActual} botones={wiz.botones} dispatch={dispatchWiz}>
        <PaginaUSensor3 antena={antena} sensor={sensor} dispatch={dispatchWiz} datos={wiz} />
      </PaginaSeccion>
    )
  }
}


const BOTONES_DEFAULT = {
  next: { disabled: false, },
  prev: { disabled: true, },
  cancel: { disabled: false, },
};

const initWizardSeccion = ({antena,sensor,seccion,botones,onClose}) => {
  const wiz = {
    antena,
    sensor,
    paginaActual: 1,
    botones: {...BOTONES_DEFAULT, ...botones},
    onClose,
    camposCalibracion: null,  //los campos de calibracion (cc) pertenecientes a la seccion a editar
    datosCalibracion: {},   //los los valores actuales de los cc de la seccion
    datosAdicionales: {},   //los valores del resto de los cc
  };
  const is = sensor.infoTipoSensor
  let valores = {}
  if(sensor.idCalibracionSensor) {//si ya tiene una calibracion saco los valores iniciales de ahi
    is.calibracion = sensor.calibracionActual
    valores = is.getValoresCalibracion();
  } else {//si no tiene una calibracion inicial, le pongo todo en ''
    is.camposCalibracion.forEach((cc, i) => {
      valores[cc.campo] = '';
    });
  }
  //armar la lista de los campos de la seccion seleccionada. Si no se pasa una seccion se consideran todos los cc
  let camposSeccion = is.camposCalibracion;
  if(seccion) {
    const sec = is.seccionesCalibracion ? is.seccionesCalibracion.find(sc => sc.nombre === seccion) : null;
    camposSeccion = sec ? sec.campos.map(cs => is.camposCalibracion.find(cc => cc.campo === cs)) : []
  }
  wiz.camposCalibracion = camposSeccion
  camposSeccion.forEach((cs, i) => {//inicializo los valores de los cc de la seccion
    wiz.datosCalibracion[cs.campo] = valores[cs.campo]
  });
  is.camposCalibracion.forEach((cc, i) => {//inicializar tambien los valores del resto de los cc
    if(!camposSeccion.find(cs => cs.campo === cc.campo))
      wiz.datosAdicionales[cc.campo] = valores[cc.campo]
  });

  return wiz;
}

//reducers
const handleNext = (wiz,action) => {
  if(action.pagina === 1 || action.pagina === 2){
    return {
      ...wiz,
      paginaActual: wiz.paginaActual+1,
      datosCalibracion: {...wiz.datosCalibracion, ...action.datos},
      botones: {...BOTONES_PAGINA_USENSOR[wiz.paginaActual+1]},
    }
  } else if(action.pagina === 3) {
    const cal = getCalibracionActual(wiz.sensor,wiz)
    ll('Grabar calibracion: cal:%o',cal);
    const calibracion = {
      idSensor: wiz.sensor.idSensor,
      idLocacion: wiz.antena.idLocacionActual,
      notas: cal.notas || wiz.notas,
      ...cal
    }
    ll('Grabar calibracion: calibracion:%o',calibracion);
    wiz.onClose(wiz.antena, wiz.antena.locacionActual, calibracion);
  }
  return wiz
}

const handlePrev = (wiz,action) => {
  if(wiz.paginaActual > 1){
    return {
      ...wiz,
      paginaActual: wiz.paginaActual-1,
      botones: {...BOTONES_PAGINA_USENSOR[wiz.paginaActual-1]}
    }
  }
}

const reducerWizardUSensor = (wiz,action) => {
  ll('reducerWizUSens: W:%o a:%o',wiz,action)
  switch(action.tipo){

    case 'next':
      return handleNext(wiz,action);

    case 'cancel':
      setTimeout(wiz.onClose,1);
      return wiz;

    case 'prev':
      return handlePrev(wiz,action);

    default: return wiz //do nothing
  }
}



function PaginaSeccion({indice,botones,dispatch,titulo,subtitulo,formSetup,children}){
  const classes = useStyles()
  const [initialValues,validationSchema,] = formSetup ? formSetup() : [{_tipo:''},null]
  const formSubmit = datos => {
    const formDatos = {...datos}
    delete formDatos._tipo
    dispatch({tipo:datos._tipo, pagina:indice, datos:formDatos})
  }

  ll('PagSeccion: i:%d b:%o',indice,botones)
  return (
    <Card className={classes.root}>
      <CardHeader id="form-dialog-title" title={titulo || "Calibración"} subheader={subtitulo || `Pag. ${indice}`}/>
      <Formik initialValues={initialValues} onSubmit={formSubmit} validationSchema={validationSchema}>
        {(formik) => (
          <>
          <input type="hidden" id="_tipo" name="_tipo" onChange={formik.handleChange} value={formik.values._tipo} />
          <CardContent>
          {children}
          </CardContent>
          <CardActions>
            <BotonWizard disabled={botones.prev.disabled} tipo={'prev'} color="secondary" dispatch={dispatch}>{'< Anterior'}</BotonWizard>
            <BotonWizardNext disabled={botones.next.disabled} tipo={'next'} color="primary">{botones.next.label || 'Siguiente >'}</BotonWizardNext>
            <BotonWizard disabled={botones.cancel.disabled} tipo={'cancel'} color="secondary" dispatch={dispatch}>Cancelar</BotonWizard>
          </CardActions>
          </>
        )}
      </Formik>
    </Card>
  )
}

const BotonWizardNext = ({tipo,disabled,color,children}) => {
  const { submitForm, setFieldValue } = useFormikContext()
  const botonSubmitClick = () => {
    //ll('botonSubmitClick: v:',values)
    setFieldValue('_tipo',tipo);
    submitForm()
  }
  return (
    <Button variant="contained" size="small" type="button" color={color} disabled={disabled} onClick={botonSubmitClick}>{children}</Button>
  )
}

const BotonWizard = ({tipo,disabled,color,children,dispatch}) => {
  const botonSubmitClick = () => {
    dispatch({tipo})  //se salta la validación del formulario se usa para prev y cancel, no lleva más parámetros.
  }
  return (
    <Button variant="contained" size="small" type="button" color={color} disabled={disabled} onClick={botonSubmitClick}>{children}</Button>
  )
}


/******************************************************************************/
function PaginaUSensor1({ antena, sensor, dispatch }){
  //const classes = useStyles();
  useResetTablaCalibraciones(antena.idPlacaRB, sensor.idSensorPlaca);
  const [ultimaLectura] = useActivarModoCalibracion(antena.idPlacaRB, sensor.idSensorPlaca, sensor.idSensor);
  const lecturas = useListaCalibraciones(ultimaLectura);
  const [avance,setAvance] = React.useState(0)

  React.useEffect(() => {
    if(!lecturas || lecturas.length === 0) return;
    let newDatos = null
    let ncampos=0, doneCampos=0
    sensor.infoTipoSensor.camposCalibracion.filter(cc => cc.tipoFuente === 'lectura').forEach((cc, i) => {
      ncampos++
      const fuente = cc.fuente || 'valorLeido'
      if(!cc.modificador){//valor directo de la última lectura
        newDatos[cc.campo] = lecturas[lecturas.length-1][fuente]
        doneCampos++
      } else {
        const [tipo,preNlecturas] = cc.modificador.split('-')
        const MIN_LECTURAS = 5
        const nlecturas = preNlecturas < MIN_LECTURAS ? MIN_LECTURAS : preNlecturas
        if(lecturas.length < nlecturas) {
          setAvance(Number(lecturas.length/nlecturas*100).toFixed(0))
          return; //se salta el campo
        }
        setAvance(100);
        const lects = lecturas.slice(0,nlecturas)
        let tmp, val
        switch(tipo){
          case 'prom':
          case 'promedio':
            tmp = 0;
            lects.forEach((lec, i) => {
              tmp += lec[fuente]
            });
            val = Math.floor(tmp / lects.length,1)
            break;
          case 'min':
          case 'minimo':
            tmp = lects[0];
            lects.forEach((lec, i) => {
              if(lec[fuente] < tmp) tmp = lec[fuente]
            });
            val = Math.floor(tmp,1)
            break;
          case 'max':
          case 'maximo':
            tmp = lects[0];
            lects.forEach((lec, i) => {
              if(lec[fuente] > tmp) tmp = lec[fuente]
            });
            val = Math.floor(tmp,1)
            break;
          default:
            val = null
            break;
        }
        if(val !== null && !Number.isNaN(val)){
          if(!newDatos){
            newDatos = {
              [cc.campo]: val
            }
          } else {
            newDatos[cc.campo] = val
          }
          doneCampos++;
        }
      }
    });
    ll('PaginaUSensor1.useEffect:cv=%o campos:%d/%d',newDatos,doneCampos,ncampos);
    if(doneCampos === ncampos){
      dispatch({tipo:'next',pagina:1,datos:newDatos})
    }
  },[sensor.infoTipoSensor.camposCalibracion, lecturas, dispatch]);

  return (
    <div>
      <Progress size={100} value={avance || 'iniciando'} />
    </div>
  )
}

function PaginaUSensor2({ antena, sensor, dispatch, datos }){
  //const classes = useStyles();

  const initialValues = { ...datos.datosCalibracion }
  initialValues.notas = sensor.calibracionActual?.notas || ''

  const calibracionValidationSchema = {
    notas: Yup.string().max(500),
  }
  datos.camposCalibracion.filter(cc => (cc.tipoFuente || 'usuario') === 'usuario').forEach((cc, i) => {
    calibracionValidationSchema[cc.campo] = cc.tipo === 'float8' ? Yup.number() : Yup.string()
    if(cc.requerido) calibracionValidationSchema[cc.campo] = calibracionValidationSchema[cc.campo].required('Valor requerido')
    if(cc.min) calibracionValidationSchema[cc.campo] = calibracionValidationSchema[cc.campo].min(cc.min, `debe ser mayor que ${cc.min}`)
    if(cc.max) calibracionValidationSchema[cc.campo] = calibracionValidationSchema[cc.campo].max(cc.max, `debe ser menor que ${cc.max}`)
  });
  const validationSchema = Yup.object(calibracionValidationSchema);

  const formSetup = () => ([initialValues,validationSchema])

  return (
    <PaginaSeccion indice={2} botones={BOTONES_PAGINA_USENSOR[2]} dispatch={dispatch} formSetup={formSetup} >
    { datos.camposCalibracion.filter(cc => (cc.tipoFuente || 'usuario') === 'usuario').map( (cc,i) => (
      <CampoCalibracion key={i} cc={cc} />
    ))}
      <CampoCalibracion key="fila9" cc={{campo:'notas',max:500,label:'Notas'}} />
  </PaginaSeccion>
  )
}

function CampoCalibracion({cc}){
  const formik = useFormikContext()

  return (
    <FormGroup row>
      <TextField
        margin="dense"
        id={cc.campo}
        name={cc.campo}
        required={cc.requerido}
        label={cc.label}
        type={cc.tipo === 'float8' ? 'number' : 'text' }
        maxLength={cc.tipo === 'float8' ? 10 : cc.max || 100 }
        value={formik.values[cc.campo]}
        onChange={formik.handleChange}
        error={formik.touched[cc.campo] && Boolean(formik.errors[cc.campo])}
        helperText={formik.touched[cc.campo] ? (formik.errors[cc.campo] || cc.ayuda) : cc.ayuda}
      />
    </FormGroup>
  )
}

const getCalibracionActual = (sensor,wiz) => {
  let valores = {}
  const is = sensor.infoTipoSensor
  if(sensor.idCalibracionSensor) {//si ya tiene una calibracion saco los valores iniciales de ahi
    is.calibracion = sensor.calibracionActual
    valores = is.getValoresCalibracion();
  } else {//si no tiene una calibracion inicial, le pongo todo en ''
    is.camposCalibracion.forEach((cc, i) => {
      valores[cc.campo] = '';
    });
  }
  const valoresCalibracion = { ...valores, ...wiz.datosAdicionales, ...wiz.datosCalibracion }
  const calibracion = is.buildCalibracion(valoresCalibracion)
  return calibracion
}

function PaginaUSensor3({ antena, sensor, dispatch, datos }){
  //const classes = useStyles();
  useResetTablaCalibraciones(antena.idPlacaRB, sensor.idSensorPlaca);
  const [ultimaLectura] = useActivarModoCalibracion(antena.idPlacaRB, sensor.idSensorPlaca, sensor.idSensor);
  const lecturas = useListaCalibraciones(ultimaLectura,5);
  const calibracionActual = React.useMemo(()=>getCalibracionActual(sensor,datos),[sensor,datos])
  const [lecturasProcesadas] = useProcesarLecturas(lecturas,calibracionActual)

  return (
    <TablaLecturasCalibracion infoSensor={sensor.infoTipoSensor} lecturas={lecturasProcesadas} />
  )
}

function TablaLecturasCalibracion({infoSensor,lecturas}){
  const classes = useStyles()
  //ll('TablaLecturas: l.l:',lecturas?.length)
  const si = infoSensor
  const cols = [
    {
      title: 'f/h',
      align: 'center',
      field: 'fechaLectura',
    },
    ...si.mapCamposValor({soloConsultables:false, soloCalibracion:true}, cv => {
      return {
        title: si.getLabelConUnidades(cv.campo),
        align: 'center',
        field: cv.campo,
    }})
  ];
  const datos = !lecturas ? [] : lecturas.map(l => {
    const rec = {
      fechaLectura: formatoIntervalo(l.fechaLectura),
    }
    si.lectura = l
    si.forEachCampoValor({soloConsultables:false, soloCalibracion:true}, cv => {
      rec[cv.campo] = si.getValor(cv.campo)
    })
    return rec
  });

  return <>
    <Typography className={classes.subtitulo}>Lecturas de calibración</Typography>
    <TablaDatos columnas={cols} datos={datos} />
  </>
}

/******************************************************************************/
const BOTONES_PAGINA_UCAUDAL = [
  {},//PAGINAS PARTEN DE 1
  {
    next: { disabled: false, },
    prev: { disabled: true, },
    cancel: { disabled: false, },
  },
  {
    next: { disabled: false, label:'Grabar' },
    prev: { disabled: false, },
    cancel: { disabled: false, },
  },
]

function SeccionUltrasonicoCaudal({antena, sensor, onClose}){
  const botones = {...BOTONES_PAGINA_UCAUDAL[1]}
  const [wiz, dispatchWiz] = React.useReducer(reducerWizardUCaudal, {antena,sensor,seccion:'caudal',botones,onClose}, initWizardSeccion)

  if(wiz.paginaActual === 1) {
    return (
      <PaginaUCaudal1 antena={antena} sensor={sensor} dispatch={dispatchWiz} datos={wiz} indice={wiz.paginaActual} />
    )
  } else if(wiz.paginaActual === 2) {
    return (
      <PaginaSeccion indice={wiz.paginaActual} botones={wiz.botones} dispatch={dispatchWiz}>
        <PaginaUCaudal2 antena={antena} sensor={sensor} dispatch={dispatchWiz} datos={wiz} />
      </PaginaSeccion>
    )
  }
}

//reducers
const handleNextCaudal = (wiz,action) => {
  if(action.pagina === 1){
    return {
      ...wiz,
      paginaActual: wiz.paginaActual+1,
      datosCalibracion: {...wiz.datosCalibracion, ...action.datos},
      botones: {...BOTONES_PAGINA_UCAUDAL[wiz.paginaActual+1]},
    }
  } else if(action.pagina === 2) {
    const cal = getCalibracionActual(wiz.sensor,wiz)
    ll('Grabar calibracion: cal:%o',cal);
    const calibracion = {
      idSensor: wiz.sensor.idSensor,
      idLocacion: wiz.antena.idLocacionActual,
      notas: cal.notas || wiz.notas,
      ...cal
    }
    ll('Grabar calibracion: calibracion:%o',calibracion);
    wiz.onClose(wiz.antena, wiz.antena.locacionActual, calibracion);
  }
  return wiz
}

const handlePrevCaudal = (wiz,action) => {
  if(wiz.paginaActual > 1){
    return {
      ...wiz,
      paginaActual: wiz.paginaActual-1,
      botones: {...BOTONES_PAGINA_UCAUDAL[wiz.paginaActual-1]}
    }
  }
}

const reducerWizardUCaudal = (wiz,action) => {
  ll('reducerWizUCaudal: W:%o a:%o',wiz,action)
  switch(action.tipo){

    case 'next':
      return handleNextCaudal(wiz,action);

    case 'cancel':
      setTimeout(wiz.onClose,1);
      return wiz;

    case 'prev':
      return handlePrevCaudal(wiz,action);

    default: return wiz //do nothing
  }
}

function PaginaUCaudal1({ antena, sensor, dispatch, datos }){
  //const classes = useStyles();

  const initialValues = { ...datos.datosCalibracion }
  initialValues.notas = sensor.calibracionActual?.notas || ''

  const calibracionValidationSchema = {
    notas: Yup.string().max(500),
  }
  datos.camposCalibracion.filter(cc => (cc.tipoFuente || 'usuario') === 'usuario').forEach((cc, i) => {
    calibracionValidationSchema[cc.campo] = cc.tipo === 'float8' ? Yup.number() : Yup.string()
    if(cc.requerido) calibracionValidationSchema[cc.campo] = calibracionValidationSchema[cc.campo].required('Valor requerido')
    if(cc.min) calibracionValidationSchema[cc.campo] = calibracionValidationSchema[cc.campo].min(cc.min, `debe ser mayor que ${cc.min}`)
    if(cc.max) calibracionValidationSchema[cc.campo] = calibracionValidationSchema[cc.campo].max(cc.max, `debe ser menor que ${cc.max}`)
  });
  const validationSchema = Yup.object(calibracionValidationSchema);

  const formSetup = () => ([initialValues,validationSchema])

  return (
    <PaginaSeccion indice={1} botones={BOTONES_PAGINA_UCAUDAL[1]} dispatch={dispatch} formSetup={formSetup} >
    { datos.camposCalibracion.filter(cc => (cc.tipoFuente || 'usuario') === 'usuario').map( (cc,i) => (
      <CampoCalibracion key={i} cc={cc} />
    ))}
      <CampoCalibracion key="fila9" cc={{campo:'notas',max:500,label:'Notas'}} />
  </PaginaSeccion>
  )
}

function PaginaUCaudal2({ antena, sensor, dispatch, datos }){
  //const classes = useStyles();
  useResetTablaCalibraciones(antena.idPlacaRB, sensor.idSensorPlaca);
  const [ultimaLectura] = useActivarModoCalibracion(antena.idPlacaRB, sensor.idSensorPlaca, sensor.idSensor);
  const lecturas = useListaCalibraciones(ultimaLectura,5);
  const calibracionActual = React.useMemo(()=>getCalibracionActual(sensor,datos),[sensor,datos])
  const [lecturasProcesadas] = useProcesarLecturas(lecturas,calibracionActual)

  return (
    <TablaLecturasCalibracion infoSensor={sensor.infoTipoSensor} lecturas={lecturasProcesadas} />
  )
}
