import { useEffect } from 'react'
import { MapContainer, TileLayer, useMap, Marker } from 'react-leaflet'
import { makeStyles } from '@material-ui/core/styles'
import { getIconoAntena } from './iconosMapa'
import { useMapStateStore, selAntena, selSetAntena, selEdit } from '../mapStateStore'

const useStyles = makeStyles((theme) => ({
  mapa: {
    minHeight: '400px',
    flexGrow: 1
  },
}))

/**
 * Ajuste de la vista al seleccionar una antena y cuando se cargan
 */

const margen = 5.0/40000.0*360  //5km

const MapController = () => {
  const map = useMap()

  useEffect(() => {
    useMapStateStore.subscribe((ant,oldAnt) => {
      if(ant?.idAntena !== oldAnt?.idAntena){
        const [defLat, defLon] = ant?.locacionActual ? [ant.locacionActual.latitud,ant.locacionActual.longitud] : [-33.4724224,-70.7702551]  //santiago
        const bounds = [[defLat-margen,defLon-margen],[defLat+margen,defLon+margen]]
        map.fitBounds(bounds)
      }
    }, selAntena)
  },[map])
  // const { center, centerLat, centerLon, bounds, antenas, selectedAntenaId } = props
  // if(antenas && selectedAntenaId){
  //   const ant = antenas.find(a => a.idAntena == selectedAntenaId) //eslint-disable-line eqeqeq
  //   if(ant && ant.locacionActual) map.setView([ant.locacionActual.latitud,ant.locacionActual.longitud])
  // } else if(centerLat && centerLon){
  //   map.setView([centerLat, centerLon])
  // } else if(center){
  //   map.setView(center)
  // } else if(bounds){
  //   map.setView([(bounds[0][0]+bounds[1][0])/2,(bounds[0][1]+bounds[1][1])/2])
  // }

  return null
}

/**
 * Registra el event handler del click sobre el mapa
 */
const MapClick = ({onClickMap}) => {
  const map = useMap()

  useEffect(() => {
    map.on('click', (event) => {
      const { latlng } = event
      if(onClickMap) onClickMap(latlng)
    })
  },[map,onClickMap])

  return null
}


export default function MapaAntenas(props) {
  const { antenas, onClickMap, clickedLatLng, bounds, ...otherProps } = props
  const classes = useStyles()
  const markedAntena = useMapStateStore(selAntena)
  const setAntena = useMapStateStore(selSetAntena)
  const editForm = useMapStateStore(selEdit)

  // let minLat, maxLat, minLon, maxLon
  // console.log('Mapa: props:',props)
  // let marks = !antenas ? [] : antenas.filter(a => !!a.idLocacionActual)
  // console.log('marks antenas: ', marks)
  // marks.forEach((a, i) => {
  //   if(!minLat || minLat>a.locacionActual.latitud) minLat = a.locacionActual.latitud
  //   if(!maxLat || maxLat<a.locacionActual.latitud) maxLat = a.locacionActual.latitud
  //   if(!minLon || minLon>a.locacionActual.longitud) minLon = a.locacionActual.longitud
  //   if(!maxLon || maxLon<a.locacionActual.longitud) maxLon = a.locacionActual.longitud
  // });
  // const margen = 5.0/40000.0*360  //5km
  // const [defLat, defLon] = marks.length ? [marks[0].locacionActual.latitud,marks[0].locacionActual.longitud] : [-33.4724224,-70.7702551]  //santiago
  // const bounds = !marks.length ? [[defLat-margen,defLon-margen],[defLat+margen,defLon+margen]] : [[minLat-margen,minLon-margen],[maxLat+margen,maxLon+margen]]
  //console.log('bounds:',bounds)
  // const prps = {}
  // if(marks.length === 1){
  //   const [{locacionActual:loc}] = marks
  //   prps.center = [loc.latitud,loc.longitud]
  //   prps.zoom = 10
  // } else {
  //   prps.bounds = bounds
  // }

  //console.log('MAPA prps:',{...prps,...otherProps})
  return (
    <MapContainer className={classes.mapa} {...otherProps} bounds={bounds} onClickMap={onClickMap} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      { antenas.map((a,i) => (
          <Marker key={`marker${i}`}
            position={[a.locacionActual.latitud,a.locacionActual.longitud]}
            icon={getIconoAntena(a,
               markedAntena && a.idAntena === markedAntena.idAntena,
               false,
               markedAntena && a.idAntena === markedAntena.idAntena && a.locacionActual.nombreLocacion
             )}
            eventHandlers={{
              click: () => {
                if(setAntena && !editForm) setAntena(a)
              },
            }}
            >
          </Marker>)
      )}
      { onClickMap && clickedLatLng && clickedLatLng.lat && clickedLatLng.lng && (
        <Marker key="clickedMarker"
          position={clickedLatLng}
          icon={getIconoAntena({}, false, false, '', true)}
        >
        </Marker>
      )}

      {
        <MapController bounds={bounds} />
      }

      <MapClick onClickMap={onClickMap} />
    </MapContainer>
  )
}
