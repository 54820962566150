import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DialogCerrarSesion({open,handleCerrarSesion,handleCancelar}){
  return <Dialog
    open={open}
    onClose={handleCancelar}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">Cerrar sesión</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        ¿Está segur@ que desea cerrar la sesión?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCerrarSesion} color="warning">
          Cerrar sesión
        </Button>
        <Button onClick={handleCancelar} color="default" autoFocus>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
}
