import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormControl, InputLabel, Select, MenuItem, FormHelperText, } from '@material-ui/core'
import { useFormikContext } from 'formik'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));



export const SelectAccionable = ({formik, campo, label, valores, changeCB, ...otherProps}) => {
  const classes = useStyles()
  const { values, handleChange } = formik

  const onMyChange = (event) => {
    console.log('OnMyChange:',event.target.value)
    if(changeCB) changeCB(event.target.value, formik)
    handleChange(event)
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${campo}Label`}>{label}</InputLabel>
      <Select
        {...otherProps}
        margin="dense"
        labelId={`${campo}Label`}
        id={campo}
        name={campo}
        value={values[campo]}
        onChange={onMyChange}
        error={Boolean(formik.errors[campo])}
      >
      { valores.map((v,i) => (
        <MenuItem key={i} value={v.valor}>
        <em>{v.texto}</em>
        </MenuItem>
      ))}
      </Select>
      <FormHelperText>{formik.errors[campo]}</FormHelperText>
    </FormControl>
  )
}

export const BotonSubmit = ({caption, disabled}) => {
  const { values, submitForm } = useFormikContext()

  const botonSubmitClick = () => {
    console.log('botonSubmitClick: v:',values)
    submitForm()
  }
  return (
    <Button type="button" onClick={botonSubmitClick} disabled={disabled} color="primary">{caption || 'Guardar'}</Button>
  )
}
