import {
  IoTDataPlaneClient, PublishCommand
} from "@aws-sdk/client-iot-data-plane";
import {
  CognitoIdentityClient,
} from "@aws-sdk/client-cognito-identity";
import {
  fromCognitoIdentityPool,
} from "@aws-sdk/credential-provider-cognito-identity";
import {
  SecretsManagerClient, GetSecretValueCommand
} from "@aws-sdk/client-secrets-manager";
import { Auth } from 'aws-amplify';

const ADMIN_CREDS_NAME = 'EMIGO_Admin_Creds';
const IDENTITY_POOL_ID = 'us-east-1:db13dc24-9e88-440b-a473-536c0bc18ea0'
const REGION = 'us-east-1';
const ENDPOINT = 'https://a29fo1oq92o565-ats.iot.us-east-1.amazonaws.com';

const [ll, ee]   = [console.log, console.error]


const getAdminCreds = async (token) => {
    const CONFIG = {
      region: REGION,
      credentials: fromCognitoIdentityPool({
        identityPoolId: IDENTITY_POOL_ID,
        client: new CognitoIdentityClient({ region: REGION }),
        logins: {
          [token.payload.iss.substr(8)]: token.jwtToken
        },
        expired: true,
      }),
    };
    const client = new SecretsManagerClient(CONFIG);
    // ll('SecMgr client=',client)
    const cmd = new GetSecretValueCommand({
      SecretId: ADMIN_CREDS_NAME
    });
    try{
      const res = await client.send(cmd)
      // console.log('SecMgr: gsv=',res)
      // console.log('SecMgr: ss=',res.SecretString)
      const creds = JSON.parse(res.SecretString)
      return creds
    }catch(e){
      console.error('SecMgr:e:',e)
    }
}


export const MgrIoT = {
  publicar: async (client, { topic, payload, qos }) => {
    const params = {
      payload,
      qos,
      topic,
    };
    try{
      const cmd = new PublishCommand(params);
      const res = await client.send(cmd);
      ll('iot:pub: res=',res)
      return true
    }catch(e){
      ee('iot:pub: err=',e)
      return false
    }
  },

  allOp: async (operacion,args) => {
    const CONFIG = {
      region: REGION,
      endpoint: ENDPOINT,
      credentials: null,
    };
    const sess = await Auth.currentSession();
    //console.log('Current session:',sess);
    CONFIG.credentials = await getAdminCreds(sess.idToken);
    const client = new IoTDataPlaneClient(CONFIG);
    //ll('MgrIoT: client=',client)
    return MgrIoT[operacion](client, args)
  },

  mgr: async (operacion, args) => {
    switch(operacion){
      case 'publicar':
        return MgrIoT.allOp(operacion, args);

      default:
        ee(`Operación "${operacion}" no implementada`)
        throw new Error(`Operación "${operacion}" no implementada`)
    }
  }
}
