/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addCliente = /* GraphQL */ `
  mutation AddCliente($nombreCliente: String!) {
    addCliente(nombreCliente: $nombreCliente) {
      idCliente
      nombreCliente
      fechaCreacion
      idEstadoCliente
      antenas {
        idAntena
        idCliente
        idModeloAntena
        idPlacaRB
        activa
        idEstadoAntena
        idModoLectura
        intervaloLectura
        idLocacionActual
        fechaCreacion
        sensores {
          idSensor
          idAntena
          nombreSensor
          idTipoSensor
          idSensorPlaca
          idCalibracionSensor
          idUltimaLectura
          visibleSoloAdministradores
        }
        locacionActual {
          idLocacion
          idCliente
          idAntena
          nombreLocacion
          latitud
          longitud
          notas
          activa
          fechaCreacion
        }
      }
    }
  }
`;
export const updCliente = /* GraphQL */ `
  mutation UpdCliente(
    $idCliente: ID!
    $nombreCliente: String
    $idEstadoCliente: ID
  ) {
    updCliente(
      idCliente: $idCliente
      nombreCliente: $nombreCliente
      idEstadoCliente: $idEstadoCliente
    ) {
      idCliente
      nombreCliente
      fechaCreacion
      idEstadoCliente
      antenas {
        idAntena
        idCliente
        idModeloAntena
        idPlacaRB
        activa
        idEstadoAntena
        idModoLectura
        intervaloLectura
        idLocacionActual
        fechaCreacion
        sensores {
          idSensor
          idAntena
          nombreSensor
          idTipoSensor
          idSensorPlaca
          idCalibracionSensor
          idUltimaLectura
          visibleSoloAdministradores
        }
        locacionActual {
          idLocacion
          idCliente
          idAntena
          nombreLocacion
          latitud
          longitud
          notas
          activa
          fechaCreacion
        }
      }
    }
  }
`;
export const addAntena = /* GraphQL */ `
  mutation AddAntena(
    $idCliente: ID!
    $idModeloAntena: ID!
    $idPlacaRB: String!
    $intervaloLectura: Int
  ) {
    addAntena(
      idCliente: $idCliente
      idModeloAntena: $idModeloAntena
      idPlacaRB: $idPlacaRB
      intervaloLectura: $intervaloLectura
    ) {
      idAntena
      idCliente
      idModeloAntena
      idPlacaRB
      activa
      idEstadoAntena
      idModoLectura
      intervaloLectura
      idLocacionActual
      fechaCreacion
      sensores {
        idSensor
        idAntena
        nombreSensor
        idTipoSensor
        idSensorPlaca
        idCalibracionSensor
        idUltimaLectura
        visibleSoloAdministradores
        calibracionActual {
          idCalibracionSensor
          idSensor
          idLocacion
          activo
          valorCalibracion1
          valorCalibracion2
          valorCalibracion3
          valorCalibracion4
          valorCalibracion5
          notas
          fechaCreacion
          idTipoSensor
          idTipoDetectorAlerta
          configDetectorAlerta
          variableAlerta
        }
        ultimaLectura {
          idLectura
          idSensor
          idLocacion
          idCalibracionSensor
          valorLeido
          datosAdicionales
          valorCalculado1
          valorCalculado2
          valorCalculado3
          valorCalculadoAdicional
          fechaLectura
          fechaCreacion
        }
      }
      locacionActual {
        idLocacion
        idCliente
        idAntena
        nombreLocacion
        latitud
        longitud
        notas
        activa
        fechaCreacion
      }
    }
  }
`;
export const updAntena = /* GraphQL */ `
  mutation UpdAntena(
    $idAntena: ID!
    $idPlacaRB: String
    $activa: Boolean
    $idEstadoAntena: ID
    $idModoLectura: ID
  ) {
    updAntena(
      idAntena: $idAntena
      idPlacaRB: $idPlacaRB
      activa: $activa
      idEstadoAntena: $idEstadoAntena
      idModoLectura: $idModoLectura
    ) {
      idAntena
      idCliente
      idModeloAntena
      idPlacaRB
      activa
      idEstadoAntena
      idModoLectura
      intervaloLectura
      idLocacionActual
      fechaCreacion
      sensores {
        idSensor
        idAntena
        nombreSensor
        idTipoSensor
        idSensorPlaca
        idCalibracionSensor
        idUltimaLectura
        visibleSoloAdministradores
        calibracionActual {
          idCalibracionSensor
          idSensor
          idLocacion
          activo
          valorCalibracion1
          valorCalibracion2
          valorCalibracion3
          valorCalibracion4
          valorCalibracion5
          notas
          fechaCreacion
          idTipoSensor
          idTipoDetectorAlerta
          configDetectorAlerta
          variableAlerta
        }
        ultimaLectura {
          idLectura
          idSensor
          idLocacion
          idCalibracionSensor
          valorLeido
          datosAdicionales
          valorCalculado1
          valorCalculado2
          valorCalculado3
          valorCalculadoAdicional
          fechaLectura
          fechaCreacion
        }
      }
      locacionActual {
        idLocacion
        idCliente
        idAntena
        nombreLocacion
        latitud
        longitud
        notas
        activa
        fechaCreacion
      }
    }
  }
`;
export const setLocacionAntena = /* GraphQL */ `
  mutation SetLocacionAntena(
    $idAntena: ID!
    $nombreLocacion: String!
    $latitud: Float!
    $longitud: Float!
    $notas: String
  ) {
    setLocacionAntena(
      idAntena: $idAntena
      nombreLocacion: $nombreLocacion
      latitud: $latitud
      longitud: $longitud
      notas: $notas
    ) {
      idAntena
      idCliente
      idModeloAntena
      idPlacaRB
      activa
      idEstadoAntena
      idModoLectura
      intervaloLectura
      idLocacionActual
      fechaCreacion
      sensores {
        idSensor
        idAntena
        nombreSensor
        idTipoSensor
        idSensorPlaca
        idCalibracionSensor
        idUltimaLectura
        visibleSoloAdministradores
        calibracionActual {
          idCalibracionSensor
          idSensor
          idLocacion
          activo
          valorCalibracion1
          valorCalibracion2
          valorCalibracion3
          valorCalibracion4
          valorCalibracion5
          notas
          fechaCreacion
          idTipoSensor
          idTipoDetectorAlerta
          configDetectorAlerta
          variableAlerta
        }
        ultimaLectura {
          idLectura
          idSensor
          idLocacion
          idCalibracionSensor
          valorLeido
          datosAdicionales
          valorCalculado1
          valorCalculado2
          valorCalculado3
          valorCalculadoAdicional
          fechaLectura
          fechaCreacion
        }
      }
      locacionActual {
        idLocacion
        idCliente
        idAntena
        nombreLocacion
        latitud
        longitud
        notas
        activa
        fechaCreacion
      }
    }
  }
`;
export const setCalibracionSensor = /* GraphQL */ `
  mutation SetCalibracionSensor(
    $idSensor: ID!
    $idLocacion: ID!
    $valorCalibracion1: Float
    $valorCalibracion2: Float
    $valorCalibracion3: Float
    $valorCalibracion4: Float
    $valorCalibracion5: String
    $notas: String
    $idTipoDetectorAlerta: ID
    $configDetectorAlerta: AWSJSON
  ) {
    setCalibracionSensor(
      idSensor: $idSensor
      idLocacion: $idLocacion
      valorCalibracion1: $valorCalibracion1
      valorCalibracion2: $valorCalibracion2
      valorCalibracion3: $valorCalibracion3
      valorCalibracion4: $valorCalibracion4
      valorCalibracion5: $valorCalibracion5
      notas: $notas
      idTipoDetectorAlerta: $idTipoDetectorAlerta
      configDetectorAlerta: $configDetectorAlerta
    ) {
      idCalibracionSensor
      idSensor
      idLocacion
      activo
      valorCalibracion1
      valorCalibracion2
      valorCalibracion3
      valorCalibracion4
      valorCalibracion5
      notas
      fechaCreacion
      idTipoSensor
      idTipoDetectorAlerta
      configDetectorAlerta
      variableAlerta
    }
  }
`;
export const addLecturaCalibracion = /* GraphQL */ `
  mutation AddLecturaCalibracion(
    $idPlacaRB: String!
    $idSensorPlaca: String!
    $valorLeido: Float
    $datosAdicionales: String
    $fechaLectura: AWSDateTime!
    $timestamp: AWSDateTime
  ) {
    addLecturaCalibracion(
      idPlacaRB: $idPlacaRB
      idSensorPlaca: $idSensorPlaca
      valorLeido: $valorLeido
      datosAdicionales: $datosAdicionales
      fechaLectura: $fechaLectura
      timestamp: $timestamp
    ) {
      idLecturaCalibracion
      idSensor
      valorLeido
      datosAdicionales
      fechaLectura
      fechaCreacion
    }
  }
`;
export const resetLecturasCalibracion = /* GraphQL */ `
  mutation ResetLecturasCalibracion(
    $idPlacaRB: String!
    $idSensorPlaca: String!
  ) {
    resetLecturasCalibracion(
      idPlacaRB: $idPlacaRB
      idSensorPlaca: $idSensorPlaca
    )
  }
`;
export const addLecturasSensor = /* GraphQL */ `
  mutation AddLecturasSensor(
    $idPlacaRB: String!
    $idSensorPlaca: String!
    $valores: [ValorLeido]
    $timestamp: AWSDateTime
  ) {
    addLecturasSensor(
      idPlacaRB: $idPlacaRB
      idSensorPlaca: $idSensorPlaca
      valores: $valores
      timestamp: $timestamp
    ) {
      idLectura
      idSensor
      idLocacion
      idCalibracionSensor
      valorLeido
      datosAdicionales
      valorCalculado1
      valorCalculado2
      valorCalculado3
      valorCalculadoAdicional
      fechaLectura
      fechaCreacion
    }
  }
`;
export const addAlerta = /* GraphQL */ `
  mutation AddAlerta(
    $idSensor: ID!
    $idDetector: ID!
    $habilitada: Boolean!
    $valoresParametros: [ValorParametroAlertaInput!]
    $asignacionesVariables: [AsignacionVariableAlertaInput!]
    $notificarEmail: Boolean
    $destinatariosEmail: String
    $notificarPush: Boolean
    $destinatariosPush: String
  ) {
    addAlerta(
      idSensor: $idSensor
      idDetector: $idDetector
      habilitada: $habilitada
      valoresParametros: $valoresParametros
      asignacionesVariables: $asignacionesVariables
      notificarEmail: $notificarEmail
      destinatariosEmail: $destinatariosEmail
      notificarPush: $notificarPush
      destinatariosPush: $destinatariosPush
    ) {
      idAlerta
      idSensor
      idDetector
      borrada
      habilitada
      valoresParametros {
        nombreParametro
        valor
      }
      asignacionesVariables {
        nombreVariable
        campoSensor
        conversion
      }
      estadoActual
      variablesEstadoActual
      fechaEstadoActual
      estadoReportado
      fechaEstadoReportado
      variablesEstadoReportado
      fechaCreacion
      notificaciones {
        idNotificacionAlerta
        idAlerta
        nombreEstado
        evento
        nivelAlerta
        generarLog
        generarEmail
        destinatariosEmail
        alertaPush
        destinatariosPush
        plantilla
        activa
      }
    }
  }
`;
export const updAlerta = /* GraphQL */ `
  mutation UpdAlerta(
    $idAlerta: ID!
    $habilitada: Boolean
    $valoresParametros: [ValorParametroAlertaInput!]
    $asignacionesVariables: [AsignacionVariableAlertaInput!]
    $estadoActual: String
    $fechaEstadoActual: AWSDateTime
    $variablesEstadoActual: AWSJSON
    $estadoReportado: String
    $fechaEstadoReportado: AWSDateTime
    $variablesEstadoReportado: AWSJSON
  ) {
    updAlerta(
      idAlerta: $idAlerta
      habilitada: $habilitada
      valoresParametros: $valoresParametros
      asignacionesVariables: $asignacionesVariables
      estadoActual: $estadoActual
      fechaEstadoActual: $fechaEstadoActual
      variablesEstadoActual: $variablesEstadoActual
      estadoReportado: $estadoReportado
      fechaEstadoReportado: $fechaEstadoReportado
      variablesEstadoReportado: $variablesEstadoReportado
    ) {
      idAlerta
      idSensor
      idDetector
      borrada
      habilitada
      valoresParametros {
        nombreParametro
        valor
      }
      asignacionesVariables {
        nombreVariable
        campoSensor
        conversion
      }
      estadoActual
      variablesEstadoActual
      fechaEstadoActual
      estadoReportado
      fechaEstadoReportado
      variablesEstadoReportado
      fechaCreacion
      notificaciones {
        idNotificacionAlerta
        idAlerta
        nombreEstado
        evento
        nivelAlerta
        generarLog
        generarEmail
        destinatariosEmail
        alertaPush
        destinatariosPush
        plantilla
        activa
      }
    }
  }
`;
export const delAlerta = /* GraphQL */ `
  mutation DelAlerta($idAlerta: ID!) {
    delAlerta(idAlerta: $idAlerta)
  }
`;
export const addDefaultNotificacionesAlerta = /* GraphQL */ `
  mutation AddDefaultNotificacionesAlerta(
    $idAlerta: ID!
    $idDetector: ID!
    $notificarEmail: Boolean
    $destinatariosEmail: String
    $notificarPush: Boolean
    $destinatariosPush: String
    $plantilla: String
    $reemplazar: Boolean!
  ) {
    addDefaultNotificacionesAlerta(
      idAlerta: $idAlerta
      idDetector: $idDetector
      notificarEmail: $notificarEmail
      destinatariosEmail: $destinatariosEmail
      notificarPush: $notificarPush
      destinatariosPush: $destinatariosPush
      plantilla: $plantilla
      reemplazar: $reemplazar
    ) {
      idNotificacionAlerta
      idAlerta
      nombreEstado
      evento
      nivelAlerta
      generarLog
      generarEmail
      destinatariosEmail
      alertaPush
      destinatariosPush
      plantilla
      activa
    }
  }
`;
export const addNotificacionAlerta = /* GraphQL */ `
  mutation AddNotificacionAlerta(
    $idAlerta: ID!
    $nombreEstado: String!
    $evento: String
    $activa: Boolean!
    $nivelAlerta: NivelAlerta!
    $generarLog: Boolean!
    $generarEmail: Boolean!
    $destinatariosEmail: String
    $alertaPush: Boolean!
    $destinatariosPush: String
    $plantilla: String
  ) {
    addNotificacionAlerta(
      idAlerta: $idAlerta
      nombreEstado: $nombreEstado
      evento: $evento
      activa: $activa
      nivelAlerta: $nivelAlerta
      generarLog: $generarLog
      generarEmail: $generarEmail
      destinatariosEmail: $destinatariosEmail
      alertaPush: $alertaPush
      destinatariosPush: $destinatariosPush
      plantilla: $plantilla
    ) {
      idNotificacionAlerta
      idAlerta
      nombreEstado
      evento
      nivelAlerta
      generarLog
      generarEmail
      destinatariosEmail
      alertaPush
      destinatariosPush
      plantilla
      activa
    }
  }
`;
export const updNotificacionAlerta = /* GraphQL */ `
  mutation UpdNotificacionAlerta(
    $idNotificacionAlerta: ID!
    $nombreEstado: String!
    $evento: String
    $activa: Boolean!
    $nivelAlerta: NivelAlerta!
    $generarLog: Boolean!
    $generarEmail: Boolean!
    $destinatariosEmail: String
    $alertaPush: Boolean!
    $destinatariosPush: String
    $plantilla: String
  ) {
    updNotificacionAlerta(
      idNotificacionAlerta: $idNotificacionAlerta
      nombreEstado: $nombreEstado
      evento: $evento
      activa: $activa
      nivelAlerta: $nivelAlerta
      generarLog: $generarLog
      generarEmail: $generarEmail
      destinatariosEmail: $destinatariosEmail
      alertaPush: $alertaPush
      destinatariosPush: $destinatariosPush
      plantilla: $plantilla
    ) {
      idNotificacionAlerta
      idAlerta
      nombreEstado
      evento
      nivelAlerta
      generarLog
      generarEmail
      destinatariosEmail
      alertaPush
      destinatariosPush
      plantilla
      activa
    }
  }
`;
export const delNotificacionAlerta = /* GraphQL */ `
  mutation DelNotificacionAlerta($idNotificacionAlerta: ID!) {
    delNotificacionAlerta(idNotificacionAlerta: $idNotificacionAlerta)
  }
`;
export const addRegistroAlerta = /* GraphQL */ `
  mutation AddRegistroAlerta(
    $idAlerta: ID!
    $nombreEstado: String
    $evento: String
    $nivel: NivelAlerta
    $mensaje: String
    $fechaAlerta: AWSDateTime!
  ) {
    addRegistroAlerta(
      idAlerta: $idAlerta
      nombreEstado: $nombreEstado
      evento: $evento
      nivel: $nivel
      mensaje: $mensaje
      fechaAlerta: $fechaAlerta
    ) {
      idRegistroAlerta
      idAlerta
      fechaAlerta
      fechaCreacion
      nombreEstado
      evento
      nivel
      mensaje
    }
  }
`;
