module.exports = {

  EstadoCliente: {
    TIPO: 'EstadoCliente',
    ACTIVO: '1000',
    SUSPENDIDO: '1001',
    INACTIVO: '1002'
  },

  ModeloAntena: {
    TIPO: 'ModeloAntena',
    ANTENA_ESTANDAR: '2000',
  },

  TipoSensor: {
    TIPO: 'TipoSensor',
    Ultrasonico: '3000',
    ControlDeCarga: '3001',
    TemperaturaCPU: '3002',
  },

  EstadoAntena: {
    TIPO: 'EstadoAntena',
    INACTIVA: '4000',
    ACTIVA: '4001',
    PROBLEMAS: '4002',
    FALLA: '4003'
  },

  ModoLecturaAntena: {
    TIPO: 'ModoLecturaAntena',
    NORMAL: '5000',
    CALIBRACION: '5001',
  },

  TipoDetector: {
    TIPO: 'TipoDetectorAlerta',
    DETECTOR_VALOR_MINIMO: '10000',
    DETECTOR_VALOR_MAXIMO: '10001',
  }


}
