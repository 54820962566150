import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import TablaDatos from '../components/TablaDatos';
import { MgrCognito } from '../mgrcognito';
import { useStore, selUserPoolId, selUserClientId } from '../store'
import { Auth } from 'aws-amplify';
import LoadingIcon from '../components/loading';
//import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  botonBotonera: {
    marginBottom: theme.spacing(1)
  },
}))

const refreshUsuarios = async (idCliente, poolId, setUsuarios) => {
  try {
    const sess = await Auth.currentSession();
    console.log('Current session:',sess);
    const res = await MgrCognito.mgr( 'getUsuarios', { idCliente, poolId, token:sess.idToken });
    console.log('refreshUsuarios: res:',res)
    setUsuarios(res)
  }catch(e){
    console.error('RefreshUsuarios: ERROR:',e)
  }

};


export default function PaginaUsuarios() {
  const classes = useStyles()
  const idCliente = useStore(selUserClientId)
  const poolId = useStore(selUserPoolId)
  const [usuarios, setUsuarios] = React.useState(null)

  const Columnas = [
    {
      title: 'Usuario',
      field: 'username',
      width: 200,
      align: 'left',
    },
    {
      title: 'Email',
      field: 'email',
      width: 200,
      align: 'left',
    },
    {
      title: 'Perfil',
      field: 'perfil',
      width: 200,
      align: 'left',
    },
  ]

  React.useEffect(() => {
    refreshUsuarios(idCliente, poolId, setUsuarios)
  },[idCliente, poolId])

  const onAgregarUsuario = () => {

  }

  return (
    <main className={classes.root}>
    { usuarios === null && <LoadingIcon /> }
    { usuarios !== null &&
      <Grid container spacing={2}>
      <Button className={classes.botonBotonera} type="button" variant="contained" startIcon={<AddTwoToneIcon size='small' />} color="primary" onClick={onAgregarUsuario}>Usuario</Button>
      <TablaDatos columnas={Columnas} datos={usuarios} />
      </Grid>
    }
    </main>
  )
}
