import fs from 'fs'

class Automission {
  constructor(tipoVehiculo){
    this.tipoVehiculo = tipoVehiculo
    this.mlist = []
    this.counter = 1
    this.mlist.push('QGC WPL 110')
    this.mlist.push('0	1	0	0	0	0	0	0	0	0	0	1')
  }

  param_to_mcommand(...args){
    this.mlist.push(`${this.counter++}\t${args.join('\t')}`)
  }

  waypoint(lat,lon,alt,delay=0){
		const waypoint_id=16
		this.param_to_mcommand(0,3,waypoint_id,delay,0,0,0,lat,lon,alt,1)
  }
	takeoff(angle,alt,lat=0,lon=0){
		const takeoff_id=22
		this.param_to_mcommand(0,3,takeoff_id,angle,0,0,0,lat,lon,alt,1)
  }
	land(lat=0,lon=0,alt=0){
		const landid=21
		this.param_to_mcommand(0,3,landid,0,0,0,0,lat,lon,alt,1)
  }
	do_set_roi(lat,lon,alt=0) {
		const do_set_roi_id=201
		this.param_to_mcommand(0,3,do_set_roi_id,0,0,0,0,lat,lon,alt,1)
  }
	rtl() {
		const rtl_id=20
		this.param_to_mcommand(0,3,rtl_id,0,0,0,0,0,0,0,1)
  }
	spline_waypoint(lat,lon,alt,delay=0){
		const spline_waypoint_id=82
		this.param_to_mcommand(0,3,spline_waypoint_id,delay,0,0,0,lat,lon,alt,1)
  }
	loiter_time(time,lat=0,lon=0,alt=0){
		const loiter_time_id=19
		this.param_to_mcommand(0,3,loiter_time_id,time,0,0,0,lat,lon,alt,1)
  }
	loiter_turns(turn,direction,lat=0,lon=0,alt=0){
		const loiter_turns_id=18
		this.param_to_mcommand(0,3,loiter_turns_id,turn,0,direction,0,lat,lon,alt,1)
  }
	loiter_unlim(lat=0,lon=0,alt=0){
		const loiter_unlim_id=17
		this.param_to_mcommand(0,3,loiter_unlim_id,0,0,0,0,lat,lon,alt,1)
  }
	condition_delay(time){
		const condition_delay_id=112
		this.param_to_mcommand(0,3,condition_delay_id,time,0,0,0,0,0,0,1)
  }
	condition_distance(dist){
		const condition_distance_id=114
		this.param_to_mcommand(0,3,condition_distance_id,dist,0,0,0,0,0,0,1)
  }
	condition_yaw(deg,rel_abs,dir=0){
		const condition_yaw_id=115
		this.param_to_mcommand(0,3,condition_yaw_id,deg,0,dir,rel_abs,0,0,0,1)
  }
	do_jump(wp_number,repeat){
		const do_jump_id=177
		this.param_to_mcommand(0,3,do_jump_id,wp_number,repeat,0,0,0,0,0,1)
  }
	do_change_speed(speed){
		const do_change_speed_id=178
		this.param_to_mcommand(0,3,do_change_speed_id,speed,0,0,0,0,0,0,1)
  }
	do_set_home(current=1,lat=0,lon=0){
		const do_set_home_id=179
		this.param_to_mcommand(0,3,do_set_home_id,current,0,0,0,lat,lon,0,1)
  }
	do_digicam_control(){
		const do_digicam_control_id=203
		this.param_to_mcommand(0,3,do_digicam_control_id,0,0,0,0,0,0,0,1)
  }

  asText(){
    return [...this.mlist,''].join('\r\n')
  }

  write(fileName="salida.txt"){
    const out = fs.createWriteStream(fileName)
    out.end(this.asBlob())
  }
}

export function mision_directa(lat,lon,alt=50){
  const my_mission = new Automission('copter')

  my_mission.takeoff(10,15)

  my_mission.waypoint(lat,lon,alt)

//  my_mission.waypoint(lat+0.001,lon-0.0002,alt+5)

//  my_mission.do_set_roi(-33.034332912151,-71.5933064371347)
  const increment = 0.0002
  for(let i=0; i<7; i++){
  	my_mission.waypoint(lat,lon,alt)
  	my_mission.do_digicam_control()
  	lat += i%4===0 ? increment/2 : 0
  	lon += i%4===1 ? increment : 0
  	lat += i%4===2 ? increment/2 : 0
  	lon -= i%4===3 ? increment : 0
  	alt= alt+1
  }
  my_mission.rtl()
  return my_mission.asText()
}

export function mision_reconocimiento(lat,lon,alt=50){
  const my_mission = new Automission('copter')
  my_mission.waypoint(lat,lon,alt)
//  my_mission.do_set_home(lat,lon)
  let increase = 0.0001
  for(let i=0; i<10; i++){
  	my_mission.spline_waypoint(lat,lon,alt)
  	lat += i%4===0 ? increase : 0
  	lon += i%4===1 ? increase : 0
  	lat -= i%4===2 ? increase : 0
  	lon -= i%4===3 ? increase : 0
  	increase+=0.00001
  }
  my_mission.rtl()
  return my_mission.asText()
}
