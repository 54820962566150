import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Grid, Checkbox } from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import DateFnsAdapter from '@date-io/luxon';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import { Formik, useFormikContext, Form, Field } from 'formik'
import * as Yup from 'yup'
import {Settings, DateTime, Interval} from 'luxon'
import { useStore, selDatosFormConsulta, selSetDatosFormConsulta } from '../store';



Settings.defaultLocale = 'es'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControlFree: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  formControlAngosto: {
    margin: theme.spacing(0.5),
    maxWidth: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  stackedButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
  },
  form: {
    alignItems: 'center',
    margin: 0,
  }
}));


const DateTimePickerField = ({ field, form, ...other }) => {
  const currentError = form.errors[field.name];
  return (
    <DateTimePicker
      margin="dense"
      variant="dialog"
      ampm={false}
      name={field.name}
      value={field.value}
      format="dd/MM/yyyy HH:mm"
      helperText={currentError}
      error={Boolean(currentError)}
      onError={error => {
        // handle as a side effect
        if (error !== currentError) {
          form.setFieldError(field.name, error);
        }
      }}
      // if you are using custom validation schema you probably want to pass `true` as third argument
      onChange={date => form.setFieldValue(field.name, date, false)}
      {...other}
    />
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export default function FormConsultaDatos({ sensores, handleConsultar, cargando }) {
  const classes = useStyles()
  const datosFormConsulta = useStore(selDatosFormConsulta)
  const setDatosFormConsulta = useStore(selSetDatosFormConsulta)

  const AGREGACIONES = [
    { unidad:'MUESTRA', label: 'lectura'},
    { unidad:'MINUTO', label:'minuto' },
    { unidad:'HORA', label:'hora' },
    { unidad:'DIA', label:'día' },
    { unidad:'SEMANA', label:'semana' },
    { unidad:'MES', label:'mes' },
  ]

  const initialValues = datosFormConsulta || {
    sensores: [],
    fechaInicio: DateTime.now().minus({days: 7}).startOf('day').toString(),
    fechaFin: DateTime.now().toString(),
    agregacion: 'MINUTO',
  }

  const consultaValidationSchema = {
    sensores: Yup.array().required(),
    fechaInicio: Yup.date().required(),
    fechaFin: Yup.date().required(),
  }

  const validationSchema = Yup.object(consultaValidationSchema)

  const formik_handleSubmit = consulta => {
    const res = handleConsultar(consulta)
    setDatosFormConsulta({
      sensores: consulta.sensores,
      fechaInicio: consulta.fechaInicio,
      fechaFin: consulta.fechaFin,
      agregacion: consulta.agregacion,
    })
    if(res instanceof Promise){
      res.catch(e => {
        console.log('Error en consulta',e)
      })
    }
  }

  const BotonSubmit = ({cargando}) => {
    const { values, setFieldValue, submitForm } = useFormikContext()

    const botonSubmitClick = () => {
      console.log('botonSubmitClick: v:',values)
      submitForm()
    }

    const botonActualizarClick = () => {
      const interv = Interval.fromDateTimes(DateTime.fromISO(values.fechaInicio), DateTime.fromISO(values.fechaFin))
      const updFin = DateTime.now()
      const diff = interv.toDuration()
      const updInicio = updFin.minus(diff)
      console.log('actualizar:',{interv, diff, updFin, updInicio})
      setFieldValue('fechaFin', updFin.toString())
      setFieldValue('fechaInicio', updInicio.toString())
      submitForm()
    }

    return (
      <Grid item container md="2" xs="12" spacing={1}>
        <Grid item md="12" sm="6" xs="12">
          <Button disabled={cargando} type="button" fullWidth variant="contained" onClick={botonSubmitClick} color="primary">Mostrar</Button>
        </Grid>
        <Grid item md="12" sm="6" xs="12">
          <Button disabled={cargando} type="button"  fullWidth variant="contained" onClick={botonActualizarClick} color="primary">Actualizar</Button>
        </Grid>
      </Grid>
    )
  }


  const SelectSensores = ({sensores, ...props}) => {
    const { values, setFieldValue } = useFormikContext()
    const [inputValue, setInputValue] = React.useState('')

    const onSensoresChange = (event, newValue, reason) => {
      //console.log('handleChange:',event, newValue, reason)
      setFieldValue(props.name, newValue)
    }
    const handleInputChange = (event, newValue, reason) => {
      //console.log('handleChange:',event, newValue, reason)
      setInputValue(newValue)
    }
    const handleGetOptionSelected = (option, value) => {
      return value.label === option.label && value.idSensor === option.idSensor
    }
    const handleRenderOption = (option, { selected }) => (
      <React.Fragment>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option.label}
      </React.Fragment>
    )

    return (
      <Autocomplete className={classes.formControlFree}
        fullWidth
        multiple
        size="small"
        id="sensores"
        name="sensores"
        options={sensores}
        disableCloseOnSelect
        limitTags={3}
        value={values.sensores}
        onChange={onSensoresChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => option.label}
        getOptionSelected={handleGetOptionSelected}
        renderOption={handleRenderOption}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Sensor(es)" placeholder="seleccionar" />
        )}
      />
    )
  }


  return (
      <Formik initialValues={initialValues} onSubmit={formik_handleSubmit} validationSchema={validationSchema}>
      { (formik) => { return (
        <Form className={classes.form} key="form">
        <MuiPickersUtilsProvider utils={DateFnsAdapter}>

        <Grid container  direction="row" justifyContent="center" alignItems="flex-start" spacing={2} >
          <Grid item md="4" xs="12">
            <SelectSensores name="sensores" sensores={sensores} />
          </Grid>
          <Grid item md="2" xs="6">
            <Field name="fechaInicio" label="Desde" component={DateTimePickerField} />
          </Grid>
          <Grid item md="2" xs="6">
            <Field name="fechaFin" label="Hasta" component={DateTimePickerField} />
          </Grid>

          <Grid item md="2" xs="6">
            <FormControl >
              <InputLabel id="agregacionLabel">Agregación</InputLabel>
              <Select
                autoFocus
                margin="dense"
                labelId="agregacionLabel"
                id="agregacion"
                name="agregacion"
                value={formik.values.agregacion}
                onChange={formik.handleChange}
              >
              { AGREGACIONES.map(u => { return (
                <MenuItem value={u.unidad}>
                  <em>{u.label}</em>
                </MenuItem>
              )})}
              </Select>
            </FormControl>
          </Grid>
          <BotonSubmit cargando={cargando > 0} />

          </Grid>
        </MuiPickersUtilsProvider>
      </Form>
    )}}
    </Formik>
)}
