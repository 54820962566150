import { I18n } from "aws-amplify"
import { Translations } from "@aws-amplify/ui-components"

const TRADUCCIONES_ES = {
  [Translations.BACK_TO_SIGN_IN]: 'Volver a inicio de sesión',
  [Translations.CHANGE_PASSWORD_ACTION]: 'Cambiar',
  [Translations.CHANGE_PASSWORD]: 'Cambiar clave',
  [Translations.CODE_LABEL]: 'Código de verificación',
  [Translations.CODE_PLACEHOLDER]: 'Ingrese el código',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Código de confirmación',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'Ingrese el código',
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Confirmar registro',
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: '¿Perdió su código?',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Re-enviar el código',
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Confirmar',
  [Translations.CONFIRM_SMS_CODE]: 'Confirmar Código SMS',
  [Translations.CONFIRM_TOTP_CODE]: 'Confirmar Código TOTP',
  [Translations.CONFIRM]: 'Confirmar',
  [Translations.CREATE_ACCOUNT_TEXT]: 'Crear cuenta',
  [Translations.EMAIL_LABEL]: 'Correo electrónico *',
  [Translations.EMAIL_PLACEHOLDER]: 'Ingrese su dirección de correo electrónico',
  [Translations.FORGOT_PASSWORD_TEXT]: '¿Olvidó su clave?',
  [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]: 'Menos de dos tipos de MFA disponibles',
  [Translations.NEW_PASSWORD_LABEL]: 'Nueva clave',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Ingrese su nueva clave',
  [Translations.NO_ACCOUNT_TEXT]: '¿No tiene cuenta?',
  [Translations.USERNAME_REMOVE_WHITESPACE]: 'El nombre de usuario no puede contener espacios',
  [Translations.PASSWORD_REMOVE_WHITESPACE]: 'La clave no puede tener espacios al inicio ni al final',
  [Translations.PASSWORD_LABEL]: 'Clave *',
  [Translations.PASSWORD_PLACEHOLDER]: 'Ingrese su clave',
  [Translations.PHONE_LABEL]: 'Teléfono *',
  [Translations.PHONE_PLACEHOLDER]: '999999999',
  [Translations.QR_CODE_ALT]: 'Código QR',
  [Translations.RESET_PASSWORD_TEXT]: 'Recuperar clave',
  [Translations.RESET_YOUR_PASSWORD]: 'Recuperar su clave',
  [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: 'Seleccionar tipo de MFA',
  [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: 'Verificar',
  [Translations.SEND_CODE]: 'Enviar código',
  [Translations.SUBMIT]: 'Enviar',
  [Translations.SETUP_TOTP_REQUIRED]: 'Necesita configurar TOTP',
  [Translations.SIGN_IN_ACTION]: 'Iniciar sesión',
  [Translations.SIGN_IN_HEADER_TEXT]: 'Inicio de sesión',
  [Translations.SIGN_IN_TEXT]: 'Inicio de sesión',
  [Translations.SIGN_IN_WITH_AMAZON]: 'Iniciar sesión con Amazon',
  [Translations.SIGN_IN_WITH_AUTH0]: 'Iniciar sesión con Auth0',
  [Translations.SIGN_IN_WITH_AWS]: 'Iniciar sesión con AWS',
  [Translations.SIGN_IN_WITH_FACEBOOK]: 'Iniciar sesión con Facebook',
  [Translations.SIGN_IN_WITH_GOOGLE]: 'Iniciar sesión con Google',
  [Translations.SIGN_OUT]: 'Cerrar sesión',
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'correo@dominio.com',
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: '¿Tiene una cuenta?',
  [Translations.SIGN_UP_HEADER_TEXT]: 'Crear una cuenta nueva',
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'clave',
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Crear cuenta',
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'Nombre de usuario',
  [Translations.SUCCESS_MFA_TYPE]: 'Correcto! Su tipo de MFA ahora es:',
  [Translations.TOTP_HEADER_TEXT]: 'Capture y luego ingrese el código',
  [Translations.TOTP_LABEL]: 'ingrese el código de seguridad:',
  [Translations.TOTP_ISSUER]: 'AWSCognito',
  [Translations.TOTP_SETUP_FAILURE]: 'Falló la configuración de TOTP',
  [Translations.TOTP_SUBMIT_BUTTON_TEXT]: 'Verificar el token de seguridad',
  [Translations.TOTP_SUCCESS_MESSAGE]: 'Configuración de TOTP exitosa!',
  [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]: 'Error! No se pudo configurar MFA en este momento',
  [Translations.USERNAME_LABEL]: 'Nombre de usuario *',
  [Translations.USERNAME_PLACEHOLDER]: 'Ingrese su nombre de usuario',
  [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'Correo electrónico',
  [Translations.VERIFY_CONTACT_HEADER_TEXT]: 'La recuperacion de la cuenta requiere que la información de contacto haya sido verificada',
  [Translations.VERIFY_CONTACT_PHONE_LABEL]: 'Número de teléfono',
  [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: 'Enviar',
  [Translations.VERIFY_CONTACT_VERIFY_LABEL]: 'Verificar',
  [Translations.ADDRESS_LABEL]: 'Dirección',
  [Translations.ADDRESS_PLACEHOLDER]: 'Ingrese su dirección',
  [Translations.NICKNAME_LABEL]: 'Alias',
  [Translations.NICKNAME_PLACEHOLDER]: 'Ingrese su alias',
  [Translations.BIRTHDATE_LABEL]: 'Fecha de nacimiento',
  [Translations.BIRTHDATE_PLACEHOLDER]: 'Ingrese su fecha de nacimiento',
  [Translations.PICTURE_LABEL]: 'URL de la imagen',
  [Translations.PICTURE_PLACEHOLDER]: 'Ingrese el URL de la imagen',
  [Translations.FAMILY_NAME_LABEL]: 'Apellido',
  [Translations.FAMILY_NAME_PLACEHOLDER]: 'Ingrese su apelldo',
  [Translations.PREFERRED_USERNAME_LABEL]: 'Nombre de usuario preferido',
  [Translations.PREFERRED_USERNAME_PLACEHOLDER]: 'Ingrese su nombre de usuario preferido',
  [Translations.GENDER_LABEL]: 'Género',
  [Translations.GENDER_PLACEHOLDER]: 'Ingrese su género',
  [Translations.PROFILE_LABEL]: 'URL del perfil',
  [Translations.PROFILE_PLACEHOLDER]: 'Ingrese el URL de su perfil',
  [Translations.GIVEN_NAME_LABEL]: 'Nombre',
  [Translations.GIVEN_NAME_PLACEHOLDER]: 'Ingrese su nombre',
  [Translations.ZONEINFO_LABEL]: 'Zona horaria',
  [Translations.ZONEINFO_PLACEHOLDER]: 'Ingrese su zona horaria',
  [Translations.LOCALE_LABEL]: 'Idioma',
  [Translations.LOCALE_PLACEHOLDER]: 'Ingrese su Idioma',
  [Translations.UPDATED_AT_LABEL]: 'Actualizado en',
  [Translations.UPDATED_AT_PLACEHOLDER]: 'ingrese cuando se hizo la última actualización',
  [Translations.MIDDLE_NAME_LABEL]: 'Segundo nombre',
  [Translations.MIDDLE_NAME_PLACEHOLDER]: 'Ingrese su segundo nombre',
  [Translations.WEBSITE_LABEL]: 'Sitio web',
  [Translations.WEBSITE_PLACEHOLDER]: 'Ingrese su sitio web',
  [Translations.NAME_LABEL]: 'Nomrbe completo',
  [Translations.NAME_PLACEHOLDER]: 'Ingrese su nombre completo',
  [Translations.PHOTO_PICKER_TITLE]: 'Título del selector',
  [Translations.PHOTO_PICKER_HINT]: 'Texto auxiliar que puede ocupar este espacio',
  [Translations.PHOTO_PICKER_PLACEHOLDER_HINT]: 'Sugerencia',
  [Translations.PHOTO_PICKER_BUTTON_TEXT]: 'Botón',
  [Translations.IMAGE_PICKER_TITLE]: 'Agregar foto para el perfil',
  [Translations.IMAGE_PICKER_HINT]: 'Previsualizar antes de cambiar',
  [Translations.IMAGE_PICKER_PLACEHOLDER_HINT]: 'Tocar la imagen para seleccionar',
  [Translations.IMAGE_PICKER_BUTTON_TEXT]: 'Cargar',
  [Translations.PICKER_TEXT]: 'Escoja un archivo',
  [Translations.TEXT_FALLBACK_CONTENT]: 'Contenido por omisión',
  [Translations.CONFIRM_SIGN_UP_FAILED]: 'Confirmación de registro fallida',
  [Translations.SIGN_UP_FAILED]: 'Registro fallido',
  [Translations.CHATBOT_TITLE]: 'ChatBot Lex',
  [Translations.TEXT_INPUT_PLACEHOLDER]: 'Escriba un mensaje',
  [Translations.VOICE_INPUT_PLACEHOLDER]: 'Haga click en el micrófono para hablar',
  [Translations.CHAT_DISABLED_ERROR]: 'Error: Ya sea voz o texto deben estar habilitados para usar el chatbot',
  [Translations.NO_BOT_NAME_ERROR]: 'Error: Se debe proveer un nombre para usar el ChatBot',
}

const initTraducciones = () => {
  I18n.putVocabulariesForLanguage("es-CL", TRADUCCIONES_ES)
  I18n.putVocabulariesForLanguage("es", TRADUCCIONES_ES)
  I18n.setLanguage('es-CL')
}

export default initTraducciones
