import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, TextField, FormGroup } from '@material-ui/core'
import { Card, CardContent, CardActions, CardHeader } from '@material-ui/core'
import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControlSmall: {
    margin: theme.spacing(1),
    width: 192,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DEFAULT_LOCACION = {
  nombreLocacion: '',
  latitud: null,
  longitud: null,
  notas: ''
}

export default function FormLocacion({ antena, onClose, latlng, ...otherProps }) {
  const classes = useStyles()

  const initialValues = (antena.idLocacionActual ? {...antena.locacionActual} : {...DEFAULT_LOCACION, idAntena:antena.idAntena} )
  if(latlng && latlng.lat !== null && latlng.lng !== null){
    initialValues.latitud = latlng.lat
    initialValues.longitud = latlng.lng
  }

  const locacionValidationSchema = {
    idAntena: Yup.number().required(),
    nombreLocacion: Yup.string().required('debe ingresar un nombre de locacion').max(100),
    latitud: Yup.number().required('Debe ingresar un valor de latitud')
      .min(-180,'Debe ingresar un valor de latitud entre -180 y +180')
      .max(180,'Debe ingresar un valor de latitud entre -180 y +180'),
    longitud: Yup.number().required('Debe ingresar un valor de longitud')
      .min(-180,'Debe ingresar un valor de longitud entre -180 y +180')
      .max(180,'Debe ingresar un valor de longitud entre -180 y +180'),
    notas: Yup.string().max(500)
  }

  const validationSchema = Yup.object(locacionValidationSchema)

  console.log('initialValues:',initialValues)

  const formik_handleSubmit = editLoc => {
    onClose(antena, editLoc)
  }

  const handleClose = () => {
    onClose()
  }

  const BotoneraLocacion = ({values,latlng}) => {
    const { setFieldValue } = useFormikContext()
    const setLocacion = () => {
      setFieldValue('idLocacion',null)
      setFieldValue('nombreLocacion','')
      setFieldValue('latitud', '')
      setFieldValue('longitud', '')
      setFieldValue('notas','')
    }
    return (
      <FormGroup row key="BotoneraLocacion">
      { values.idLocacionActual &&
        <Button onClick={setLocacion}>Nueva locacion</Button>
      }
      </FormGroup>
    )
  }

  const BotonSubmit = (props) => {
    const { values, submitForm } = useFormikContext()
    const botonSubmitClick = () => {
      console.log('botonSubmitClick: v:',values)
      submitForm()
    }
    return (
      <Button type="button" onClick={botonSubmitClick} color="primary">Guardar</Button>
    )
  }

  const CampoCoordenada = ({name,coordenada}) => {
    const { values, handleChange, touched, errors, setFieldValue } = useFormikContext()

    //Actualizar el valor segun lo que se clickeo en el mapa
    React.useEffect(() => {
      if(coordenada !== null && coordenada !== undefined) setFieldValue(name,coordenada)
    },[name,coordenada,setFieldValue])

    return (
      <TextField
        className={classes.formControlSmall}
        margin="dense"
        id={name}
        name={name}
        label={name[0].toUpperCase()+name.substring(1)}
        type="number"
        value={values[name]}
        onChange={handleChange}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
      />)
  }


  return (
    <Card className={classes.root}>
      <CardHeader id="form-dialog-title" title="Modificar locación antena" subheader="Puede cambiar las coordenadas haciendo click en el mapa o ingresarlas manualmente."/>
      <Formik initialValues={initialValues} onSubmit={formik_handleSubmit} validationSchema={validationSchema}>
      { (formik) => { return (
      <>
      <CardContent>

        <form onSubmit={formik.handleSubmit}>
          <BotoneraLocacion values={formik.values} latlng={latlng} />
          <FormGroup row key="fila5">
            <TextField
              className={classes.formControl}
              margin="dense"
              id="nombreLocacion"
              name="nombreLocacion"
              label="Locacion"
              type="text"
              maxLength="100"
              fullWidth
              value={formik.values.nombreLocacion}
              onChange={formik.handleChange}
              error={formik.touched.nombreLocacion && Boolean(formik.errors.nombreLocacion)}
              helperText={formik.touched.nombreLocacion && formik.errors.nombreLocacion}
            />
          </FormGroup>
          <FormGroup row key="fila6">
            <CampoCoordenada name="latitud" coordenada={latlng ? latlng.lat : null} />
            <CampoCoordenada name="longitud" coordenada={latlng ? latlng.lng : null} />
          </FormGroup>
          <FormGroup row key="fila7">
            <TextField
              className={classes.formControl}
              margin="dense"
              id="notas"
              name="notas"
              label="Notas"
              type="text"
              maxLength="200"
              fullWidth
              value={formik.values.notas}
              onChange={formik.handleChange}
              error={formik.touched.notas && Boolean(formik.errors.notas)}
              helperText={formik.touched.notas && formik.errors.notas}
            />
          </FormGroup>
      </form>
      </CardContent>

      <CardActions>
        <Button onClick={handleClose} color="primary">Cancelar</Button>
        <BotonSubmit />
      </CardActions>
      </>
    )}}
    </Formik>
  </Card>
)}
