import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, } from '@material-ui/core'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import RemoveCircleTwoToneIcon from '@material-ui/icons/RemoveCircleTwoTone'
import RouterTwoToneIcon from '@material-ui/icons/RouterTwoTone'
import ReportProblemTwoToneIcon from '@material-ui/icons/ReportProblemTwoTone'
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone'
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone'
import { EstadoAntena, ModoLecturaAntena } from '../enums'
import { makeLoggers } from '../utils'
const {ll,ee} = makeLoggers('ListaAntenasBase')

// nombres semanticos
const AntInactivaIcon = RemoveCircleTwoToneIcon
const AntActivaIcon = RouterTwoToneIcon
const AntProblemasIcon = ReportProblemTwoToneIcon
const AntErrorIcon = ErrorTwoToneIcon


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    //maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    maxHeight: 400,
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'flex-start',
  },
  lista: {
    minHeight: 400,
    width: '100%'
  },
  contenedorLista: {
    width: '100%',
    overflow: 'auto',
  },

}))

/**
 * Componente para mostrar el ícono de una antena
 */
const AntenaIcono = (props) => {
  const { antena, ...otherProps } = props

  return (
    <ListItemIcon>
    { antena.idModoLectura === ModoLecturaAntena.NORMAL && antena.idEstadoAntena === EstadoAntena.INACTIVA && (
        <AntInactivaIcon {...otherProps} color='disabled' />
    )}
    { antena.idModoLectura === ModoLecturaAntena.NORMAL && antena.idEstadoAntena === EstadoAntena.ACTIVA && (
        <AntActivaIcon {...otherProps} color='primary' />
    )}
    { antena.idModoLectura === ModoLecturaAntena.NORMAL && antena.idEstadoAntena === EstadoAntena.PROBLEMAS && (
        <AntProblemasIcon {...otherProps} color='secondary' />
    )}
    { antena.idModoLectura === ModoLecturaAntena.NORMAL && antena.idEstadoAntena === EstadoAntena.FALLA && (
        <AntErrorIcon {...otherProps} color='error' />
    )}
    { antena.idModoLectura === ModoLecturaAntena.CALIBRACION && (
        <BuildTwoToneIcon {...otherProps} color='secondary' />
    )}
    </ListItemIcon>
  )
}

/**
 * Antena
 */
const Antena = ({ antena, selected, botones, onClickAntena, onClickBoton, nivelUsuario, ...otherProps }) => {
  const [anchorEl,setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  ll('nivelUsuario: %d',nivelUsuario)

  const onClickBase = () => {
    if(onClickAntena) return onClickAntena({antena})
    if(onClickBoton) return onClickBoton({antena})
  }
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const onCloseMenu = () => setAnchorEl(null)
  const handleClick = (b,m) => {
    onClickBoton({antena,idBoton:b.idBoton,datos:m})
  }

  return (
    <ListItem {...otherProps} button dense onClick={onClickBase} selected={selected}>
      <AntenaIcono antena={antena} />
      <ListItemText
        primary={antena.idPlacaRB}
        secondary={antena.idLocacionActual ? antena.locacionActual.nombreLocacion : '(sin localizacion)'}
      />
      <ListItemSecondaryAction>
      { botones.map((b,i) => {
        if(!b.menuSetup) {
          return (
            <Tooltip key={i} title={b.tooltip} placement="top">
            <IconButton disabled={b.disabled} size="small" edge="end" onClick={() => onClickBoton({antena:antena, idBoton: b.idBoton})}>
            {b.icon}
            </IconButton>
            </Tooltip>
          )
        } else {
          const items = b.menuSetup(antena).filter(it => nivelUsuario >= it.nivel);
          ll('Antena menu: b:%o items:%o',b,items);
          return (
            <>
            <Tooltip key={i} title={b.tooltip} placement="top">
              <IconButton disabled={b.disabled} size="small" edge="end" onClick={openMenu}>
              {b.icon}
              </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={menuOpen} onClose={onCloseMenu}>
            { items.map((mi,j) => <MenuItem key={j} onClick={() => handleClick(b,mi)}>{mi.titulo}</MenuItem>) }
            </Menu>
            </>
          )
        }
      })}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

/**
 * Componente para mostrar la lista de antenas y el dialogo de edicion
 * @prop  antenas           {[Antena]}            Lista de antenas a mostrar
 * @prop  selectedAntenaId  {int}                 idAntena de la seleccionada inicialmente
 * @prop  onChangedSelected  {function(idAntena)}  Callback para indicar que se selecciona una antena nueva
 * @prop  onEditedAntena   {f({Antena | Locacion | Calibracion})}callback que invoca cuando se acepta un formulario de modificacion
 */
export default function ListaAntenasBase({ antenas, selectedAntenaId, botones, onClickAntena, onClickBoton, nivelUsuario }) {
  const classes = useStyles()
  ll.enabled = false; ee.enabled = true;

  return (
    <div className={classes.root}>
      <div className={classes.contenedorLista}>
      <List className={classes.lista}>
      { antenas.map((a,i) => (
        <Antena key={a.idAntena || i}
          antena={a}
          selected={selectedAntenaId === a.idAntena}
          {...{botones, onClickAntena, onClickBoton, nivelUsuario}}
        />
      ))}
      </List>
    </div>
  </div>
  )
}
