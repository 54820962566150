/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdatedAntena = /* GraphQL */ `
  subscription OnUpdatedAntena($idCliente: ID!) {
    onUpdatedAntena(idCliente: $idCliente) {
      idAntena
      idCliente
      idModeloAntena
      idPlacaRB
      activa
      idEstadoAntena
      idModoLectura
      intervaloLectura
      idLocacionActual
      fechaCreacion
      sensores {
        idSensor
        idAntena
        nombreSensor
        idTipoSensor
        idSensorPlaca
        idCalibracionSensor
        idUltimaLectura
        visibleSoloAdministradores
        calibracionActual {
          idCalibracionSensor
          idSensor
          idLocacion
          activo
          valorCalibracion1
          valorCalibracion2
          valorCalibracion3
          valorCalibracion4
          valorCalibracion5
          notas
          fechaCreacion
          idTipoSensor
          idTipoDetectorAlerta
          configDetectorAlerta
          variableAlerta
        }
        ultimaLectura {
          idLectura
          idSensor
          idLocacion
          idCalibracionSensor
          valorLeido
          datosAdicionales
          valorCalculado1
          valorCalculado2
          valorCalculado3
          valorCalculadoAdicional
          fechaLectura
          fechaCreacion
        }
      }
      locacionActual {
        idLocacion
        idCliente
        idAntena
        nombreLocacion
        latitud
        longitud
        notas
        activa
        fechaCreacion
      }
    }
  }
`;
export const onNuevaLecturaCalibracion = /* GraphQL */ `
  subscription OnNuevaLecturaCalibracion($idSensor: ID!) {
    onNuevaLecturaCalibracion(idSensor: $idSensor) {
      idLecturaCalibracion
      idSensor
      valorLeido
      datosAdicionales
      fechaLectura
      fechaCreacion
    }
  }
`;
export const onNuevasLecturasSensor = /* GraphQL */ `
  subscription OnNuevasLecturasSensor($idPlacaRB: String!) {
    onNuevasLecturasSensor(idPlacaRB: $idPlacaRB) {
      idLectura
      idSensor
      idLocacion
      idCalibracionSensor
      valorLeido
      datosAdicionales
      valorCalculado1
      valorCalculado2
      valorCalculado3
      valorCalculadoAdicional
      fechaLectura
      fechaCreacion
    }
  }
`;
