
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
  containerBotonRefresh: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  botonRefresh: {
    marginBottom: theme.spacing(1),
  },
}))



export default function BotonRefrescar ({caption, refrescando, doRefresh}) {
  const classes = useStyles()
  return (
    <div className={classes.containerBotonRefresh}>
      <Button className={classes.botonRefresh}
        disabled={refrescando}
        startIcon={<FontAwesomeIcon icon={faSync} spin={refrescando} />}
        onClick={doRefresh}
        type="button" variant="contained" color="primary">{caption || "Actualizar"}</Button>
    </div>
  )
}
