import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
//import CloudDownloadTwoToneIcon from '@material-ui/icons/CloudDownloadTwoTone'

const useStyles = makeStyles((theme) => ({
  icono: {
    margin: 'auto',
  },
}))

export default function LoadingIcon({size}) {
  const classes = useStyles()
  const s = size === 'small' ? 20 : 80
  const th = size === 'small' ? 2 : 3.6
  return (
    <CircularProgress size={s} thickness={th} className={classes.icono} />
  )
}
