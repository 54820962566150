import React from 'react'
import { Badge, Button, Link, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MapaAntenas from '../components/MapaAntenas'
import BotonRefrescar from '../components/BotonRefrescar'
import { useDatosAntenas } from '../components/hooksAntenas'
import { useDatosAlertas } from '../components/hooksAlertas'
import { makeDetector } from '../detectorInfo'
import { useStore, selInfoSensores, selInfoDetectores, selUserIsAdmin } from '../store'
import { ListaElementos } from '../components/ListaElementos'
import { faRoute } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {mision_directa, mision_reconocimiento} from '../dron'
import { saveAs } from 'file-saver';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone'

import FormAlerta from '../components/FormAlerta'

import { addAlerta, updAlerta, addDefaultNotificacionesAlerta } from '../graphql/mutations'
import { API } from 'aws-amplify'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '500px',
  },
  botonBotonera: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  linkDownload: {

  },
}))


const ACCION_EDITAR_ALERTA = 1
const ACCION_PLAN_VUELO_DIRECTO = 2
const ACCION_PLAN_VUELO_RECONOCIMIENTO = 3

const BOTON_PV_DIRECTO = {
  idBoton: ACCION_PLAN_VUELO_DIRECTO,
  tooltip:'Generar plan de vuelo tipo 1',
  icon: <Badge badgeContent={1} color="primary">
          <FontAwesomeIcon icon={faRoute} size="lg" color="primary"/>
        </Badge>,
  disabled: false,
}

const BOTON_PV_RECONOCIMIENTO = {
  idBoton: ACCION_PLAN_VUELO_RECONOCIMIENTO,
  tooltip:'Generar plan de vluelo tipo 2',
  icon: <Badge badgeContent={2} color="secondary">
          <FontAwesomeIcon icon={faRoute} size="lg" color="secondary"/>
        </Badge>,
  disabled: false,
}

const BOTON_EDITAR_ALERTA = {
  idBoton: ACCION_EDITAR_ALERTA,
  tooltip:'Editar alerta',
  icon: <EditTwoToneIcon />,
  disabled: false,
  adminOnly: true,
}

const BOTONES = [BOTON_EDITAR_ALERTA, BOTON_PV_DIRECTO, BOTON_PV_RECONOCIMIENTO]

class ElementoAlerta {
  constructor(alerta,sensor,antena){
    this.alerta = alerta
    this.sensor = sensor
    this.antena = antena
  }
  get id() {
    return this.alerta.idAlerta
  }

  get primaryText() {
    return `${this.antena && this.antena.locacionActual && this.antena.locacionActual.nombreLocacion}: Estado:${this.alerta.estadoReportado || '(sin información)'}`
  }

  get secondaryText() {
    if(!this.sensor) return ''

    const template = ''//'{{nombreEstado}}: {{v.valorMonitoreado.label}} {{sv.valor}} / Valor minimo: {{p.valorMinimo}}.'
    const [als] = this.sensor.alertas.filter(al => al.detector.alertada)
      .map(al => al.detector.getMensajeEstado(null, this.sensor.infoTipoSensor, template))
    return als
  }
}

export function ListaAlertas({elementosAlerta, ...otrasProps}){
  return <ListaElementos elementos={elementosAlerta} {...otrasProps} />
}

const crearElementosAlerta = (alertas, antenas) => {
  if(!alertas || !antenas || !alertas.length || !antenas.length)
    return
  const sensores = antenas.map(a => a.sensores.map(s => ({...s,antena:a}))).flat()
  const eeaa = alertas.map(al => {
    const sensor = sensores.find(s => s.idSensor === al.idSensor)
    const antena = sensor.antena
    //const antena = antenas.find(a => a.idAntena === sensor.idAntena)
    return new ElementoAlerta(al, sensor, antena)
  })
  return eeaa
}

const antenaAlertada = (antena) =>{
  return antena.sensores.find(s => s.alertas.find(al => al.detector.nivelAlerta === 'ALTO') !== undefined) !== undefined
}

export default function PaginaAlertas(){
  const esAdmin = useStore(selUserIsAdmin)
  const classes = useStyles();
  const {alertas, refreshAlertas, refrescandoAlertas} = useDatosAlertas()
  const {antenas, refreshAntenas, refrescandoAntenas} = useDatosAntenas()
  const infoSensores = useStore(selInfoSensores)
  const infoDetectores = useStore(selInfoDetectores)
  const [ editAlerta, setEditAlerta ] = React.useState(null)

  const sensores = antenas.map(a => a.sensores).flat()
  const alertasP = !alertas.length ? [] : alertas.map(al => ({...al, detector: makeDetector(infoDetectores,al), sensor:sensores.find(s => s.idSensor === al.idSensor)}))

  const antenasAl = !antenas.length ? [] : antenas.map(a => {
    const ss = a.sensores.map(s => {
      const als = alertasP.filter(al => al.idSensor === s.idSensor)
      return {...s, alertas:als}
    })
    return {...a, sensores:ss}
  })//.filter(a => antenaAlertada(a));

  console.log('alertasP y antenasAl:',{alertasP, antenasAl})

  const elementosAlerta = (alertasP.length > 0 && antenasAl.length > 0) ?  crearElementosAlerta(alertasP, antenasAl) : []
  elementosAlerta.sort((a,b) => a.primaryText.localeCompare(b.primaryText))
  console.log('elementosAlerta:',elementosAlerta)

  const onClickRefrescar = () => {
    refreshAlertas()
    refreshAntenas()
  }

  const onClickBoton = ({elemento, idBoton}) => {
    const { alerta, antena } = elemento
    console.log('onClick:',{antena,idBoton})
    let blobSrc = null, filename = 'plan_de_vuelo.txt'
    const {latitud,longitud} = antena && antena.locacionActual ? antena.locacionActual : {}
    switch(idBoton){
      case ACCION_PLAN_VUELO_DIRECTO:
        console.log('Click PLAN VUELO DIRECTO:',antena)
        blobSrc = mision_directa(latitud,longitud)
        filename = `${antena?.locacionActual?.nombreLocacion || antena?.idPlacaRB || '' }_plan_de_vuelo_1.txt`
      break
      case ACCION_PLAN_VUELO_RECONOCIMIENTO:
        console.log('Click PLAN VUELO reconocimiento:',antena)
        blobSrc = mision_reconocimiento(latitud,longitud)
        filename = `${antena?.locacionActual?.nombreLocacion || antena?.idPlacaRB || '' }_plan_de_vuelo_2.txt`
      break
      case ACCION_EDITAR_ALERTA:
        console.log('Click EDITAR ALERTA:',alerta)
        setEditAlerta(alerta)
      break
      default://solo selecciona
      break
    }
    if(blobSrc){
      const blob = new Blob([blobSrc],{type: "text/plain;charset=utf-8"})
      saveAs(blob,filename)
    }
  }

  const onAgregarAlerta = () => {
    setEditAlerta({})
  }

  const onCloseFormAlerta = async data => {
    console.log('onCloseFormAlerta:',data)
    if(data){
      const { notificarEmail, destinatariosEmail } = data
      const dataAlerta = {
        idAlerta: data.idAlerta,
        idSensor: data.idSensor,
        idDetector: data.idDetector,
        borrada: data.borrada,
        habilitada: data.habilitada,
        valoresParametros: data.valoresParametros,
        asignacionesVariables: data.asignacionesVariables,
        estadoActual: editAlerta.estadoActual,
        fechaEstadoActual: editAlerta.fechaEstadoActual,
        variablesEstadoActual: JSON.stringify(editAlerta.variablesEstadoActual || null),
        estadoReportado: editAlerta.estadoReportado,
        fechaEstadoReportado: editAlerta.fechaEstadoReportado,
        variablesEstadoReportado: JSON.stringify(editAlerta.variablesEstadoReportado || null),
      }
      if(!dataAlerta.idAlerta){
        dataAlerta.notificarEmail = notificarEmail
        dataAlerta.destinatariosEmail = destinatariosEmail
        const res = await API.graphql({ query: addAlerta, variables : dataAlerta })
        console.log('onCloseFormAlerta: res addAlerta:',res)
      } else {
        const res = await API.graphql({ query: updAlerta, variables : dataAlerta })
        console.log('onCloseFormAlerta: res updAlerta:',res)
        const rnot = await API.graphql({ query: addDefaultNotificacionesAlerta, variables: { idAlerta:data.idAlerta, idDetector: data.detector.id, notificarEmail, destinatariosEmail, reemplazar: true }})
        console.log('onCloseFormAlerta: res addNots:', rnot)
      }
      refreshAlertas()
      setEditAlerta(null)
    } else {
      setEditAlerta(null)
      refreshAlertas()
    }
  }

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item container xs={12}>
        <BotonRefrescar refrescando={refrescandoAlertas || refrescandoAntenas} doRefresh={onClickRefrescar} />
        { esAdmin &&
          <Button className={classes.botonBotonera} disabled={!!editAlerta} type="button" variant="contained" startIcon={<AddTwoToneIcon size='small' />} color="primary" onClick={onAgregarAlerta}>Alerta</Button>
        }
        <div className={classes.spacer} />
        <Typography variant="h6" gutterBottom>
          <Link href="https://firmware.ardupilot.org/Tools/MissionPlanner/MissionPlanner-latest.msi" target="_blank" rel="noopener">Descargar software Dron</Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        { !editAlerta &&
          <ListaAlertas elementosAlerta={elementosAlerta}
          botones={BOTONES.filter(b => !b.adminOnly || esAdmin)}
          onClickAntena={onClickBoton}
          onClickBoton={onClickBoton}
          />
        }
        { editAlerta &&
          <FormAlerta alerta={editAlerta} antenas={antenasAl} onClose={onCloseFormAlerta} />
        }
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <MapaAntenas antenas={antenasAl.filter(a => antenaAlertada(a))} infoSensores={infoSensores} esAdmin={esAdmin} />
      </Grid>
    </Grid>
  )

}
