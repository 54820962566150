import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, TextField, FormControl, FormGroup, InputLabel, Select, MenuItem, FormControlLabel, Switch } from '@material-ui/core'
import { Card, CardContent, CardActions, CardHeader } from '@material-ui/core'
import { EstadoAntena, ModeloAntena, ModoLecturaAntena } from '../enums'
import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

const DEFAULT_ANTENA = {
  idAntena: null,
  idCliente: null,
  idModeloAntena: ModeloAntena.ANTENA_ESTANDAR,
  idPlacaRB: '',
  activa: false,
  idEstadoAntena: EstadoAntena.INACTIVA,
  idModoLectura: ModoLecturaAntena.NORMAL,
  intervaloLectura: 0,
  idLocacionActual: null,
}

const ModelosAntena = [
  { idEnum: 2000,  nombreCorto: 'Monitor Altura' },
  { idEnum: 2001,  nombreCorto: 'Monitor Altura+CC' },
]

const EstadosAntena = [
  { idEnum: 4000, nombreCorto: 'Inactiva' },
  { idEnum: 4001, nombreCorto: 'Activa' },
  { idEnum: 4002, nombreCorto: 'Problemas' },
  { idEnum: 4003, nombreCorto: 'Falla' },
]

const ModosLectura = [
  { idEnum: 5000, nombreCorto: 'Normal' },
  { idEnum: 5001, nombreCorto: 'Calibración' },
]


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));




export default function FormAntena({ antena, onClose, ...otherProps }) {
  const crear = !(antena && antena.idAntena)
  const classes = useStyles()
  const initialValues = (crear ? {...DEFAULT_ANTENA, ...antena} : {...antena})
  console.log('initialValues:',initialValues)

  const antenaValidationSchema = {
    idCliente: Yup.number().integer().positive().required(),
    idModeloAntena: Yup.number().oneOf(ModelosAntena.map(m=>m.idEnum)).required(),
    idPlacaRB: Yup.string().required('Debe ingresar el Id de la Placa').max(100),
    activa: Yup.boolean().required(),
    idEstadoAntena: Yup.number().oneOf(EstadosAntena.map(e=>e.idEnum)).required(),
    idModoLectura: Yup.number().oneOf(ModosLectura.map(e=>e.idEnum)).required(),
  }
  const validationSchema = Yup.object(antenaValidationSchema)


  const formik_handleSubmit = editAntena => {
    console.log('formik_handleSubmit:',editAntena)
    onClose(editAntena)
  }

  const handleClose = () => {
    onClose()
  }

  const BotonSubmit = (props) => {
    const { values, submitForm } = useFormikContext()
    const botonSubmitClick = () => {
      console.log('botonSubmitClick: v:',values)
      submitForm()
    }
    return (
      <Button type="button" onClick={botonSubmitClick} color="primary">Guardar</Button>
    )
  }

  return (
    <Card className={classes.root}>
      <CardHeader id="form-dialog-title" title={crear ? "Crear Antena" : "Modificar Antena"}/>
      <Formik initialValues={initialValues} onSubmit={formik_handleSubmit} validationSchema={validationSchema}>
      { (formik) => { return (
      <>
      <CardContent>

        <form onSubmit={formik.handleSubmit}>
        <FormGroup row key="fila2">
          <TextField
            margin="dense"
            id="idPlacaRB"
            name="idPlacaRB"
            required
            label="Identificador de Placa RB"
            type="text"
            maxLength="100"
            fullWidth
            value={formik.values.idPlacaRB}
            onChange={formik.handleChange}
            error={formik.touched.idPlacaRB && Boolean(formik.errors.idPlacaRB)}
            helperText={formik.touched.idPlacaRB && formik.errors.idPlacaRB}
          />
        </FormGroup>

        <FormGroup row key="fila1">
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.activa}
                onChange={formik.handleChange}
                name="activa"
                color="primary"
              />
            }
            label="Activa"
          />

          <FormControl className={classes.formControl}>
            <InputLabel id="modeloAntenaLabel">Modelo de Antena</InputLabel>
            <Select
              autoFocus
              required
              margin="dense"
              labelId="modeloAntenaLabel"
              id="idModeloAntena"
              name="idModeloAntena"
              value={formik.values.idModeloAntena}
              onChange={formik.handleChange}
            >
            { ModelosAntena.map(ma => { return (
              <MenuItem value={ma.idEnum}>
              <em>{ma.nombreCorto}</em>
              </MenuItem>
            )})}
            </Select>
          </FormControl>
        </FormGroup>

        <FormGroup row key="fila3">
          <FormControl className={classes.formControl}>
            <InputLabel id="estadoAntenaLabel">Estado de la Antena</InputLabel>
            <Select
              autoFocus
              margin="dense"
              labelId="estadoAntenaLabel"
              id="idEstadoAntena"
              name="idEstadoAntena"
              value={formik.values.idEstadoAntena}
              onChange={formik.handleChange}
            >
            { EstadosAntena.map(ma => { return (
              <MenuItem value={ma.idEnum}>
              <em>{ma.nombreCorto}</em>
              </MenuItem>
            )})}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel id="modoLecturaLabel">Modo de Lectura</InputLabel>
            <Select
              autoFocus
              margin="dense"
              labelId="modoLecturaLabel"
              id="idModoLectura"
              name="idModoLectura"
              value={formik.values.idModoLectura}
              onChange={formik.handleChange}
            >
            { ModosLectura.map(ma => { return (
              <MenuItem value={ma.idEnum}>
                <em>{ma.nombreCorto}</em>
              </MenuItem>
            )})}
            </Select>
          </FormControl>
        </FormGroup>
      </form>
      </CardContent>

      <CardActions>
        <Button onClick={handleClose} color="primary">Cancelar</Button>
        <BotonSubmit />
      </CardActions>
      </>
    )}}
    </Formik>
  </Card>
)}
