import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function CircularProgressWithLabel(props) {
  const valProps = {...props}
  delete valProps.value
  const val = Math.round(props.value,0)
  let text
  if(val > 0){
    valProps.variant = "determinate"
    valProps.value = val
    text = `${val}%`
  } else {
    text = props.value
  }
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress {...valProps} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${text}`}</Typography>
      </Box>
    </Box>
  );
}
