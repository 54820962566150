import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, TextField, FormGroup, FormControl, InputLabel, Select, MenuItem, FormControlLabel, FormHelperText, Switch } from '@material-ui/core'
import { Card, CardContent, CardActions, CardHeader, Typography  } from '@material-ui/core'
import { Formik } from 'formik'
import { SelectAccionable, BotonSubmit } from './formUtils'
import * as Yup from 'yup'
import { useStore, selInfoDetectores, selUserEmail } from '../store'
import { makeDetector } from '../detectorInfo'
import { makeSensorInfo } from '../sensorInfo'

const DEFAULT_ALERTA = {
  idAlerta: '',
  idSensor: '',
  idDetector: '',
  borrada: false,
  habilitada: true,
  notificarEmail: false,
  destinatariosEmail: '',
  notificarPush: false,
  destinatariosPush: ''
}


const objToPropArray = (obj, propNameKey, propValKey, pref) => {
  const res = []
  for(let p in obj){
    if(p.startsWith(pref)) {
      const pname = p.substr(pref.length)
      res.push({ [propNameKey]: pname, [propValKey]: obj[p]})
    }
  }
  return res
}

const propArrayToObj = (parray, propNameKey, propValKey, pref) => {
  let res = {}
  for(let e of parray){
    res[`${pref}${e[propNameKey]}`] = propValKey ? e[propValKey] : ''
  }
  return res
}

const removeProps = (obj, pref) => {
  const res = {}
  for(let p in obj){
    if(!p.startsWith(pref)) {
      res[p] = obj[p]
    }
  }
  return res
}


const PREF_VP = '_p_'
const PREF_AV = '_v_'
const PAPT_VALORES_PARAMETROS = ['nombreParametro','valor',PREF_VP]
const PAPT_VALORES_PARAMETROS_NUEVO = ['param','default',PREF_VP]
const PAPT_ASIGNACIONES_VARIABLES = ['nombreVariable','campoSensor',PREF_AV]
const PAPT_ASIGNACIONES_VARIABLES_NUEVO = ['nombreVariable','',PREF_AV]

const crearValoresInicialesEdit = (a,defaultEmail) => {
  const nots = a.notificaciones ? a.notificaciones : []
  const notificarEmail = Boolean(nots.find(n => n.generarEmail === true))
  const destinatariosEmail = nots.length ? nots[0].destinatariosEmail : defaultEmail
  const notificarPush = Boolean(a.notificaciones.find(n => n.alertaPush === true))
  const destinatariosPush = nots.length ? nots[0].destinatariosPush : ''
  return {
    idAlerta: a.idAlerta,
    idSensor: a.idSensor,
    idDetector: a.idDetector,
    borrada: a.borrada,
    habilitada: a.habilitada,
    notificarEmail,
    destinatariosEmail,
    notificarPush,
    destinatariosPush,
    ...propArrayToObj(a.valoresParametros, ...PAPT_VALORES_PARAMETROS),
    ...propArrayToObj(a.asignacionesVariables, ...PAPT_ASIGNACIONES_VARIABLES)
  }
}

const crearValoresInicialesNew = (defaultEmail) => ({
  ...DEFAULT_ALERTA,
  destinatariosEmail: defaultEmail
})

/**
 * Crea el nuevo objeto de valores del formulario cuando se cambia el tipo de detector, borrando los campos del detector antiguo (si existen)
 * y agregando los del nuevo.
 * @param currentValues <formik.values>  Valores actuales del formulario
 * @param detector      <infoDetector>   nuevo tipo de detector
 */
const crearValoresReinicio = (currentValues, detector) => {
  return {
    idAlerta: currentValues.idAlerta,
    idSensor: currentValues.idSensor,
    idDetector: currentValues.idDetector,
    borrada: currentValues.borrada,
    habilitada: currentValues.habilitada,
    notificarEmail: currentValues.notificarEmail,
    destinatariosEmail: currentValues.destinatariosEmail,
    notificarPush: currentValues.notificarPush,
    destinatariosPush: currentValues.destinatariosPush,
    ...propArrayToObj(detector.params, ...PAPT_VALORES_PARAMETROS_NUEVO),
    ...propArrayToObj(detector.vars, ...PAPT_ASIGNACIONES_VARIABLES_NUEVO)
  }
}

const crearValidationSchema = (detector) => {
  console.log('crearValidationSchema:',{detector})
  const baseSchema = {
    idSensor: Yup.number().required('debe seleccionar un sensor'),
    idDetector: Yup.number().required('debe seleccionar un detector'),
  }
  if(detector){
    detector.params.forEach(p => {
      let y = p.tipo === 'integer' || p.tipo === 'float' ? Yup.number('debe ser numérico') : Yup.string()
      if('min' in p) y = y.min(p.min,`debe ser menor que ${p.min}`)
      if('max' in p) y = y.max(p.max,`debe ser mayor que ${p.max}`)
      if(p.requerido) y = y.required('ingrese un valor')
      baseSchema[`${PREF_VP}${p.param}`] = y
    })
    detector.vars.forEach(v => {
      let y = Yup.string().required('Debe seleccionar un valor')
      baseSchema[`${PREF_AV}${v.nombreVariable}`] = y
    })
  }
  console.log('crearValidationSchema: bs:',baseSchema)
  const schema = Yup.object().shape(baseSchema)
  return schema
}


const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  subtitulo: {
    fontSize: 14,
    marginTop: '1em'
  },
}));



export default function FormAlerta({ alerta, antenas, onClose, ...otherProps }) {
  console.log('FormAlerta:',{alerta,antenas})
  const classes = useStyles()
  const infoDetectores = useStore(selInfoDetectores)
  const defaultEmail = useStore(selUserEmail)
  const [detector, setDetector] = React.useState(alerta.idDetector ? makeDetector(infoDetectores,alerta) : null)
  const sensores = antenas.map(a => a.sensores.map(s => ({...s, antena:a}))).flat()
  const [tipoSensor, setTipoSensor] = React.useState(alerta.sensor ? alerta.sensor.infoTipoSensor : null)
  const [valoreInicialesForm, setValoresInicialesForm] = React.useState(alerta.idAlerta ? crearValoresInicialesEdit(alerta) : crearValoresInicialesNew(defaultEmail))


  console.log('detector actual: ', detector)

  console.log('initialValues:',valoreInicialesForm)

  const formik_handleSubmit = formAlerta => {
    console.log('formik_handleSubmit:',formAlerta)
    const d = removeProps(removeProps(formAlerta, PREF_VP), PREF_AV)
    const data = {
      ...d,
      valoresParametros: objToPropArray(formAlerta, ...PAPT_VALORES_PARAMETROS),
      asignacionesVariables: objToPropArray(formAlerta, ...PAPT_ASIGNACIONES_VARIABLES),
      notificaciones: alerta.notificaciones,
      detector: alerta.detector,
    }
    onClose(data)
  }

  const handleClose = () => {
    onClose()
  }

  const onChangeSensor = (newIdSensor,formik) => {
    const sensor = sensores.find(s => s.idSensor === newIdSensor)
    console.log('onChangeSensor',newIdSensor,sensor)
    for(let fv in formik.values){
      if(fv.startsWith(PREF_AV)) formik.setFieldValue(fv,'')
    }
    setTipoSensor(makeSensorInfo(sensor.infoTipoSensor))
  }

  const onChangeDetector = (newDetector,formik) => {
    console.log('onChangeDetector',newDetector)
    const detInf = makeDetector(infoDetectores.find(d => d.id === String(newDetector)))
    const newValues = crearValoresReinicio({...formik.values, idDetector:newDetector}, detInf)
    setValoresInicialesForm(newValues)
    setDetector(detInf)
  }

  const getValidationSchema = () => {
    return crearValidationSchema(detector)
  }

  return (
    <Card className={classes.root}>
      <CardHeader id="form-dialog-title" title="Alerta" subheader={''}/>
      <Formik initialValues={valoreInicialesForm} enableReinitialize={true} onSubmit={formik_handleSubmit} validationSchema={getValidationSchema}>
      { (formik) => { console.log('formikRender:values=',formik); return (
      <>
      <CardContent key="cardContent">

        <form onSubmit={formik.handleSubmit}>

          <FormGroup row key="Selects">
            <SelectAccionable
              formik={formik}
              campo="idSensor"
              label="Sensor"
              valores={ sensores.map(s => ({ valor: s.idSensor, texto: `${s.antena.locacionActual.nombreLocacion || s.antena.idPlacaRB}/${s.nombreSensor}` })) }
              changeCB={onChangeSensor}
              autoFocus
            />
            <SelectAccionable
              formik={formik}
              campo="idDetector"
              label="Tipo detector"
              valores={ infoDetectores.map(d => ({ valor: d.id, texto: d.nombre })) }
              changeCB={onChangeDetector}
            />
          </FormGroup>

          <FormGroup row key="flags">
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.habilitada}
                  onChange={formik.handleChange}
                  name="habilitada"
                  color="primary"
                />
              }
              label="Habilitada"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.borrada}
                  onChange={formik.handleChange}
                  name="borrada"
                  color="primary"
                />
              }
              label="Borrada"
            />
          </FormGroup>

          <FormGroup row key="email">
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.notificarEmail}
                  onChange={formik.handleChange}
                  name="notificarEmail"
                  color="primary"
                />
              }
              label="Notificar Email?"
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              id="destinatariosEmail"
              name="destinatariosEmail"
              required={formik.values.notificarEmail}
              label="Destinatarios Email"
              type={'text'}
              maxLength={ 100 }
              disabled={true}
              value={formik.values.destinatariosEmail}
              onChange={formik.handleChange}
              error={formik.touched.destinatariosEmail && Boolean(formik.errors.destinatariosEmail)}
            />
          </FormGroup>

          <Typography key="tituloParametros" className={classes.subtitulo}>Valores Parámetros</Typography>

          <FormGroup row key={"Parametros"}>
          { (detector ? detector.params : []).map( (cc,i) => (
              <TextField
                className={classes.formControl}
                margin="dense"
                id={`${PREF_VP}${cc.param}`}
                name={`${PREF_VP}${cc.param}`}
                required={cc.requerido}
                label={cc.label}
                type={cc.tipo === 'float' || cc.tipo === 'integer' ? 'number' : 'text' }
                maxLength={cc.tipo === 'float' || cc.tipo === 'integer' ? 10 : cc.max || 100 }
                value={formik.values[`${PREF_VP}${cc.param}`]}
                onChange={formik.handleChange}
                error={formik.touched[`${PREF_VP}${cc.param}`] && Boolean(formik.errors[`${PREF_VP}${cc.param}`])}
                helperText={formik.touched[`${PREF_VP}${cc.param}`] ? (formik.errors[`${PREF_VP}${cc.param}`] || cc.ayuda) : cc.ayuda}
              />
          ))}
          </FormGroup>

          <Typography key="tituloVariables" className={classes.subtitulo}>Asignación de Variables</Typography>

          <FormGroup row key={"Variables"}>
          { (detector ? detector.vars : []).filter(cc => `${PREF_AV}${cc.nombreVariable}` in formik.values).map( (cc,i) => (
            <FormControl className={classes.formControl}>
              <InputLabel id={`${PREF_AV}${cc.nombreVariable}Label`}>{cc.label}</InputLabel>
              <Select
                margin="dense"
                labelId={`${PREF_AV}${cc.nombreVariable}Label`}
                id={`${PREF_AV}${cc.nombreVariable}`}
                name={`${PREF_AV}${cc.nombreVariable}`}
                value={formik.values[`${PREF_AV}${cc.nombreVariable}`]}
                onChange={formik.handleChange}
                error={Boolean(formik.errors[`${PREF_AV}${cc.nombreVariable}`])}
              >
              { (tipoSensor ? tipoSensor.getCamposValor({}) : []).map((v,i) => (
                <MenuItem key={i} value={v.campo}>
                <em>{v.label}</em>
                </MenuItem>
              ))}
              </Select>
              <FormHelperText>{formik.errors[`${PREF_AV}${cc.nombreVariable}`]}</FormHelperText>
            </FormControl>
          ))}
          </FormGroup>
        </form>

      </CardContent>

      <CardActions key="cardActions">
        <Button onClick={handleClose} disabled={formik.isSubmitting} color="primary">Cancelar</Button>
        <BotonSubmit disabled={formik.isSubmitting} />
      </CardActions>
      </>
    )}}
    </Formik>
  </Card>
)}
