import React from 'react'
import { API } from 'aws-amplify'
import * as mutations from '../graphql/mutations'
import * as subscriptions from '../graphql/subscriptions'
import * as queries from '../graphql/queries'
import { MgrIoT } from '../mgriot';
import { makeLoggers } from '../utils';


const {ll,ee} = makeLoggers('hooksCalibracion','log')


/***********************************************************************************
 * Hook para borrar las lecturas de calibracion de la tabla. Retorna el indicador de cuando estén borradas
 */
export function useResetTablaCalibraciones(idPlacaRB, idSensorPlaca){
  const [done,setDone] = React.useState(false)

  React.useEffect(() => {
    resetCalibraciones(idPlacaRB, idSensorPlaca)
    .then(res => {
      ll('useResetTablaCalibraciones:done');
      setDone(true)
    }).catch(e => {
      ee('useResetTablaCalibraciones:error',e);
      setDone(true)
    });
  },[idPlacaRB, idSensorPlaca]);

  return done
}

const resetCalibraciones = async (idPlacaRB, idSensorPlaca) => {
  ll('borrando calibraciones antiguas')
  try{
    const res = API.graphql({
      query: mutations.resetLecturasCalibracion,
      variables: {
        idPlacaRB: idPlacaRB,
        idSensorPlaca: idSensorPlaca,
      },
    });
    ll('calibraciones borradas');
    return res
  } catch(e){
    ee('Error al borrar calibraciones:',e);
  };
}


/***********************************************************************************
 * Hook para habilitar el modo calibracion del sensor mediante un mensaje MQTT.
 * También levanta un timer para mantener el modo activo mientras se mantiene abierto el formulario.
 * Además se suscribe a las lecturas de calibracion retornando la última
 */

export function useActivarModoCalibracion(idPlacaRB, idSensorPlaca, idSensor){
  const [done,setDone] = React.useState(false)
  const [ultimaLecturaCalibracion, setUltimaLecturaCalibracion] = React.useState(null)

  /**
   * Effect para enviar el mensaje para activar el modo calibracion
   */
  React.useEffect(() => {
    if(!idPlacaRB || !idSensorPlaca) return
    ll('useActivarModoCalibracion: setup: ',{idPlacaRB, idSensorPlaca})
    const timerMantenerModoCalibracion = setInterval(() => {
      setModoCalibracionSensor(idPlacaRB, idSensorPlaca, 'activarModoCalibracion')
      .then(res => {
        ll('timer useActivarModoCalibracion: res=',res)
      }).catch(e => {
        ee('timer useActivarModoCalibracion: error=',e)
      });
    },60000);
    setModoCalibracionSensor(idPlacaRB, idSensorPlaca, 'activarModoCalibracion')
    .then(res => {
      ll('useActivarModoCalibracion: res=',res)
      setDone(true)
    }).catch(e => {
      ee('useActivarModoCalibracion: error=',e)
      setDone(true)
    });

    return () => {
      ll('useActivarModoCalibracion: cleanup timer');
      clearInterval(timerMantenerModoCalibracion)
    }
  },[idPlacaRB,idSensorPlaca]);

  /**
   * Effect para suscribirse a las lecturas de calibracion
   */
  React.useEffect(() => {
    const variables = { idSensor }
    ll('useCalibracionSensor: subscripcion:',variables)
    if(!idSensor) return

    //suscribirse a las lecturas de calibracion
    const subCalib = API.graphql({
      query: subscriptions.onNuevaLecturaCalibracion,
      variables,
    }).subscribe({
      next: ({provider, value}) => {
        ll('onNuevaLecturaCalibracion.next:',value.data.onNuevaLecturaCalibracion);
        setUltimaLecturaCalibracion(value.data.onNuevaLecturaCalibracion);
      },
      error: error => {
        ee('onNuevaLecturaCalibracion error:',error)
      }
    });

    return () => {
      ll('useCalibracionSensor: unsubscribe:',subCalib)
      if(subCalib) subCalib.unsubscribe()
    }
  },[idSensor]);

  return [ultimaLecturaCalibracion, done]
}

//Función para enviar el mensaje mqtt para activar el modo calibracion en el sensor
const setModoCalibracionSensor = async (idPlacaRB, idSensorPlaca, modo) => {
  const pubArgs = {
    topic: `iot/antena/calibracion/${idPlacaRB}`,
    qos: 1,
    payload: JSON.stringify({
      idPlacaRB: idPlacaRB,
      idSensorPlaca: idSensorPlaca,
      comando: modo,
    }),
  };
  ll('activar modo calibracion sensor:',pubArgs);
  return MgrIoT.mgr('publicar', { ...pubArgs });
}


/***********************************************************************************
 * Hook para obtener los valores de las lecturas procesados mediante una calibracion.
 */
export function useProcesarLecturas(lecturas,calibracion) {
  const [done, setDone] = React.useState(false)
  const [lecturasProcesadas, setLecturasProcesadas] = React.useState(null)

  ll.enabled = true; ee.enabled = true;

  React.useEffect(() => {
    if(!calibracion || !lecturas) return
    const cal = {
      idTipoSensor: calibracion.idTipoSensor,
      valorCalibracion1: calibracion.valorCalibracion1,
      valorCalibracion2: calibracion.valorCalibracion2,
      valorCalibracion3: calibracion.valorCalibracion3,
      valorCalibracion4: calibracion.valorCalibracion4,
      valorCalibracion5: calibracion.valorCalibracion5,
    }
    // ll('procesarLecturas: ll:%o',lecturas)
    // ll('procesarLecturas: c:%o',calibracion)
    setDone(false);
    procesarLecturas(lecturas,cal)
    .then(lects => {
      if(!lects) throw new Error(`no se pudieron procesar las lecturas: c:${JSON.stringify(calibracion)}`);
      ll('procesarLecturas:res=',lects);
      setLecturasProcesadas(lects);
      setDone(true);
    }).catch(e =>{
      ee('useProcesarLecturas:error=',e)
    })
  },[lecturas,calibracion]);

  return [lecturasProcesadas,done]
}

const procesarLecturas = async (lectureas,calibracion) => {
  //ll('procesarLecturas:',{calibracion,lectureas,})
  if(!calibracion || !lectureas) return null
  const lecturas = Array.isArray(lectureas) ? lectureas : [lectureas]
  if(lecturas.length === 0) return []

  try{
    const variables = {
      idSensor: 0,
      calibracion,
      valores: lecturas.map(l => ({
        valorLeido: l.valorLeido,
        datosAdicionales: l.datosAdicionales,
        fechaLectura: l.fechaLectura || new Date()
      }))};
    const res = await API.graphql({
      query: queries.procesarLecturas,
      variables,
    });
    const lecturasProc = res.data.procesarLecturas
    //ll('procesarLecturas: res:%o',lecturasProc);
    return  Array.isArray(lectureas) ? lecturasProc : lecturasProc[0];
  }catch(e){
    ee('procesarLacturas:err:',e)
    return null
  }
}

/**
 * Hook para acumular la lista de lecturas de calibracion a medida que van llegando.
 * Limita el largo de la lista para evitar problemas de memoria si se deja abierto.
 */
export function useListaCalibraciones(lectura,maxLecturas){
  const [lecturas,dispatch] = React.useReducer(reducerListaCalibraciones,[]);

  React.useEffect(()=>{
    if(lectura)
      dispatch({tipo:'addLecturaCalibracion',lectura,maxLecturas})
  },[lectura,maxLecturas]);

  return lecturas
}

const reducerListaCalibraciones = (state,action) => {
  //ll('Reducer: a:%o s:%o',action,state)
  switch(action.tipo){
    case 'addLecturaCalibracion': //action:{ lectura, dispatch }
      return [action.lectura, ...state].slice(0,action.maxLecturas || 20)
    default:  break;
  }
  return state
}

/***************************************************************/
/***************************************************************/
/***************************************************************/

// const initCalibracionSensor = async (idPlacaRB, idSensorPlaca) => {
//   ll('initCalibracionSensor: resetCalibraciones:');
//   await resetCalibraciones(idPlacaRB, idSensorPlaca);
//   ll('initCalibracionSensor: setModoCalibracionSensor:');
//   await setModoCalibracionSensor(idPlacaRB, idSensorPlaca, 'activarModoCalibracion');
// }
//
// const setupMantenerModoCalibracionTimer = (idPlacaRB, idSensorPlaca) => {
//   ll('setupMantenerModoCalibracionTimer')
//   return setInterval(()=>{
//     setModoCalibracionSensor(idPlacaRB, idSensorPlaca, 'activarModoCalibracion');
//   },60000);
// }
//
// const calibracionSensorInit = sensor => {
//   ll('calibracionSensorInit');
//   return {
//     ultimaLectura: null,
//     calibracionActual: sensor.calibracionActual,
//   }
// }
//
// const MAX_LECTURAS_CALIBRACION = 20
//
// const reducerSetUltimaLectura = (state,{tipo,lectura}) => {
//   const newState = { ...state, ultimaLectura: lectura }
// //      setTimeout(() => action.dispatch(procesarLecturasAction(action.dispatch)),100)
//   return newState
// }
//
// const reducerUpdateCalibracionActual = (state,{tipo,calib}) => {
//   const newState = { ...state, calibracionActual: calib }
// //      setTimeout(() => action.dispatch(procesarLecturasAction(action.dispatch)),100)
//   return newState
// }
//
// const reducerSetLecturasCalibracionProcesadas = (state,{tipo,lecturas}) => {
//   const newState = { ...state, lecturasCalibracionProcesadas: lecturas }
//   return newState
// }
//
// const reducerProcesarLecturas = (state,{dispatch}) => {
//   procesarLecturasCalibracion(state.lecturasCalibracion, state.calibracionActual, res => dispatch(setLecturasCalibracionProcesadasAction(res)))
//   return state
// }
//
// function calibracionSensorReducer(state,action) {
//   ll('reducer: a:%o s:%o',action,state)
//   switch(action.tipo){
//     case 'setUltimaLectura': //action:{ lectura, dispatch }
//       return reducerSetUltimaLectura(state,action)
//     case 'updateCalibracionActual': //action:{ calib, dispatch }
//       return reducerUpdateCalibracionActual(state,action);
//     case 'setLecturasCalibracionProcesadas': //action: { lecturas }
//       return reducerSetLecturasCalibracionProcesadas(state,action);
//     case 'procesarLecturas': //action: { dispatch }
//       return reducerProcesarLecturas(state,action);
//     default:  break;
//   }
//   return state
// }
//
// const setUltimaLecturaAction = (lectura) => {
//   return { tipo: 'setUltimaLectura', lectura }
// }
// const updateCalibracionActualAction = (calib,dispatch) => {
//   return { tipo: 'updateCalibracionActual', calib, dispatch }
// }
// const setLecturasCalibracionProcesadasAction = (lecturas) => {
//   return { tipo: 'setLecturasCalibracionProcesadas', lecturas }
// }
// const procesarLecturasAction = dispatch => {
//   return { tipo: 'procesarLecturas', dispatch }
// }
//
// export function useCalibracionSensor(antena,sensor,cbNuevaLectura){
//   const [calibState, dispatch] = React.useReducer(calibracionSensorReducer,sensor,calibracionSensorInit);
//   const lastCalibracionValores = React.useRef({})
//
//   ll.enabled = true; ee.enabled = true;
//
//   ll(`useCalibracionSensor.render: st:%o`,calibState);
//
//
//   /**
//    * Borrar lecturas de calibracion anteriores de la bd y activar el modo calibracion en el sensor.
//    * Crea un timer de 60s para mantener el modo calibracion en el sensor, ya que el sensor
//    * sale automaticamente del modo calibracion pasados 100 segundos.
//    */
//   React.useEffect(() => {
//     //borrar las calibraciones actuales
//     const timerMantenerModoCalibracion = setupMantenerModoCalibracionTimer(antena.idPlacaRB, sensor.idSensorPlaca);
//     initCalibracionSensor(antena.idPlacaRB, sensor.idSensorPlaca);
//     return () => {
//       ll('useCalibracionSensor: cleanup timer');
//       clearInterval(timerMantenerModoCalibracion)
//     }
//   },[antena.idPlacaRB,sensor.idSensorPlaca]);
//
//   /**
//    * Effect para manejar la actualizacion automática de los datos de calibraciones
//    * leídos en el momento. Hace una subscription de graphql sobre la tabla lecturasCalibracion
//    */
//   React.useEffect(() => {
//
//     //usa la subscripción de graphql para actualizar la lista de lecturas
//     const variables = { idSensor: sensor.idSensor }
//     ll('useCalibracionSensor: subscripcion:',variables)
//
//     //suscribirse a las lecturas de calibracion
//     const subCalib = API.graphql({
//       query: subscriptions.onNuevaLecturaCalibracion,
//       variables,
//     }).subscribe({
//       next: ({provider, value}) => {
//         ll('onNuevaLecturaCalibracion.next:',value.data.onNuevaLecturaCalibracion);
//         dispatch(setUltimaLecturaAction(value.data.onNuevaLecturaCalibracion));
//         if(cbNuevaLectura) cbNuevaLectura(value.data.onNuevaLecturaCalibracion);
//       },
//       error: error => {
//         ee('onNuevaLecturaCalibracion error:',error)
//       }
//     });
//
//     return () => {
//       ll('useCalibracionSensor: unsubscribe:',subCalib)
//       if(subCalib) subCalib.unsubscribe()
//     }
//   },[sensor.idSensor,cbNuevaLectura]);
//
//   /**
//    * Esta funcion toma los valores de los campos de la calibracion y actualiza la calibracionActual.
//    * Cada vez que hay un cambio vuelve a actualizar las lecturas con los nuevos calculos.
//    */
//    const updateCalibracionActual = React.useCallback(
//      valoresForm => {
//        if(lastCalibracionValores.current !== valoresForm){
//          lastCalibracionValores.current = valoresForm
//          const si = sensor.infoTipoSensor
//          const calib = si.buildCalibracion(valoresForm);
//          dispatch(updateCalibracionActualAction(calib,dispatch));
//        }
//      },[sensor.infoTipoSensor]);
//
//
//   return [calibState.ultimaLectura, updateCalibracionActual]
// }
