import Mustache from 'mustache'

export class Detector {
  constructor(info, al){
    this._detectorInfo = info
    this.alerta = al || null
  }

  get id() {
    return this._detectorInfo.id
  }

  get nombre(){
    return this._detectorInfo.nombre
  }

  get descripcion() {
    return this._detectorInfo.descripcion
  }

  get estadoInicial() {
    return this._detectorInfo.estadoInicial
  }

  get detectorInfo() {
    return this._detectorInfo
  }
  set detectorInfo(newDI){
    this._detectorInfo = newDI
  }

  get alerta(){
    return this._alerta
  }
  set alerta(newAlerta) {
    this._alerta = newAlerta
  }

  get estados(){
    return this._detectorInfo ? this._detectorInfo.estados : []
  }

  getEstado(ne){
    return this._detectorInfo.estados.find(e => e.nombreEstado === (ne || this._alerta.estadoReportado))
  }

  getMensajeEstado(ne,infoSensor,template){
    const estado = this.getEstado(ne)
    const context = {
      nombreEstado: this.getEstado(ne).nombreEstado,
      sv: this._alerta.variablesEstadoReportado,
      p: this.p,
      v: this.getV(infoSensor),
    }
    return estado ? Mustache.render(template || estado.desc, context) : ''
  }


  get params() {
    return this._detectorInfo ? this._detectorInfo.params : []
  }

  get paramsObj() {
    const _p = {}
    this._detectorInfo.params.forEach(pp => _p[pp.param] = pp)
    return _p
  }

  get p(){
    const _p = {}
    if(this._alerta){
      this._alerta.valoresParametros.forEach(vp => _p[vp.nombreParametro] = vp.valor)
    } else {
      this._detectorInfo.params.forEach(pp => _p[pp.param] = pp)
    }
    return _p
  }

  get av(){
    const _av = {}
    if(this._alerta){
      this._alerta.asignacionesVariables.forEach(av => _av[av.nombreVariable] = av)
    }
    return _av
  }

  getV(infoSensor){
    const _v = {}
    for(let q in this.av) {
      const vv = this.av[q]
      _v[vv.nombreVariable] = {...vv, label: infoSensor?.getLabel(vv.campoSensor)}};
    return _v
  }

  get vars() {
    return this._detectorInfo ? this._detectorInfo.vars : []
  }

  get nivelAlerta() {
    if(!this._alerta || !this._alerta.estadoReportado) return ''
    const state = this._detectorInfo.estados.find(e => e.nombreEstado === this._alerta.estadoReportado)
    if(!state) return ''
    return state.reportarNivel
  }

  get alertada() {
    return this.nivelAlerta === 'ALTO'
  }

}

export function makeDetector(iiff,al) {
  if(Array.isArray(iiff)){
    // eslint disable next
    const info = iiff.find(inf => String(inf.id) === String(al.idDetector))
    return new Detector(info,al)
  }
  return new Detector(iiff,al)
}
