import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TablaDatos from '../components/TablaDatos';
import LoadingIcon from '../components/loading';
import { useDatosAntenas } from '../components/hooksAntenas';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}))


export default function PaginaUltimasLecturas(){
  const classes = useStyles();
  const {antenas, BotonRefrescoAntenas} = useDatosAntenas()
  const cargando = antenas === null || antenas.length === 0
  const datosTabla = []
  const prefCamposSensor = {}
  const Columnas = [
    {
      title: 'Antena',
      field: 'antena.locacionActual.nombreLocacion',
      width: 200,
      align: 'left',
    }
  ]

  if(antenas && antenas.length){
    antenas.forEach(a => {
      const row = {
        'antena.locacionActual.nombreLocacion': a.idLocacionActual ? a.locacionActual.nombreLocacion : a.idPlacaRB
       }
      //const modeloAntena = modelosAntenas.find(ma => String(ma.id) === String(a.idModeloAntena))
      a.sensores.forEach(s => {
        console.log('Sensor:',s)
        const prefCampos = `M${a.idModeloAntena}I${s.idSensorPlaca}`
        const is = s.infoTipoSensor
        const campos = is.getCamposValor({})
        //timestamp de la ultimaLectura
        if(!(prefCampos in prefCamposSensor)){
          Columnas.push({
            title: 'f/h última lectura',
            field: `${prefCampos}#ts`,
            width: 150,
            align: 'left',
          })
        }
        if(s.idUltimaLectura){
          is.lectura = s.ultimaLectura
          row[`${prefCampos}#ts`] = is.getValor('fechaLectura')
        }
        //valoresObtenidos
        campos.forEach(cv => {
          if(!(prefCampos in prefCamposSensor)){
            Columnas.push({
              title: `${s.nombreSensor}`,
              subtitle: is.getLabelConUnidades(cv.campo),
              field: `${prefCampos}#${cv.campo}`,
              cellStyle: {width: 200},
              width: 200,
              align: 'right',
            })
          }
          if(s.idUltimaLectura){
            row[`${prefCampos}#${cv.campo}`] = is.getValor(cv.campo)
          }
        });
        prefCamposSensor[prefCampos] = true
      });
      datosTabla.push(row)
    })
  }

  return (
    <main className={classes.root}>
    { cargando ? <LoadingIcon /> :
      <>
        <BotonRefrescoAntenas />
        <TablaDatos columnas={Columnas} datos={datosTabla} />
      </>
      }
    </main>

  )

}
