import {
  //ListUsersCommand,
  ListUsersInGroupCommand,
  AdminCreateUserCommand,
  AdminAddUserToGroupCommand,
  //AdminSetUserPasswordCommand,
  CognitoIdentityProviderClient
} from '@aws-sdk/client-cognito-identity-provider';
import {
  CognitoIdentityClient,
} from "@aws-sdk/client-cognito-identity";
import {
  fromCognitoIdentityPool,
} from "@aws-sdk/credential-provider-cognito-identity";

const [ll, ee]   = [console.log, console.error]

const GRUPO_ADMIN = "Administradores";
const GRUPO_USUARIOS = "SoloLectura";
const GRUPOS = ['ROOT',GRUPO_ADMIN,GRUPO_USUARIOS];

const REGION = 'us-east-1';

export const MgrCognito = {

  getUsuarios: async (client, { poolId, idCliente }) => {
    ll('getUsuarios: c:',client)
    ll('getUsuarios: a:',{ poolId, idCliente })
    const params = {
      UserPoolId: poolId,
    };
    const ATTRIB_IDCLIENTE = 'custom:idCliente'
    const ATTRIB_EMAIL = 'email'
    try{
      const rrr = await Promise.allSettled(GRUPOS.map(g => {
        const command = new ListUsersInGroupCommand({...params,GroupName:g});
        ll('getUsuarios: cmd:',command)
        return client.send(command)
      }));
      ll('getUsuarios: rrr:',rrr);
      const users = rrr.map((r,i) => r.status === 'fulfilled' ? r.value.Users.map(u => ({...u, perfil:GRUPOS[i]})) : [])
        .flat()
        .filter(u => u.Attributes.find(a => a.Name === ATTRIB_IDCLIENTE && a.Value === String(idCliente)) !== undefined)
        .map(u => {
          const usr = {
            username: u.Username,
            enabled: u.Enabled,
            status: u.UserStatus,
            fechaCreacion: u.UserCreateDate,
            fechaModificacion: u.UserLastModifiedDate,
            perfil: u.perfil,
          }
          const attEmail = u.Attributes.find(a => a.Name === ATTRIB_EMAIL)
          usr.email = attEmail ? attEmail.Value : '(sin datos)'
          return usr;
        })
      ll('users:',users)
      return users
    }catch(e){
      ee('getUsuarios: ERROR:',e)
    }
  },


  addUsuario: async (client, {poolId,username,email,idCliente,perfil}) => {
    const paramsCrear = {
      UserPoolId: poolId,
      Username: username,
      MessageAction: 'SUPPRESS',
      UserAttributes: [
        { Name:'email', Value: email },
        { Name:'email_verified', Value: 'True' },
        //{ Name:'phone_verified', Value: 'True' },
        { Name:'custom:idCliente', Value: String(idCliente) },
      ],
    };
    try{
      const cmdCrear = new AdminCreateUserCommand(paramsCrear);
      const resCrear = await client.send(cmdCrear);
      ll('addUsuario: res:',resCrear);
      const usr = {
        username: resCrear.User.Username,
        enabled: resCrear.User.Enabled,
        status: resCrear.User.UserStatus,
        fechaCreacion: resCrear.User.UserCreateDate,
        fechaModificacion: resCrear.User.UserLastModifiedDate,
        email,
      }
      const grupo = perfil === GRUPO_ADMIN ? GRUPO_ADMIN : GRUPO_USUARIOS;  //no se permite crear usuarios ROOT
      usr.perfil = grupo;
      const cmdAddToGroup = new AdminAddUserToGroupCommand({
        UserPoolId: poolId,
        Username: username,
        GroupName: grupo,
      });
      const resGroup = await client.send(cmdAddToGroup)
      ll('addUsuario: addToGroup:',cmdAddToGroup,resGroup)
      return usr;
    }catch(e){
      ee(e);
    }
  },


  allOp: async (operacion,{token, ...args}) => {
    const CONFIG = {
      region: REGION,
      credentials: fromCognitoIdentityPool({
        identityPoolId: 'us-east-1:db13dc24-9e88-440b-a473-536c0bc18ea0',
        client: new CognitoIdentityClient({ region: REGION }),
        logins: {
          [token.payload.iss.substr(8)]: token.jwtToken,
        },
      }),
    };

    const client = new CognitoIdentityProviderClient(CONFIG);
    ll('MgrCognito: client=',client)
    return MgrCognito[operacion](client, args);
  },

  mgr: async (operacion, args) => {
    switch(operacion){
      case 'getUsuarios':
      case 'addUsuario':
        return MgrCognito.allOp(operacion, args);

      default:
        ee(`Operación "${operacion}" no implementada`);
        throw new Error(`Operación "${operacion}" no implementada`);
    }
  }
}

//module.exports = MgrCognito;
