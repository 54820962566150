import create from 'zustand'

export const useMapStateStore = create(set => ({
  antena: null,
  edit: '',
  sensor: null,
  seccion: '',

  setAntena: a => set(state => ({
    antena: a
  })),

  setEdit: e => set(state => ({
    edit: e
  })),

  setSensor: s => set(state => ({
    sensor: s
  })),

  setEditAntena: ({antena,edit}) => set(state => ({
    antena,
    edit,
  })),

  setEditAntenaSensor: ({antena,sensor,edit,seccion}) => set(state => ({
    antena,
    sensor,
    edit,
    seccion,
  })),

}));

export const selAntena = state => state.antena
export const selEdit = state => state.edit
export const selSensor = state => state.sensor
export const selEditAntena = state => ({ antena: state.antena, edit: state.edit, sensor: state.sensor, seccion: state.seccion })

export const selSetEditAntena = state => state.setEditAntena
export const selSetEditAntenaSensor = state => state.setEditAntenaSensor
export const selSetAntena = state => state.setAntena
export const selSetEdit = state => state.setEdit
export const selSetSensor = state => state.setSensor
